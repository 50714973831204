import { useExtraInfo } from '@/composables/states/useExtraInfo';
import { VALID_EXTRA_INFO_PATHS } from '@/composables/states/useExtraInfo/useExtraInfo.types';
import { useProspect } from '@/composables/states/useProspect';
import { useCrypto } from '@/composables/utils/useCrypto';
import { useStorage } from '@/composables/utils/useStorage';
import { useUrls } from '@/composables/utils/useUrls';

export const utmGuard = (to, from, next) => {
  const { query } = to;
  const { parseQuery, removeParameterFromUrl } = useUrls();
  const { getFromLocalStorage, setInLocalStorage, getFromSessionStorage } = useStorage();
  const { setProspectData, PROSPECT_DATA_PATH } = useProspect();
  const { setExtraInfo } = useExtraInfo();

  if (!getFromLocalStorage('about_utm')) {
    const sessionUtm = getFromSessionStorage('about_utm');
    if (sessionUtm) {
      setInLocalStorage('about_utm', sessionUtm);
    } else {
      const { uuidv4 } = useCrypto();
      setInLocalStorage('about_utm', {
        first_utm_source: null,
        first_utm_medium: null,
        first_utm_campaign: null,
        first_utm_content: null,
        first_utm_keyword: null,
        first_utm_term: null,
        first_url: null,
        current_utm_source: null,
        current_utm_medium: null,
        current_utm_campaign: null,
        current_utm_content: null,
        current_utm_keyword: null,
        current_utm_term: null,
        current_url: null,
        partner_user_id: null,
        uuid: uuidv4(),
        referrer: null
      });
    }
  }
  const about_utm = getFromLocalStorage('about_utm');

  const {
    utm_source = '',
    utm_medium = '',
    utm_campaign = '',
    utm_content = '',
    utm_keyword = '',
    utm_term = '',
    partner_user_id = ''
  } = parseQuery(location.search);

  const url = removeParameterFromUrl(window.location.href || document.documentURI, 'token');
  const referrer = document.referrer;

  if (!sessionStorage.getItem('isCurrentSession')) {
    sessionStorage.setItem('isCurrentSession', true);
    about_utm.current_utm_source = utm_source;
    about_utm.current_utm_medium = utm_medium;
    about_utm.current_utm_campaign = utm_campaign;
    about_utm.current_utm_content = utm_content;
    about_utm.current_utm_keyword = utm_keyword;
    about_utm.current_utm_term = utm_term;
    about_utm.current_url = url;
    about_utm.partner_user_id = partner_user_id;
    about_utm.referrer = referrer;

    if (
      about_utm.first_utm_source === null ||
      about_utm.first_utm_medium === null ||
      about_utm.first_utm_campaign === null ||
      about_utm.first_utm_content === null ||
      about_utm.first_utm_keyword === null ||
      about_utm.first_utm_term === null ||
      about_utm.first_url === null ||
      about_utm.referrer === null
    ) {
      about_utm.first_utm_source = utm_source;
      about_utm.first_utm_medium = utm_medium;
      about_utm.first_utm_campaign = utm_campaign;
      about_utm.first_utm_content = utm_content;
      about_utm.first_utm_keyword = utm_keyword;
      about_utm.first_utm_term = utm_term;
      about_utm.first_url = url;
      about_utm.referrer = referrer;
    }
    sessionStorage.setItem('isCurrentSession', true);
    setInLocalStorage('about_utm', about_utm);
  }

  const listUTMKeys = Object.keys(query).filter((key) => key.startsWith('utm_'));
  if (listUTMKeys.length) {
    const listParams = listUTMKeys.reduce((result, key) => {
      result[key] = query[key];
      return result;
    }, {});
    setExtraInfo(VALID_EXTRA_INFO_PATHS.UTM_PARAMS, listParams, true);
  }
  if (query.external_id) {
    setProspectData(PROSPECT_DATA_PATH.subscriber.additional_data.external_id, query.external_id);
  }

  sessionStorage.setItem('about_utm', JSON.stringify(about_utm));

  return next();
};
