export enum BundleOptionsEnum {
  DE_DOG_BITES = 'germany-dog-liability-bites',
  DE_BIKE = 'germany-home-content-bike',
  DE_PET_HEALTH_V2 = 'germany-pet-health-v2-premium',
  DE_PET_HEALTH_V2_PH = 'germany-pet-health-v2-ph-premium',
  FR_LEGAL_PROTECTION = 'france-legal-protection',
  FR_PNO_OUTBUILDING = 'france-landlord-liag-outbuilding',
  FR_OUTBUILDINGS = 'france-home-outbuildings',
  FR_LEGAL_PROTECTION_EXTENDED = 'france-legal-protection-extended',
  FR_VALUABLES = 'france-home-valuables'
}
export enum BundleGuaranteesEnum {}
export enum BundleInstallationsEnum {}
