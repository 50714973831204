<template>
  <LukoBanner
    v-model:show="isBannerShown"
    :class="$style.info"
    :theme="infoData?.type"
    :message="infoData?.text"
    data-luko-tracking="InfoBox"
  />
</template>

<script lang="ts" setup>
import { LukoBanner } from '@demain-es/lukompo';
import { computed, onMounted } from 'vue';

import { useState } from '@/composables/states/useState';

const { stateData } = useState();

const isBannerShown = computed({
  get: () => !!stateData.value.infoData,
  set: (value) => {
    if (!value) stateData.value.infoData = undefined;
  }
});

const infoData = computed(() => stateData.value.infoData);

onMounted(() => {
  setTimeout(() => {
    stateData.value.infoData = undefined;
  }, 3000);
});
</script>

<style lang="scss" module>
.info {
  position: fixed;
  z-index: 1000;

  border-radius: 0;
}
</style>
