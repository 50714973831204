import 'reset-css';
import '@/styles/_module.scss';
import './plugins';

import { createApp } from 'vue';

import { i18n } from '@/i18n';
import { asyncMultiguard, multiguard } from '@/lib/vue-router-multiguard';
import { installPlugins } from '@/plugins';
import asyncGuards from '@/router/asyncGuards';
import guards from '@/router/guards';
import { router } from '@/router/router';

import App from './App.vue';

router.beforeEach(multiguard(guards));
router.beforeResolve(asyncMultiguard(asyncGuards));

const app = createApp(App);

installPlugins(app);

app.use(i18n);
app.use(router);

app.mount('#app');

export { app };
