import './logger';
import './storage';
import './lukosiris';
import './braze';
import './events';

import { App } from 'vue';

import { useEnv } from '@/composables/utils/useEnv';

import { install as installDevTools } from './devtools';
import { install as installGlobalComponents } from './globalComponents';
import { install as installGtm } from './gtm';
import { install as installLukompo } from './lukompo';
import { install as installMeta } from './meta';
import { install as installSentry } from './sentry';
import { install as installUnleash } from './unleash';
import { install as installVSanitize } from './v-sanitize';

export const installPlugins = (app: App): void => {
  const { isDev, devEnvForced } = useEnv();

  const plugins = [
    installLukompo,
    installGlobalComponents,
    installMeta,
    installSentry,
    installVSanitize,
    installGtm,
    installUnleash
  ];
  if (isDev || devEnvForced.value) {
    plugins.push(installDevTools);
  }

  plugins.forEach(app.use);
};
