const cleanQueryParamList = [
  'pk',
  'key',
  /^\$\./, // prospectData
  'prospect',
  'moving',
  'bypass',
  'go_fast',
  'gofast',
  'pricing',
  'payment',
  't',
  'token',
  'pc',
  'product',
  'public_key',
  'contractId',
  'data',
  'cross_sell'
];

export const purifyQueryGuard = async (to, from, next) => {
  const { query } = to;

  Object.keys(query).forEach((key) => {
    const hasRecognizedKey = !!cleanQueryParamList.find((pattern) =>
      pattern instanceof RegExp ? !!key.match(pattern) : key === pattern
    );

    if (hasRecognizedKey) delete query[key];
  });

  return next();
};
