import { PaymentIntentResponse } from '@/api/contract/contract.types';
import PartnerAPI from '@/api/PartnerAPI';
import { i18n } from '@/i18n';
import { PaymentMethodInterface, PaymentSource } from '@/types/Payment';

import PaymentIntentModel from './Payment.models';

export default {
  async createPaymentIntent(
    prospectKey: string,
    payload: {
      intent: boolean;
      existing_card_payment_method_id: number;
    }
  ): Promise<PaymentIntentModel> {
    try {
      const { data } = await PartnerAPI.post<PaymentIntentResponse>(
        `/prospect/${prospectKey}/payment/intent?lang=${i18n.global.locale}`,
        payload
      );

      if (data.prospect.key) {
        return Promise.resolve(
          new PaymentIntentModel(data.intent?.id, data.intent?.client_secret, data.prospect.key)
        );
      }

      throw new Error('missing prospect key');
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getPaymentMethod(): Promise<PaymentSource[]> {
    try {
      const { data } = await PartnerAPI.get<PaymentMethodInterface>(`/consumer/payment_methods`);

      if (data.sources) {
        return Promise.resolve(data.sources);
      }

      throw new Error('missing prospect key');
    } catch (err) {
      return Promise.reject(err);
    }
  }
};
