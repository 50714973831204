import '@demain-es/lukompo/dist/style.css';

import { useLukompoConfiguration } from '@demain-es/lukompo';

export const install = () => {
  const geoapifyApiKey = import.meta.env.VITE_GEOAPIFY_KEY;

  if (!geoapifyApiKey) return;

  const { configure } = useLukompoConfiguration();
  configure({ geoapifyApiKey });
};
