export enum ProductToParamEnum {
  FR_ESCOOTER = 'escooter',
  DE_PET_HEALTH = 'pets',
  DE_LIABILITY_DOG = 'pets',
  DEFAULT_PET = 'pets'
}

export enum ParamToProductEnum {
  escooter = 'FR_ESCOOTER',
  pets = 'DEFAULT_PET'
}

export enum ProductEnum {
  DEFAULT = 'DEFAULT',
  DEFAULT_PET = 'DEFAULT_PET',
  FR_HOME = 'FR_HOME',
  FR_LANDLORD = 'FR_LANDLORD',
  FR_ESCOOTER = 'FR_ESCOOTER',
  FR_MORTGAGE = 'FR_MORTGAGE',
  FR_RENT = 'FR_RENT',
  DE_LIABILITY_DOG = 'DE_LIABILITY_DOG',
  DE_PET_HEALTH = 'DE_PET_HEALTH',
  DE_LIABILITY_PRIVATE = 'DE_LIABILITY_PRIVATE',
  DE_HOME_CONTENT = 'DE_HOME_CONTENT'
}

export enum ProductTypeEnum {
  HOME = 'home',
  MORTGAGE = 'mortgage',
  BUILDING = 'building'
}

export enum ProductDefaultPackageEnum {
  FR_HOME = 'france-home-pom',
  FR_HOME_FLAT = 'france-flat-pom',
  FR_HOME_HOUSE = 'france-house-pom',
  FR_LANDLORD = 'france-landlord-pom',
  FR_ESCOOTER = 'france-nvei-pom',
  FR_MORTGAGE = 'france-mortgage-adaptive-package',
  FR_RENT = 'france-fr-rent-b2c-mila',
  DE_HOME_CONTENT = 'germany-home-content-basic',
  DE_LIABILITY_PRIVATE = 'germany-private-liability-base',
  DE_PET_HEALTH = 'germany-pet-health-v2-comfort-plus',
  DE_LIABILITY_DOG = 'germany-dog-liability-base'
}

export enum ProductExcludedBundleEnum {
  'france-flat-parents',
  'france-flat-maternal-assistant',
  'france-house-parents',
  'france-legal-protection-extended'
}

export enum ProductPackages {
  // POM
  FR_HOME_POM = 'france-home-pom',
  FR_HOME_FLAT_POM = 'france-flat-pom',
  FR_HOME_HOUSE_POM = 'france-house-pom',
  FR_LANDLORD_POM = 'france-landlord-pom',
  FR_ESCOOTER_POM = 'france-nvei-pom',
  FR_MORTGAGE_POM = 'france-mortgage-adaptive-package',
  FR_RENT_POM = 'france-rent-??',
  DE_HOME_CONTENT_POM = 'germany-home-content-plus',
  DE_LIABILITY_PRIVATE_POM = 'germany-private-liability-base',
  DE_PET_HEALTH_POM = 'germany-pet-health-ambulatory',
  DE_LIABILITY_DOG_POM = 'germany-dog-liability-base',
  // MINLEG
  FR_HOME_MINLEG = 'france-home-minleg',
  FR_HOME_FLAT_MINLEG = 'france-flat-minleg',
  FR_HOME_HOUSE_MINLEG = 'france-house-minleg',
  FR_LANDLORD_MINLEG = 'france-landlord-minleg',
  FR_ESCOOTER_MINLEG = 'france-nvei-minleg',
  FR_MORTGAGE_MINLEG = 'france-mortgage-adaptive-package',
  FR_RENT_MINLEG = 'france-rent-??',
  DE_HOME_CONTENT_MINLEG = 'germany-home-content-basic',
  DE_LIABILITY_PRIVATE_MINLEG = 'germany-private-liability-base',
  DE_PET_HEALTH_MINLEG = 'germany-pet-health-v2-pet-health-comfort-plus',
  DE_LIABILITY_DOG_MINLEG = 'germany-dog-liability-base'
}
