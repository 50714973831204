import { get, set } from 'lodash-es';
import { reactive } from 'vue';

import { useProspect } from '../useProspect';
import { VALID_TRACKINFO_PATHS } from './useExtraInfo.types';

const extraInfos = reactive({});
const { setProspectData } = useProspect();

const setExtraInfo = <T>(key: VALID_TRACKINFO_PATHS, data: T, persistIntoProspect = false) => {
  if (persistIntoProspect) setProspectData(`other_info.${key}`, data);
  return set(extraInfos, key, data);
};

const getExtraInfo = <T>(key: VALID_TRACKINFO_PATHS): T => {
  return get(extraInfos, key);
};

const restoreExtraInfoFromProspect = (prospectData) => {
  Object.assign(extraInfos, prospectData.other_info);
};

const resetExtraInfo = () => {
  Object.assign(extraInfos, {});
};

export const useExtraInfo = () => {
  return {
    extraInfos,
    setExtraInfo,
    getExtraInfo,
    resetExtraInfo,
    restoreExtraInfoFromProspect
  };
};
