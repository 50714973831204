import isArray from 'lodash-es/isArray';
import isEmpty from 'lodash-es/isEmpty';
import isNil from 'lodash-es/isNil';

import { useNavigation } from '@/composables/app/useNavigation';
import { usePartner } from '@/composables/app/usePartner';
import { PartnerNameEnum } from '@/composables/app/usePartner/usePartner.types';
import { useProspect } from '@/composables/states/useProspect';
import { useState } from '@/composables/states/useState';
import { useStorage } from '@/composables/utils/useStorage';
import { MortgageMainAssetTypeEnum } from '@/types/Mortgage';
import { ProductEnum } from '@/types/Product';

const { getFromLocalStorage } = useStorage();
const { getProspectData, setProspectData, PROSPECT_DATA_PATH, MORTGAGE_PROSPECT_DATA_PATH } =
  useProspect();
const { isBypass, isFirstLanded } = useNavigation();
const { stateData } = useState();

const getQueryValue = (query, fallback) => {
  if (isNil(query)) return fallback;
  return isArray(query) ? query[0] : query;
};

const getPartnerName = (query) => {
  if (isEmpty(query)) return null;

  const { partner_name, utm_campaign, utm_source } = query;

  if (partner_name) return partner_name;

  const local_utm = getFromLocalStorage('about_utm');
  const source = getQueryValue(utm_source, local_utm?.current_utm_source);
  const campaign = getQueryValue(utm_campaign, local_utm?.current_utm_campaign) || null;

  /* 
    For contracts coming from API and URL - utm_source=partner AND utm_campaign
    For contracts coming from BO - utm_campaign AND utm_content=BO
  */

  if (source?.toUpperCase() === 'VEEPEE') return source;
  if (campaign) return campaign;

  return null;
};

export const partnerQueryGuard = (to, from, next) => {
  const { query } = to;
  const partner_name = getPartnerName(query);

  if (!partner_name) return next();

  if (partner_name.toUpperCase() === 'MOVEHUB') {
    window.location.replace('https://luko.page.link/prix-garanties');
    return;
  }

  const { partnerName, isAggregator, isPartner } = usePartner();
  const { partner_user_id } = query;

  partnerName.value = partner_name;
  isAggregator.value = getQueryValue(query.utm_medium, '')?.toLowerCase() === 'aggregator';
  isPartner.value = getQueryValue(query.utm_source, '')?.toLowerCase() === 'partner';

  if (partner_user_id) {
    setProspectData(PROSPECT_DATA_PATH.subscriber.additional_data.external_id, partner_user_id);
  }

  // FR_HOME Aggregator adaptation.
  if (
    getProspectData(PROSPECT_DATA_PATH.product) === ProductEnum.FR_HOME &&
    isAggregator.value &&
    isFirstLanded.value
  ) {
    isBypass.value = true;
  }

  // FR_MORTGAGE Aggregator adaptation.
  if (
    getProspectData(PROSPECT_DATA_PATH.product) === ProductEnum.FR_MORTGAGE &&
    (query['$.key'] || query['pk'])
  ) {
    if (
      [PartnerNameEnum.LESFURETS, PartnerNameEnum.ASSURLAND].includes(partner_name?.toLowerCase())
    ) {
      stateData.value.aggregatorOnePager = true;
      getProspectData(MORTGAGE_PROSPECT_DATA_PATH.loan_list)?.forEach(
        (loan) => (loan.initial_start_date = undefined)
      );

      if (getProspectData(MORTGAGE_PROSPECT_DATA_PATH.borrower.email)) {
        setProspectData(MORTGAGE_PROSPECT_DATA_PATH.borrower.email, undefined);
      }
      if (
        [
          MortgageMainAssetTypeEnum.INSURED_BY_BANK,
          MortgageMainAssetTypeEnum.INSURED_BY_REINSURER
        ].includes(getProspectData(MORTGAGE_PROSPECT_DATA_PATH.main_asset_type))
      ) {
        setProspectData(
          MORTGAGE_PROSPECT_DATA_PATH.main_asset_type,
          MortgageMainAssetTypeEnum.TO_SPECIFY
        );
      }
    }
    if (partner_name?.toLowerCase() === PartnerNameEnum.ASSURLAND) {
      setProspectData(MORTGAGE_PROSPECT_DATA_PATH.main_asset_type, undefined);
      setProspectData(MORTGAGE_PROSPECT_DATA_PATH.bank_name, undefined);
    }
    isBypass.value = true;
  }

  return next();
};
