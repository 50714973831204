const defaultLocale = 'en';
const locale = (navigator.language || navigator['userLanguage'] || defaultLocale)
  .split('-')[0]
  ?.toLowerCase();

export const LOCALES = [
  { value: 'fr', wording: 'Français', code: 'FR_FR' },
  { value: 'en', wording: 'English', code: 'EN_UK' },
  { value: 'de', wording: 'Deutsch', code: 'DE_DE' }
];

export const AVAILABLE_LOCALES = LOCALES.map((l) => l.value?.toLowerCase());
export const DEFAULT_LOCALE = AVAILABLE_LOCALES.includes(locale) ? locale : defaultLocale;

export const getLocalCode = (val: string) => {
  const loc = LOCALES.find((l) => l.value === val?.toLowerCase());
  return loc ? loc.code : 'FR_FR';
};
