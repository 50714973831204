import { computed, ref } from 'vue';

import { ContractStateEnum } from '@/api/contract/contract.types';
import { Prospect } from '@/api/prospect/types';
import { useProduct } from '@/composables/app/useProduct';
import { useUser } from '@/composables/app/useUser';
import { useProspect } from '@/composables/states/useProspect';
import { useCrypto } from '@/composables/utils/useCrypto';
import { useStorage } from '@/composables/utils/useStorage';
import { i18n } from '@/i18n';
import { HomeTypeEnum } from '@/types/Home';

import { UserPathEnum } from '../app/useUser/useUser.types';

const { getHashText } = useCrypto();
const { fetchUserLoggedData, getUserData } = useUser();
const { getProspectData, PROSPECT_DATA_PATH, prospectData } = useProspect();
const { isHomeInsurance, checkIsHomeInsurance } = useProduct();
const { isLoggedIn } = useUser();
const { getFromLocalStorage } = useStorage();

const displayWarning = ref(false);
const duplicatedContractId = ref();

const contractComparisonRules = [
  {
    id: 'HOME_INSURANCE',
    conditions: [(p) => checkIsHomeInsurance(p?.product)],
    getExistingContract(contracts) {
      const product = getProspectData(PROSPECT_DATA_PATH.product);
      const address = getProspectData(PROSPECT_DATA_PATH.address_main);
      const surface = getProspectData(PROSPECT_DATA_PATH.surface);
      return contracts?.filter(
        (contract) =>
          contract.product_code === product &&
          contract.home.surface === surface &&
          contract.is_signed === true &&
          [ContractStateEnum.ACTIVE, ContractStateEnum.NOT_YET_ACTIVE].includes(contract.state) &&
          address.includes(contract.home.address.street_and_number)
      )[0]?.id;
    },
    async compareWithHash(localStorageHashedContract) {
      return (await this.getHash()) === localStorageHashedContract;
    },
    async getHash() {
      return getHashText(
        [PROSPECT_DATA_PATH.product, PROSPECT_DATA_PATH.address_main, PROSPECT_DATA_PATH.surface]
          .map((path) => getProspectData(path))
          .join(' ')
      );
    }
  }
];

const getRules = (p: Prospect) => {
  for (const r of contractComparisonRules) {
    if (r.conditions.every((condition) => condition(p))) {
      return r;
    }
  }
  return null;
};

const checkContractExist = async () => {
  const localSignedContractInfo = getFromLocalStorage('signed_contract');
  const rule = getRules(prospectData);
  if (!rule) {
    displayWarning.value = false;
    return;
  }

  if (!isLoggedIn.value) {
    displayWarning.value = await rule.compareWithHash(localSignedContractInfo);
    return;
  }

  await fetchUserLoggedData(true);

  duplicatedContractId.value = rule.getExistingContract(getUserData(UserPathEnum.contracts));
  displayWarning.value = !!duplicatedContractId.value;
};

const duplicatedDescription = computed(() => {
  if (isHomeInsurance.value) {
    const address = getProspectData(PROSPECT_DATA_PATH.address_main);
    const surface = getProspectData(PROSPECT_DATA_PATH.surface);
    const type = getProspectData(PROSPECT_DATA_PATH.house_type);

    const typeLabel =
      type === HomeTypeEnum.HOUSE
        ? i18n.global.t('Popups.DuplicatedContractPopup.home.house')
        : i18n.global.t('Popups.DuplicatedContractPopup.home.flat');

    return i18n.global.t('Popups.DuplicatedContractPopup.home.descripton', {
      surface,
      address,
      type: typeLabel
    });
  }
  return null;
});

export const useDuplicatedContractChecker = () => ({
  checkContractExist,
  duplicatedDescription,
  displayWarning,
  duplicatedContractId,
  getRules
});
