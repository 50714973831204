import { useNavigation } from '@/composables/app/useNavigation';

export const productBypassGuard = async (to, _, next) => {
  const query = to.query;
  const { isOneTimeBypass } = useNavigation();
  if (query['$.product'] || query.product) {
    isOneTimeBypass.value = true;
  }

  next();
};
