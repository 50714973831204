import { useSteps } from '@/composables/app/useSteps';
import { useState } from '@/composables/states/useState';

const { stateData } = useState();
const {
  checkIsPostSignSteps,
  checkIsSignatureStep,
  checkIsPricingStep,
  checkIsPaymentStep,
  checkIsRecapStep
} = useSteps();

export const blockSignatureGuard = async (to, from, next) => {
  const fromHash = (from.hash || '').replace('#', '');
  const toHash = (to.hash || '').replace('#', '');

  if (
    checkIsPostSignSteps(fromHash) &&
    (checkIsSignatureStep(toHash) ||
      checkIsPricingStep(toHash) ||
      checkIsPaymentStep(toHash) ||
      checkIsRecapStep(toHash)) &&
    stateData.value.signatureDone
  ) {
    return new Promise((resolve) => {
      stateData.value.blockSignatureCallback = () => {
        resolve(next(false));
      };
    });
  }
  return next();
};
