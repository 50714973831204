import { Slice } from '@prismicio/types';
import { computed, ComputedRef, reactive, ref } from 'vue';

import { Prospect } from '@/api/prospect/types';
import { useUser } from '@/composables/app/useUser';
import { useEnv } from '@/composables/utils/useEnv';
import { i18n } from '@/i18n';
import { HomeOccupantTypeEnum, HomeTypeEnum } from '@/types/Home';
import { ProductEnum } from '@/types/Product';
import DESuccessPage from '@@/script/prismic/data/offer_step.de.json';
import ENSuccessPage from '@@/script/prismic/data/offer_step.en.json';
import FRSuccessPage from '@@/script/prismic/data/offer_step.fr.json';

import { CrossSellRestrictions, CrossSellTemplateId } from './useCrossSell.types';

const pageDataByLocale = {
  en: ENSuccessPage,
  fr: FRSuccessPage,
  de: DESuccessPage
};
const getTemplateIds = () =>
  Object.keys(CrossSellTemplateId).reduce(function (accum, currentVal) {
    accum[currentVal] = CrossSellTemplateId[currentVal];
    return accum;
  }, {});

const landedWithCrossSell = ref(false);
const { isProd } = useEnv();
const { hasSubscribedProducts } = useUser();

const crossSellTemplates = reactive(getTemplateIds());

const FR_HOME_PRODUCTS = [ProductEnum.FR_HOME];

const checkIsFrSecondary = (p: Prospect) =>
  !p.home?.main_asset.is_main &&
  !p.home?.main_asset.owner_type &&
  p.product === ProductEnum.FR_HOME;

const crossSellRules: ComputedRef<CrossSellRestrictions[]> = computed(() =>
  [
    // FR_HOME (owner) => MORTGAGE
    {
      id: crossSellTemplates[CrossSellTemplateId.FR_HOME__FR_MORTGAGE],
      conditions: [
        (p: Prospect) =>
          FR_HOME_PRODUCTS.includes(p.product) &&
          p.home?.main_asset.occupant_type === HomeOccupantTypeEnum.OWNER &&
          !['de'].includes(i18n.global.locale)
      ]
    },

    // FR_HOME (renter) => MORTGAGE (if has landlord)
    {
      id: crossSellTemplates[CrossSellTemplateId.FR_HOME__FR_MORTGAGE],
      conditions: [
        (p: Prospect) =>
          FR_HOME_PRODUCTS.includes(p.product) &&
          hasSubscribedProducts([ProductEnum.FR_LANDLORD]) &&
          p.home?.main_asset.occupant_type === HomeOccupantTypeEnum.TENANT &&
          !['de'].includes(i18n.global.locale)
      ]
    },

    // FR_ESCOOTER => HOME
    {
      id: crossSellTemplates[CrossSellTemplateId.FR_ESCOOTER__FR_HOME],
      conditions: [
        (p: Prospect) =>
          [ProductEnum.FR_ESCOOTER].includes(p.product) && !['de'].includes(i18n.global.locale)
      ]
    },

    // FR_ESCOOTER => MORTGAGE (if has home)
    {
      id: crossSellTemplates[CrossSellTemplateId.FR_ESCOOTER__FR_MORTGAGE],
      conditions: [
        (p: Prospect) =>
          [ProductEnum.FR_ESCOOTER].includes(p.product) &&
          hasSubscribedProducts([ProductEnum.FR_HOME]) &&
          !['de'].includes(i18n.global.locale)
      ]
    },

    // FR_LANDLORD => MORTGAGE (if hasn't mortgage)
    {
      id: crossSellTemplates[CrossSellTemplateId.FR_LANDLORD__FR_MORTGAGE],
      conditions: [
        (p: Prospect) =>
          [ProductEnum.FR_LANDLORD].includes(p.product) &&
          !hasSubscribedProducts([ProductEnum.FR_MORTGAGE]) &&
          !['de'].includes(i18n.global.locale)
      ]
    },

    // FR_LANDLORD => HOME (if has mortgage)
    {
      id: crossSellTemplates[CrossSellTemplateId.FR_LANDLORD__FR_HOME],
      conditions: [
        (p: Prospect) =>
          [ProductEnum.FR_LANDLORD].includes(p.product) &&
          hasSubscribedProducts([ProductEnum.FR_MORTGAGE]) &&
          !['de'].includes(i18n.global.locale)
      ]
    },

    // FR_LANDLORD => SECONDARY (if has mortgage or fr_home)
    {
      id: crossSellTemplates[CrossSellTemplateId.FR_LANDLORD__FR_SECONDARY],
      conditions: [
        (p: Prospect) =>
          [ProductEnum.FR_LANDLORD].includes(p.product) &&
          hasSubscribedProducts([ProductEnum.FR_MORTGAGE, ProductEnum.FR_HOME]) &&
          !['de'].includes(i18n.global.locale)
      ]
    },

    // FR_SECONDARY => MORTGAGE  (if has fr_home)
    {
      id: crossSellTemplates[CrossSellTemplateId.FR_SECONDARY__FR_MORTGAGE],
      conditions: [
        (p: Prospect) =>
          (([ProductEnum.FR_LANDLORD].includes(p.product) &&
            p.home?.main_asset.type === HomeTypeEnum.HOUSE) ||
            checkIsFrSecondary(p)) &&
          hasSubscribedProducts([ProductEnum.FR_HOME]) &&
          !['de'].includes(i18n.global.locale)
      ]
    },

    // FR_SECONDARY => HOME
    {
      id: crossSellTemplates[CrossSellTemplateId.FR_SECONDARY__FR_HOME],
      conditions: [
        (p: Prospect) =>
          (([ProductEnum.FR_LANDLORD].includes(p.product) &&
            p.home?.main_asset.type === HomeTypeEnum.HOUSE) ||
            checkIsFrSecondary(p)) &&
          !hasSubscribedProducts([ProductEnum.FR_MORTGAGE]) &&
          !['de'].includes(i18n.global.locale)
      ]
    }
  ].sort((a, b) => b.conditions.length - a.conditions.length)
);

const getRules = (p: Prospect): CrossSellRestrictions | null => {
  for (const r of crossSellRules.value) {
    if (r.conditions.every((condition) => condition(p))) return r;
  }
  return null;
};

const getContent = (p: Prospect, id?: string): Slice | null => {
  const rule = getRules(p);
  const crossSellId = id ? id : rule?.id;
  if (crossSellId) {
    const template = pageDataByLocale[i18n.global.locale].body?.find(
      ({ primary: { cross_sell_id } }) => cross_sell_id === crossSellId
    );
    if (!template?.primary?.prod && isProd) return null;
    return template;
  }
  return null;
};

const getCurrentTemplateId = (p: Prospect) => {
  const rule = getRules(p);
  return rule?.id;
};

const replaceTemplateId = (templateId: string, newTemplateId: string) => {
  Object.keys(crossSellTemplates).forEach(function (key) {
    if (crossSellTemplates[key] === templateId) {
      crossSellTemplates[key] = newTemplateId;
    }
  });
};

const cherryPickForCrossSell = (newProspect: Prospect, oldProspect: Prospect) => {
  if (checkIsFrSecondary(newProspect))
    return {
      user: oldProspect.user,
      home: {
        main_asset: {
          occupant_type: HomeOccupantTypeEnum.OWNER,
          owner_type: null
        }
      }
    };
  return;
};

const needsCrossSellStep = (p: Prospect) => getCurrentTemplateId(p) !== undefined;

export const useCrossSell = () => ({
  pageDataByLocale,
  crossSellRules,
  getRules,
  getContent,
  replaceTemplateId,
  cherryPickForCrossSell,
  landedWithCrossSell,
  crossSellTemplates,
  getCurrentTemplateId,
  needsCrossSellStep
});
