import { ref } from 'vue';

const isIntercomLoaded = ref(false);

export const useIntercom = () => {
  const showIntercom = (preFilledMessage?: string) => {
    if (window.Intercom) {
      preFilledMessage
        ? window.Intercom('showNewMessage', preFilledMessage)
        : window.Intercom('show');
    } else {
      window.Didomi?.notice.show();
    }
  };

  const hideIntercomLauncher = () => {
    if (window.Intercom) {
      window.Intercom('update', {
        hide_default_launcher: true
      });
    }
  };

  const showIntercomLauncher = () => {
    if (window.Intercom) {
      window.Intercom('update', {
        hide_default_launcher: false
      });
    }
  };

  const sendIntercomMessage = (message) => {
    if (window.Intercom) {
      window.Intercom('showNewMessage', message);
    } else {
      window.Didomi?.notice.show();
    }
  };

  const loginIntercom = (email, token, firstname, lastname) => {
    if (!window.Intercom) return;

    window.Intercom('update', {
      email: email,
      user_hash: token,
      firstname: firstname,
      lastname: lastname
    });
  };

  const logoutIntercom = () => {
    if (!window.Intercom) return;

    window.Intercom('shutdown');
    window.Intercom('boot', { app_id: import.meta.env.VUE_APP_INTERCOM_KEY });
  };

  return {
    isIntercomLoaded,
    showIntercom,
    sendIntercomMessage,
    loginIntercom,
    logoutIntercom,
    hideIntercomLauncher,
    showIntercomLauncher
  };
};
