export enum CountryEnum {
  FR = 'France',
  DE = 'Germany',
  GB = 'United Kingdom',
  IT = 'Italy'
}

export enum CountryCodeEnum {
  France = 'FR',
  Germany = 'DE',
  UnitedKingdom = 'GB',
  Italy = 'IT'
}
