import { cloneDeep } from 'lodash-es';
import { computed, ref, watch } from 'vue';

import { useRecaptcha } from '@/composables/app/useRecaptcha';
import { useProspect } from '@/composables/states/useProspect';
import { useUnleash } from '@/composables/states/useUnleash';
import { UnleashFlagKeys } from '@/composables/states/useUnleash/useUnleash.types';
import { useArray } from '@/composables/utils/useArray';
import { useEnv } from '@/composables/utils/useEnv';
import DEFAULT_STEPS_ORDER from '@/modules/StepsOrder.json';
import { ProductEnum } from '@/types/Product';

import { useStepLogic } from '../useStepLogic';
import {
  PillGroupEnum,
  StepInterface,
  StepUpdaterActionEnum,
  StepUpdaterInterface
} from './useSteps.types';

const { getProspectData, PROSPECT_DATA_PATH } = useProspect();
const { moveItem } = useArray();
const { isHostingEnv, isE2E } = useEnv();
const { lazyLoadStep } = useStepLogic();
const { getVariant } = useUnleash();

// List of step objects
const productSteps = computed<StepInterface[]>(() => {
  const product = getProspectData(PROSPECT_DATA_PATH.product) || 'DEFAULT';
  return stepsOrder.value[product];
});

// List of pills name and group
const pillsList = computed(() => {
  const mapped = productSteps.value?.map((item) => ({ pill: item.pill, group: item.group }));
  return [...new Map(mapped?.map((item) => [item['pill'], item])).values()];
});

// List of steps name
const stepsList = computed(() => productSteps.value.map((item) => item.step));

// Get Current Pill name
const currentPill = computed(() => {
  const idx = productSteps.value.findIndex((item) => item.step === currentStep.value);
  return productSteps.value[idx]?.pill;
});

const currentPillIndex = computed(() => {
  const idx = productSteps.value.findIndex((item) => item.step === currentStep.value);
  const pillName = productSteps.value[idx]?.pill;
  return pillsList.value.findIndex((item) => item.pill === pillName);
});

const getFirstStep = async () => {
  const stepsList = cloneDeep(productSteps.value);
  const listwithStatus = await Promise.all(
    stepsList.map(async (step) => ({ ...step, status: await getLogicStatus(step.step) }))
  );
  const listRealStep = listwithStatus.filter((step) => step.status);
  return listRealStep[0];
};

const getLogicStatus = async (step) => {
  const stepFile = (await lazyLoadStep(step)).default;
  const logic = stepFile?.stepLogic;
  const result = !logic?.shouldBeSkipped?.();
  return result;
};

// Get Pills progress
const pillsProgresses = computed(() => {
  const result: {
    pill: string;
    group: string;
    totalStep: number;
    passedStep: number;
    progress: number;
    isCurrent: boolean;
  }[] = [];
  pillsList.value.forEach(({ pill, group }, index) => {
    const totalStep = productSteps.value.filter((step) => step.pill === pill).length;
    let passedStep = 0;
    productSteps.value
      .filter((step, index) => {
        step.originalIndex = index;
        return step.pill === pill;
      })
      .forEach((step) => {
        if (step.originalIndex <= currentStepIndex.value) {
          passedStep++;
        }
      });
    result.push({
      pill,
      group,
      totalStep,
      passedStep,
      progress: (passedStep / totalStep) * 100,
      isCurrent: currentPillIndex.value === index
    });
  });
  return result;
});

// Current Step index
const currentStepIndex = computed(() => {
  const idx = stepsList.value.findIndex((step) => step === currentStep.value);
  return idx <= 0 ? 0 : idx;
});

// Detect if step passed isAfter step
const hasPassedStep = (isAfter: string, step = currentStep.value) => {
  const indexCurrentStep = stepsList.value.indexOf(step);
  const indexStep = stepsList.value.findIndex((item) => item === isAfter);

  if (indexStep === -1) return false;

  return indexCurrentStep > indexStep;
};

// Check Functions
const checkIsPricingStep = (step: string) => step?.includes('Pricing');
const checkIsRecapStep = (step: string) => step?.includes('Recap');
const checkIsPaymentStep = (step: string) => step?.includes('Payment');
const checkIsPostProspect = (step: string): boolean =>
  ['payment', 'signature', 'recap'].includes(step?.toLowerCase()) ||
  checkIsPostPricingStep(step) ||
  checkIsPostSignSteps(step) ||
  checkIsPricingStep(step);
const checkIsSignatureStep = (step: string) => step?.includes('Signature');
const checkIsPostSignSteps = (step: string) =>
  getVariant(UnleashFlagKeys.RECAP_PAYMENT) === 'recap' &&
  getProspectData(PROSPECT_DATA_PATH.product) === ProductEnum.FR_HOME
    ? hasPassedStep('Payment', step)
    : hasPassedStep('Signature', step);
const checkIsPostPricingStep = (step: string) => hasPassedStep('Pricing', step);

const hash = window.location.hash?.replace('#', '');
const stepsOrder = ref<{ [key: string]: unknown[] }>(DEFAULT_STEPS_ORDER);
const currentStep = ref<string>(checkIsPostProspect(hash) ? hash : '');

// Flag list
const isPricingStep = computed(() => checkIsPricingStep(currentStep.value));
const isRecapStep = computed(() => checkIsRecapStep(currentStep.value));
const isSignatureStep = computed(() => checkIsSignatureStep(currentStep.value));
const isMaintenanceStep = computed(() => currentStep.value?.includes('Maintenance'));
const isOfferStep = computed(() => currentStep.value?.includes('Offer'));
const isPaymentStep = computed(() => checkIsPaymentStep(currentStep.value));
const isProspectStep = computed(() => !checkIsPostProspect(currentStep.value));
const isPostProspectStep = computed(() => checkIsPostProspect(currentStep.value));
const isPostSignStep = computed(() => checkIsPostSignSteps(currentStep.value));
const isUnavailableStep = computed(() => currentStep.value?.includes('Unavailable'));
const isQuoteStep = computed(
  () => checkIsPostProspect(currentStep.value) && !hasPassedStep('Payment', currentStep.value)
);

// Step updater
const updateSteps = (payload: StepUpdaterInterface[]) => {
  payload.forEach((item) => {
    updateOneStep(item);
  });
};

const updateOneStep = (update: StepUpdaterInterface) => {
  switch (update.action) {
    case StepUpdaterActionEnum.ADD_AFTER:
      performUpdate(update.product, (stepsArray) => {
        const targetPosition = stepsArray.findIndex((item) => item.step === update.after);
        if (targetPosition !== -1) {
          stepsArray.splice(targetPosition + 1, 0, {
            step: update.stepName,
            pill: update.pill,
            group: update.group
          });
        } else {
          return window.Logger.$log('Target not found');
        }
      });
      break;
    case StepUpdaterActionEnum.ADD_BEFORE:
      performUpdate(update.product, (stepsArray) => {
        const targetPosition = stepsArray.findIndex((item) => item.step === update.before);
        if (targetPosition !== -1) {
          stepsArray.splice(targetPosition, 0, {
            step: update.stepName,
            pill: update.pill,
            group: update.group
          });
        } else {
          return window.Logger.$log('Target not found');
        }
      });
      break;
    case StepUpdaterActionEnum.DELETE:
      performUpdate(update.product, (stepsArray) => {
        const stepPosition = stepsArray.findIndex((item) => item.step === update.stepName);
        if (stepPosition !== -1) {
          stepsArray.splice(stepPosition, 1);
        } else {
          return window.Logger.$log('Step not found');
        }
      });
      break;
    case StepUpdaterActionEnum.MOVE_AFTER:
      performUpdate(update.product, (stepsArray) => {
        const stepPosition = stepsArray.findIndex((item) => item.step === update.stepName);
        const targetPosition = stepsArray.findIndex((item) => item.step === update.after);
        if (stepPosition !== -1) {
          moveItem(stepsArray, stepPosition, targetPosition);
        } else {
          return window.Logger.$log('Step not found');
        }
      });
      break;
    case StepUpdaterActionEnum.MOVE_BEFORE:
      performUpdate(update.product, (stepsArray) => {
        const stepPosition = stepsArray.findIndex((item) => item.step === update.stepName);
        const targetPosition = stepsArray.findIndex((item) => item.step === update.before) - 1;
        if (stepPosition !== -1) {
          moveItem(stepsArray, stepPosition, targetPosition);
        } else {
          return window.Logger.$log('Step not found');
        }
      });
      break;
    case StepUpdaterActionEnum.REPLACE:
      performUpdate(update.product, (stepsArray) => {
        stepsArray.forEach((item) => {
          if (item.step === update.toReplace) {
            item.step = update.with;
          }
        });
      });
      break;
    case StepUpdaterActionEnum.REPLACE_PILL:
      performUpdate(update.product, (stepsArray) => {
        stepsArray.forEach((item) => {
          if (item.step === update.stepName) {
            item.pill = update.pill;
          }
        });
      });
      break;
  }
};

const performUpdate = (product, action) => {
  if (!product || product === 'all') {
    Object.keys(stepsOrder.value).forEach((step) => action(stepsOrder.value[step]));
  } else if (product.length == 2) {
    Object.keys(stepsOrder.value)
      .filter((product_code) => product_code.startsWith(product))
      .forEach((step) => action(stepsOrder.value[step]));
  } else {
    Object.keys(stepsOrder.value)
      .filter((product_code) => product_code === product)
      .forEach((step) => action(stepsOrder.value[step]));
  }
};
const { checkStep } = useRecaptcha();

watch(
  currentStep,
  (value) => {
    if (!isHostingEnv || isE2E.value) return;

    checkStep(value);
  },
  { immediate: true }
);

const currentPillGroup = computed(() => {
  if (isProspectStep.value) return PillGroupEnum.PROSPECT;
  if (!isProspectStep.value && !isPostSignStep.value) return PillGroupEnum.QUOTE;
  if (isPostSignStep.value) return null;
  return null;
});

export const useSteps = () => ({
  //Steps
  currentStep,
  currentStepIndex,
  stepsList,
  getFirstStep,

  //Pills
  currentPill,
  currentPillIndex,
  currentPillGroup,
  pillsList,
  pillsProgresses,

  //Flag
  isPricingStep,
  isSignatureStep,
  isPostProspectStep,
  isPostSignStep,
  isOfferStep,
  isPaymentStep,
  isUnavailableStep,
  isRecapStep,
  isQuoteStep,
  isMaintenanceStep,

  // Check functions
  checkIsPricingStep,
  checkIsRecapStep,
  checkIsPaymentStep,
  checkIsSignatureStep,
  checkIsPostProspect,
  checkIsPostSignSteps,

  // Flexible steps
  updateSteps,

  hasPassedStep
});
