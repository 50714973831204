<script lang="ts" setup>
import { ArrowLeft, ArrowRight, LukoButton } from '@demain-es/lukompo';
import { computed, onMounted, onUnmounted, PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import { useNavigation } from '@/composables/app/useNavigation';
import { useSteps } from '@/composables/app/useSteps';
import { useState } from '@/composables/states/useState';

const props = defineProps({
  // Submit
  disableSubmit: {
    type: Boolean,
    default: true
  },
  canClickSubmitButton: {
    type: Boolean,
    default: false
  },
  submitText: {
    type: String,
    default: ''
  },
  beforeSubmit: {
    type: Function,
    default: async (go: () => Promise<void>) => {
      await go();
    }
  },
  onSubmit: {
    type: Function,
    default: () => null
  },
  submitAction: {
    type: Function,
    default: null
  },
  submitIcon: {
    type: Function as PropType<typeof ArrowRight>,
    default: undefined
  },
  hasSubmitIconRight: {
    type: Boolean,
    default: true
  },

  // GoBack
  canGoBack: {
    type: Boolean,
    default: true
  },
  beforeGoBack: {
    type: Function,
    default: async (go: () => Promise<void>) => {
      await go();
    }
  },
  onGoBack: {
    type: Function,
    default: () => null
  },

  // Second CTA
  secondCtaText: {
    type: String,
    default: null
  },
  secondCtaClicked: {
    type: Function,
    default: null
  },
  secondCtaIcon: {
    type: Object as PropType<typeof ArrowRight>,
    default: undefined
  },
  secondCtaDisabled: {
    type: Boolean,
    default: false
  },

  // States
  isLoading: {
    type: Boolean,
    default: false
  },
  speakerMargin: {
    type: Boolean,
    default: true
  }
});

const emits = defineEmits(['submitProhibited']);

const { currentStepIndex, isUnavailableStep } = useSteps();
const { goNext, goBack } = useNavigation();
const { t } = useI18n();
const { stateData } = useState();

const stepCanGoBack = computed(() => {
  if (isUnavailableStep.value) return props.canGoBack;
  return currentStepIndex.value !== 0 && props.canGoBack;
});

const isSlowLoading = computed(() => stateData.value.slowConnectionLoader);

const onNext = () => {
  if (props.disableSubmit && !props.canClickSubmitButton) return;
  if (props.disableSubmit && props.canClickSubmitButton) return emits('submitProhibited');

  props.beforeSubmit(triggerSubmit);
};

const triggerSubmit = async () => {
  props.onSubmit();

  if (props.submitAction) {
    props.submitAction();
  } else {
    await goNext();
  }
};

const triggerGoBack = async () => {
  props.onGoBack();
  await goBack();
};

const submitIconComputed = computed(() => {
  if (props.submitIcon) return props.submitIcon;
  return ArrowRight;
});

const handleKeyboardPressed = (e) => {
  if (
    e.key === 'Enter' &&
    ['body', 'input', 'select'].includes(e.target?.localName) &&
    !stateData.value.loginCallback
  ) {
    onNext();
    e.preventDefault();
  }
};

onMounted(() => {
  document?.addEventListener('keypress', handleKeyboardPressed);
});

onUnmounted(() => {
  document?.removeEventListener('keypress', handleKeyboardPressed);
});
</script>

<template>
  <div :class="$style.navContainer">
    <slot />
    <div :class="$style.buttonsContainer">
      <div :class="$style.leftButtonContainer">
        <transition name="fade" mode="out-in" :duration="500">
          <LukoButton
            v-if="stepCanGoBack"
            id="backButton"
            :icon="ArrowLeft"
            :class="$style.button"
            theme="secondary"
            data-test="prospectBackButton"
            @click="beforeGoBack(triggerGoBack)"
          >
            {{ t('Navbar.back') }}
          </LukoButton>
          <div v-else />
        </transition>
      </div>
      <LukoButton
        v-if="secondCtaText && secondCtaClicked"
        :class="[$style.button, $style.secondCta]"
        :icon="secondCtaIcon"
        light
        :disabled="secondCtaDisabled"
        data-test="prospectSecondButton"
        @click="secondCtaClicked()"
      >
        {{ secondCtaText }}
      </LukoButton>

      <LukoButton
        :icon="submitIconComputed"
        :class="$style.button"
        :icon-on-right="hasSubmitIconRight"
        :disabled="disableSubmit && !canClickSubmitButton"
        :loading="isLoading || isSlowLoading"
        data-test="prospectNextButton"
        @click="onNext"
      >
        {{ submitText || t('Onboarding.AddressStep.next') }}
      </LukoButton>
    </div>
  </div>
</template>

<style lang="scss" module>
.navContainer {
  display: flex;
  flex-direction: column;
}
.buttonsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  justify-content: center;

  @include above(small) {
    display: flex;
    justify-content: space-between;
  }
}

.leftButtonContainer {
  position: relative;

  flex-grow: 1;
}

.button,
.leftButtonContainer {
  width: 100%;

  @include above(small) {
    width: auto;
  }
}

.secondCta {
  grid-row: 1;
  grid-column: 1 / 3;
}
</style>
