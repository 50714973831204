export const processEvent = (braze, eventName, eventData, __) => {
  const email = eventData.user.subscriber.email;

  if (email) {
    braze.getUser().addAlias(email, 'email');
  }

  if (eventName === 'Contract Subscribed' || eventName === 'Documents Uploaded') {
    const pack = eventData.pricing.pack;
    const price = eventData.pricing.monthly_price;

    braze.logPurchase(pack, price, 'EUR');
  }

  if (
    eventName === 'Quote Seen' ||
    eventName === 'Contract Paid' ||
    eventName === 'Contract Subscribed' ||
    eventName === 'Documents Uploaded'
  ) {
    braze.logCustomEvent(eventName, {
      product: eventData.product,
      pack: eventData.pricing.pack,
      key: eventData?.key,
      contract_id: eventData.contract?.contract_id
    });
    braze.logCustomEvent(eventName + ' ' + eventData.product, {
      product: eventData.product,
      pack: eventData.pricing.pack
    });
  } else {
    braze.logCustomEvent(eventName);
  }

  const customDataMapping = {
    home_adress: 'home.main_asset.address_main',
    home_zipcode: 'home.main_asset.zipcode',
    home_type: 'home.main_asset.type',
    home_city: 'home.main_asset.city',
    home_room_number: 'home.main_asset.room_number',
    home_size: 'home.main_asset.size',
    product: 'product',
    pack: 'pricing.pack',
    home_occupant_type: 'home.main_asset.occupant_type',
    monthly_price: 'pricing.monthly_price',
    is_settled: 'user.subscriber.is_settled',
    key: 'key',
    start_date: 'start_date',
    nvei_brand: 'nvei.main_asset.brand',
    nvei_type: 'nvei.main_asset.type',
    coupon: 'pricing.coupon.promo_code',
    coupon_bandit: 'pricing.commercial_offer.coupon_code',
    contract_discount_type: 'coupon.discount_type',
    partner_name: 'partner.name',
    first_utm_source: 'session.utm.source',
    first_utm_medium: 'session.utm.medium',
    first_utm_campaign: 'session.utm.campaign',
    current_url: 'page.url',
    quote_country: 'user.subscriber.country',
    contract_sign_url: 'postonboarding.sign_url',
    reason: 'reason',
    pet_name: 'pet.main_asset.name',
    pet_type: 'pet.main_asset.type',
    pet_birth_date: 'pet.main_asset.birth_date',
    pet_breed: 'pet.main_asset.breed',
    pet_claim_history: 'pet.main_asset.claim_history',
    pet_gender: 'pet.main_asset.gender',
    pet_illness: 'pet.main_asset.illness',
    guarantees_list: 'pricing.guarantees_list',
    mortgage_purpose_financing: 'mortgage.main_asset.purpose',
    mortgage_current_bank_name: 'mortgage.main_asset.bank_name',
    living_situation: 'other_info.living_situation',
    churn_duration: 'other_info.churn_duration',
    source: 'other_info.source'
  };

  customDataMapping['monthly_price ' + eventData.product] = 'pricing.monthly_price';

  const userDataMapping = {
    // DateOfBirth: ""
    Email: 'user.subscriber.email',
    // EmailNotificationSubscriptionType: "subscriber.has_authorized_mailing",
    FirstName: 'user.subscriber.firstname',
    HomeCity: 'home.main_asset.city',
    LastName: 'user.subscriber.lastname',
    PhoneNumber: 'user.subscriber.phone'
  };

  Object.keys(customDataMapping).forEach(function (key) {
    const value = getProperty(customDataMapping[key], eventData);

    if ((value || value === false) && value !== '' && value !== 0) {
      if (key === 'start_date') {
        braze.getUser().setCustomUserAttribute(key, new Date(value));
      } else {
        braze.getUser().setCustomUserAttribute(key, value);
      }
    }
  });

  braze.getUser().setCustomUserAttribute('quote_date', new Date().toLocaleDateString('fr-FR'));

  Object.keys(userDataMapping).forEach(function (key) {
    const value = getProperty(userDataMapping[key], eventData);

    if ((value || value === false) && value !== '' && value !== 0) {
      if (key === 'PhoneNumber') {
        braze.getUser()['set' + key](testNumber(value));
        return;
      }
      braze.getUser()['set' + key](value);
    }
  });

  // Braze subscriptionType
  let subscriptionType = 'subscribed';
  const countryCode = eventData.home.main_asset.country_code || '';
  const TRANSACTIONAL_SMS_GROUP = '619a6cc5-048b-4117-8120-cb58715133f7';
  const MARKETING_SMS_GROUP = 'd5159dcc-77cf-449e-990e-e5f86c79f244';
  if (eventData.user.subscriber.has_authorized_mailing && countryCode.toUpperCase() !== 'DE') {
    subscriptionType = 'opted_in';
    braze.getUser().addToSubscriptionGroup(MARKETING_SMS_GROUP);
  }
  if (countryCode.toUpperCase() !== 'DE') {
    braze.getUser().addToSubscriptionGroup(TRANSACTIONAL_SMS_GROUP);
  }
  braze.getUser().setEmailNotificationSubscriptionType(subscriptionType);
  braze.requestImmediateDataFlush();
};

function testNumber(nb) {
  const frNbRegex = /^(06|07)[0-9]{8}$/;
  const frPrefix = '+33';

  return frNbRegex.test(nb) ? nb.replace('0', frPrefix) : nb;
}

const getProperty = (propertyName, object) => {
  const parts = propertyName.split('.'),
    length = parts.length;
  let i,
    property = object || {};

  for (i = 0; i < length; i++) {
    if (property) property = property[parts[i]];
    else return undefined;
  }

  return property;
};
