import { addDays, differenceInMonths, differenceInYears } from 'date-fns';
import { computed } from 'vue';

import { useProspect } from '@/composables/states/useProspect';
import { useQuote } from '@/composables/states/useQuote';
import { MortgageAssetItem, MortgageTypeEnum } from '@/types/Mortgage';

import { useProduct } from '../useProduct';
import insuranceRates from './insuranceRates.json';

const { getProspectData, MORTGAGE_PROSPECT_DATA_PATH } = useProspect();
const { currentQuote } = useQuote();
const { isMortgage } = useProduct();

const loanList = computed<MortgageAssetItem[]>(() =>
  getProspectData(MORTGAGE_PROSPECT_DATA_PATH.loan_list)
);

const getInsuranceRateBasedOnProfile = (): number => {
  const birthDate = getProspectData(MORTGAGE_PROSPECT_DATA_PATH.subscriber.birth_date);
  const isSmoker = getProspectData(MORTGAGE_PROSPECT_DATA_PATH.subscriber.is_smoker);
  const age = differenceInYears(new Date(), new Date(birthDate));

  const insuranceRate = insuranceRates.find(
    (item) => item.age === age && item.isSmoker === isSmoker
  )?.average;

  return insuranceRate || 0;
};

const getSingleLoanOtherBancksExpectedPrice = (loan: MortgageAssetItem) => {
  if (!loan) return { expectedOtherBankPrice: 0, remainingPeriods: 0 };
  const {
    type,
    rate_percentage: ratePercentage,
    initial_start_date: startDate,
    initial_amount: amount,
    initial_duration_in_months: duration
  } = loan;

  if (type === MortgageTypeEnum.BRIDGE) return { expectedOtherBankPrice: 0, remainingPeriods: 0 };

  const loanDuration = duration ? duration : 0;
  const loanAmount = amount / 100;
  const rate_percentage = ratePercentage / 100;

  let monthlyPayment: number;
  if (type === MortgageTypeEnum.DEPRECIABLE) {
    monthlyPayment =
      (loanAmount * rate_percentage) / 12 / (1 - (1 + rate_percentage / 12) ** -loanDuration);
  } else if (type === MortgageTypeEnum.ZERO_INTEREST) {
    monthlyPayment = loanAmount / loanDuration;
  } else {
    monthlyPayment = 0;
  }

  const effectiveDate = addDays(new Date(), 75);
  const passedPeriods = differenceInMonths(effectiveDate, new Date(startDate));
  const totalPaid = monthlyPayment * passedPeriods;
  const remainingToPay = loanAmount - totalPaid;
  const remainingPeriods = loanDuration - passedPeriods;

  const insuranceRateBasedOnProfile = getInsuranceRateBasedOnProfile();

  const expectedOtherBankPrice =
    ((remainingToPay * insuranceRateBasedOnProfile) / 12) * remainingPeriods;

  return { expectedOtherBankPrice, remainingPeriods };
};

const mortgageSavings = computed(() => {
  if (!currentQuote.currentPrice) return 0;
  if (loanList.value?.length > 1 || !loanList.value) return 0;
  const { expectedOtherBankPrice, remainingPeriods } = getSingleLoanOtherBancksExpectedPrice(
    loanList.value?.[0]
  );

  return currentQuote.currentPrice.monthly.total * remainingPeriods > expectedOtherBankPrice
    ? 0
    : expectedOtherBankPrice - currentQuote.currentPrice.monthly.total * remainingPeriods;
});

const isMortgageSavingAmountVisible = computed(
  () => loanList.value?.length <= 1 && isMortgage.value && mortgageSavings.value > 0
);

export const useMortgageSavingAmount = () => ({
  mortgageSavings,
  isMortgageSavingAmountVisible
});
