export enum RestartReason {
  SUBSCRIBE_FROM_SIGNATURE = 'subscribe_from_signature',
  EXISTING_FLOW_CANCELLED = 'existing_flow_cancelled',
  UNAVAILABLE_STEP = 'unavailable_step',
  SIGNATURE_SESSION_EXPIRED = 'signature_session_expired',
  MISSING_PK_FOR_SIGNATURE = 'missing_pk_signature',
  MISSING_PK_FOR_PRICING = 'missing_pk_pricing',
  ERROR_NOT_HANDLED = 'error_not_handled',
  MISSING_PROSPECT_CROSS_SELL = 'missing_prospect_cross_sell',
  DE_MAINTENANCE = 'de_maintenance'
}
