export enum ValidLocalStoragePaths {
  PROSPECT_PERSISTED = 'prospect_persisted',
  PARTNER_NAME = 'partner_name',
  SIGNED_CONTRACT = 'signed_contract',
  SIGNY_STORAGE_KEY = 'signy_cache'
}

export enum ValidSessionStoragePaths {
  HAS_CHOSEN_STORAGE_KEY = 'hasChosenBorrowers',
  STATE_DATA = 'state_data'
}
