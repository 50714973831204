import { SignatureRequestModeEnum } from '@/api/contract/contract.types';
/* eslint-disable @typescript-eslint/no-explicit-any */
import PartnerAPI from '@/api/PartnerAPI';
import { i18n } from '@/i18n';

import {
  ContractCreatedResponse,
  ContractResponse,
  MandateInterface,
  SignatureRequest,
  SignContractRequest,
  TerminationInfoResponse
} from './contract.types';

export default {
  async createContract({
    prospectKey,
    intentId,
    mandate,
    existing_mandate_payment_method_id,
    signatureMode
  }: {
    prospectKey: string;
    intentId?: string;
    mandate?: MandateInterface;
    existing_mandate_payment_method_id?: string | null;
    signatureMode: SignatureRequestModeEnum;
  }): Promise<ContractCreatedResponse> {
    try {
      const { data } = await PartnerAPI.post<ContractCreatedResponse>(
        `/prospect/${prospectKey}/contract?lang=${i18n.global.locale}`,
        {
          signature_mode: signatureMode,
          intent_id: intentId,
          mandate,
          existing_mandate_payment_method_id
        }
      );

      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getContract(contractId: number): Promise<ContractResponse> {
    try {
      const { data } = await PartnerAPI.get<ContractResponse>(`/contracts/${contractId}`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async getSignatureRequest(contractId: number): Promise<SignatureRequest> {
    try {
      const { data } = await PartnerAPI.get<SignatureRequest>(
        `/contracts/${contractId}/document_signature_request`
      );

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async getContractTerminationInfo(
    contract_uuid: string,
    consumer_uuid: string
  ): Promise<TerminationInfoResponse> {
    try {
      const { data } = await PartnerAPI.get<any>(
        `/partners/contracts/${contract_uuid}/consumers/${consumer_uuid}/terminations`
      );
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async terminatePreviousContract(
    contract_uuid: string,
    consumer_uuid: string,
    payload: any
  ): Promise<any> {
    try {
      const { data } = await PartnerAPI.patch<any>(
        `/partners/contracts/${contract_uuid}/consumers/${consumer_uuid}/terminations`,
        payload
      );
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async signLukoSignContract(payload: SignContractRequest): Promise<any> {
    try {
      const { data } = await PartnerAPI.post<any>('/contracts/signature/lukosign', payload);
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getContractInsuranceCertificate(contractId: number): Promise<any> {
    try {
      const { data } = await PartnerAPI.get<any>(
        `/contract/${contractId}/documents/insurance_certificate`,
        { responseType: 'blob' }
      );

      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  }
};
