import { useState } from '@/composables/states/useState';

const { stateData } = useState();

export const intercomGuard = async (to, _, next) => {
  if (to.query.show_intercom) {
    stateData.value.shouldShowIntercom = true;
  }

  next();
};
