import get from 'lodash-es/get';
import set from 'lodash-es/set';
import { reactive } from 'vue';

import { StepsPayload } from '@/composables/app/useSteps/useSteps.types';
import checkInfoComponentOrder from '@/modules/checkInfo/order.json';
import paymentCheckoutComponentOrder from '@/modules/payment/components/payment/order.json';
import paymentSummaryComponentOrder from '@/modules/payment/components/summary/order.json';
import pricingComponentOrder from '@/modules/pricing/order.json';
import recapComponentOrder from '@/modules/recap/order.json';
import signatureComponentOrder from '@/modules/signature/order.json';
import successComponentOrder from '@/modules/success/order.json';

const defaultComponentOrder = {
  Pricing: pricingComponentOrder,
  Recap: recapComponentOrder,
  PaymentSummary: paymentSummaryComponentOrder,
  PaymentCheckout: paymentCheckoutComponentOrder,
  Signature: signatureComponentOrder,
  Success: successComponentOrder,
  CheckInfo: checkInfoComponentOrder
};

const componentsOrder = reactive(defaultComponentOrder);

const setComponentsOrder = (path, data) => {
  set(componentsOrder, path, data);
};
const getComponentsOrder = (path): string[] => get(componentsOrder, path) || [];

const overwriteComponentsOrder = (payload: StepsPayload) => {
  Object.keys(payload).forEach((key) => {
    setComponentsOrder(key, payload[key]);
  });
};

const replaceComponent = (payload) => {
  Object.keys(payload).forEach((key) => {
    if (key?.includes('.')) {
      const newValue = getComponentsOrder(key)?.map((step) =>
        payload[key][0] === step ? payload[key][1] : step
      );
      setComponentsOrder(key, newValue);
    } else {
      Object.keys(getComponentsOrder(key)).forEach((product) => {
        const newValue = getComponentsOrder(`${key}.${product}`)?.map((step) =>
          payload[key][0] === step ? payload[key][1] : step
        );
        setComponentsOrder(`${key}.${product}`, newValue);
      });
    }
  });
};

export const useComponentManager = () => ({
  componentsOrder,
  overwriteComponentsOrder,
  replaceComponent,
  getComponentsOrder,
  setComponentsOrder
});
