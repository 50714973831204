export enum PaymentServiceProvider {
  GOCARDLESS = 'gocardless',
  STRIPE = 'stripe'
}

export enum PaymentTypeEnum {
  CARD = 'card',
  DEBIT = 'sepa_debit'
}

export enum PaymentFrequencyEnum {
  YEARLY = 'YEARLY',
  MONTHLY = 'MONTHLY'
}
export interface PaymentSource {
  bank_name: string;
  card_brand: string;
  id: number;
  is_default: boolean;
  last4: string;
  masked_iban: string;
  type: PaymentTypeEnum;
}
export interface PaymentMethodInterface {
  sources: PaymentSource[];
}
