import { IRecaptchaData } from '@/composables/app/useRecaptcha/useRecaptcha.types';

import PartnerAPI from '../PartnerAPI';

export default {
  async recaptcha(token: string, step_name?: string): Promise<IRecaptchaData> {
    const { data } = await PartnerAPI.post<IRecaptchaData>(`/recaptcha`, {
      token,
      step_name
    });
    return data;
  }
};
