export enum UserPathEnum {
  token = 'token',
  intercomToken = 'intercom_token',
  userId = 'userId',
  email = 'email',
  phone = 'phone',
  gender = 'gender',
  first_name = 'firstname',
  birth_date = 'birthdate',
  birth_country_code = 'birth_country_code',
  last_name = 'lastname',
  contractId = 'contractId',
  coupon = 'coupon',
  giveback = 'giveback',
  contracts = 'contracts',
  uuid = 'uuid'
}

export interface IUser {
  userId?: string;
  email?: string;
  phone?: string;
  firstname?: string;
  birthdate?: string;
  birth_country_code?: string;
  lastname?: string;
  contractId?: string;
  gender?: 'male' | 'female';
  uuid?: string;
}
