export enum BrazeEventsEnum {
  CONTACT_GIVEN = 'Contact Given',
  QUOTE_SEEN = 'Quote Seen',
  CONTRACT_PAID = 'Contract Paid',
  CONTRACT_SUBSCRIBED = 'Contract Subscribed',
  QUOTE_UPDATED = 'Quote Updated',
  DOCUMENTS_UPLOADED = 'Documents Uploaded',
  FUNNEL_EXCLUDED = 'Funnel Excluded',
  FUNNEL_EXCLUDED_CONTACT_GIVEN = 'Funnel Excluded Contact Given',
  CALLBACK_REQUESTED = 'Callback Requested',
  SERVER_ISSUE_CONTACT_GIVEN = 'Server Issue Contact Given',
  ACCOUNT_CREATED = 'Account Created',
  ACCOUNT_CREATION_EXTRA_INFO = 'Account Creation Extra Info',
  REACHED_ACTIVATION_MORTGAGE = 'Reached Activation Mortgage',
  REACHED_ACTIVATION_RENT = 'Reached Activation Rent'
}
