import { computed } from 'vue';

import { useProspect } from '@/composables/states/useProspect';
import { useEnv } from '@/composables/utils/useEnv';
import { i18n } from '@/i18n';

import { useProduct } from '../app/useProduct';
import { useMobileApp } from './useMobileApp';
import { useScreenSize } from './useScreenSize';

const { getProspectData, PROSPECT_DATA_PATH } = useProspect();
const { isHomeInsurance, isLandlord, isMortgage, isGli, isNvei } = useProduct();
const { isDev } = useEnv();
const { isSmallScreen } = useScreenSize();
const { isMobileApp } = useMobileApp();

const CMS_URLS = {
  fr: isDev ? 'https://fr.devnoscope.luko.eu' : 'https://fr.luko.eu',
  de: isDev ? 'https://de.devnoscope.luko.eu' : 'https://de.luko.eu'
};

const CMS_SUPPORT_LANG = {
  fr: ['fr', 'en'],
  de: ['de', 'en']
};

const cmsUrl = computed(() => {
  let countryCode = getProspectData<string>(PROSPECT_DATA_PATH.subscriber.country_code) || 'FR';
  countryCode = countryCode?.toLowerCase();

  let langLocal = i18n.global.locale;
  langLocal = CMS_SUPPORT_LANG[countryCode].includes(langLocal) ? langLocal : 'en';
  if (countryCode?.toLowerCase() === langLocal) {
    // ex) fr.luko.eu/fr => fr.luko.eu/
    langLocal = '';
  }

  return [CMS_URLS[countryCode], langLocal].join('/');
});

const myLukoUrl = computed(() => {
  return `https://${location.host}/my-account?lang=${i18n.global.locale}`;
});

const myLukoContractUrl = (contractId, justCreated = false) => {
  const urlPath =
    isSmallScreen.value || isMobileApp.value
      ? `my-account/contracts/${contractId || ''}`
      : 'my-account';
  const url = new URL(`https://${location.host}/${urlPath}`);
  url.searchParams.append('lang', i18n.global.locale);
  if (justCreated) {
    url.searchParams.append('just-created', 'true');
  }

  return url.toString();
};

const forgotPasswordUrl = computed(() => {
  return `https://${location.host}/my-account/forgot-password?lang=${i18n.global.locale}`;
});

const testimonialUrl = computed(() => {
  return `${CMS_URLS[i18n.global.locale]}/testimonials/`;
});

const onboardUrl = computed(() => {
  return `https://${location.host}/${i18n.global.locale}/onboard`;
});

const faqUrl = computed(() => {
  if (isHomeInsurance.value || isLandlord.value) {
    if (i18n.global.locale === 'en')
      return 'https://faq.luko.eu/en/collections/1378822-luko-s-home-insurance';
    return 'https://faq.luko.eu/fr/collections/1378822-l-assurance-habitation-luko';
  }
  if (isMortgage.value)
    return 'https://faq.luko.eu/fr/collections/2855968-l-assurance-pret-immobilier-luko';
  if (isGli.value) {
    if (i18n.global.locale === 'en')
      return 'https://faq.luko.eu/en/collections/3840135-unpaid-rent-insurance';
    return 'https://faq.luko.eu/fr/collections/3840135-la-garantie-loyer-impaye';
  }
  if (isNvei.value)
    return 'https://faq.luko.eu/fr/collections/1401741-l-assurance-trottinette-electrique-et-nvei';
  return `https://faq.luko.eu/${i18n.global.locale}/`;
});

const getCountryCodeFromUrl = (url: string): string | string[] => {
  const validCountryCodeRegex = new RegExp('\\b(de|fr){1,2}\\b', 'g');

  return validCountryCodeRegex.exec(url)?.[0] || ['fr', 'de'];
};

const parseQuery = (queryString) => {
  const query = {};
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
};

const removeParameterFromUrl = (url, parameter) =>
  url
    .replace(new RegExp('[?&]' + parameter + '=[^&#]*(#.*)?$'), '$1')
    .replace(new RegExp('([?&])' + parameter + '=[^&]*&'), '$1');

const calendlyUrl = 'https://calendly.com/assurance-emprunteur-luko/luko-assurance-emprunteur';

const TUIOUrl =
  'https://app.tuio.com/chat/estimate/2?agencyId=vVLtSYYBtLKf-5v5Omhf&agentId=vlLtSYYBtLKf-5v5PGiN&utm_source=luko&utm_campaign=luko_onboarding ';

const MILAUrl = 'https://moncompte.service.mila.care/onboarding/gli/owner?dst=279065';

export const useUrls = () => ({
  parseQuery,
  removeParameterFromUrl,
  myLukoContractUrl,
  cmsUrl,
  myLukoUrl,
  forgotPasswordUrl,
  testimonialUrl,
  onboardUrl,
  CMS_URLS,
  faqUrl,
  TUIOUrl,
  MILAUrl,
  getCountryCodeFromUrl,
  calendlyUrl
});
