import { utmGuard } from './aboutUtm.guard';
import { bypassGuard } from './bypass.guard';
import { cleanGuard } from './clean.guard';
import { crossSellGuard } from './crossSell.guard';
import { intercomGuard } from './intercom.guard';
import { jwtGuard } from './jwt.guard';
import { loadLanguageGuard } from './loadLanguage.guard';
import { mobileGuard } from './mobile.guard';
import { movingQueryGuard } from './movingQuery.guard';
import { partnerQueryGuard } from './partnerQuery.guard';
import { pricingCallGuard } from './pricingCall.guard';
import { productBypassGuard } from './productBypass.guard';
import productQueryGuard from './productQuery.guard';
import { prospectQueryGuard } from './prospectQuery.guard';
import { publicKeyGuard } from './publicKey.guard';
import { purifyQueryGuard } from './purifyQuery.guard';
import { removePersistDataGuard } from './removePersistData.guard';
import { scrollToGuard } from './scrollTo.guard';
import { standaloneAccessGuard } from './standaloneAccess.guard';

export default [
  removePersistDataGuard,
  loadLanguageGuard,
  pricingCallGuard,
  cleanGuard,
  bypassGuard,
  publicKeyGuard,
  mobileGuard,
  jwtGuard,
  productBypassGuard,
  prospectQueryGuard,
  standaloneAccessGuard,
  utmGuard,
  movingQueryGuard,
  partnerQueryGuard,
  productQueryGuard,
  crossSellGuard,
  purifyQueryGuard,
  scrollToGuard,
  intercomGuard
];
