const modules = {
  // Prospect Steps
  Address: () => import('../../modules/prospect/steps/Address'),
  Appliances: () => import('../../modules/prospect/steps/Appliances'),
  BankName: () => import('../../modules/prospect/steps/BankName'),
  ClaimHistory: () => import('../../modules/prospect/steps/ClaimHistory'),
  ExistingContractAge: () => import('../../modules/prospect/steps/ExistingContractAge'),
  ExistingContractMonth: () => import('../../modules/prospect/steps/ExistingContractMonth'),
  Maintenance: () => import('../../modules/prospect/steps/Maintenance'),
  Outbuildings: () => import('../../modules/prospect/steps/Outbuildings'),
  EscooterInfo: () => import('../../modules/prospect/steps/EscooterInfo'),
  HomeType: () => import('../../modules/prospect/steps/HomeType'),
  HomeInfo: () => import('../../modules/prospect/steps/HomeInfo'),
  InsuranceType: () => import('../../modules/prospect/steps/InsuranceType'),
  IsAlreadyInsured: () => import('../../modules/prospect/steps/IsAlreadyInsured'),
  IsSmoker: () => import('../../modules/prospect/steps/IsSmoker'),
  IsThirdParty: () => import('../../modules/prospect/steps/IsThirdParty'),
  IsFurnished: () => import('../../modules/prospect/steps/IsFurnished'),
  MortgageBorrowerInfo: () => import('../../modules/prospect/steps/MortgageBorrowerInfo'),
  MortgageList: () => import('../../modules/prospect/steps/MortgageList'),
  MortgageOwnerType: () => import('../../modules/prospect/steps/MortgageOwnerType'),
  MortgagePersonalInfo: () => import('../../modules/prospect/steps/MortgagePersonalInfo'),
  MortgageShare: () => import('../../modules/prospect/steps/MortgageShare'),
  MovingOrSwitching: () => import('../../modules/prospect/steps/MovingOrSwitching'),
  MovingStartDate: () => import('../../modules/prospect/steps/MovingStartDate'),
  MovingTermination: () => import('../../modules/prospect/steps/MovingTermination'),
  NumberOfBorrowers: () => import('../../modules/prospect/steps/NumberOfBorrowers'),
  NveiAddress: () => import('../../modules/prospect/steps/NveiAddress'),
  NveiIsThirdParty: () => import('../../modules/prospect/steps/NveiIsThirdParty'),
  OwnerType: () => import('../../modules/prospect/steps/OwnerType'),
  PersonalInfo: () => import('../../modules/prospect/steps/PersonalInfo'),
  ProductType: () => import('../../modules/prospect/steps/ProductType'),
  ProjectType: () => import('../../modules/prospect/steps/ProjectType'),
  RenterOrOwner: () => import('../../modules/prospect/steps/RenterOrOwner'),
  RentPrice: () => import('../../modules/prospect/steps/RentPrice'),
  SpeedCheck: () => import('../../modules/prospect/steps/SpeedCheck'),
  SciTerminationInfo: () => import('../../modules/prospect/steps/SciTerminationInfo'),
  TerminationResult: () => import('../../modules/prospect/steps/TerminationResult'),
  TerminationResultSci: () => import('../../modules/prospect/steps/TerminationResultSci'),
  Unavailable: () => import('../../modules/prospect/steps/Unavailable'),
  UnavailableSuccess: () => import('../../modules/prospect/steps/UnavailableSuccess'),

  // Aggregator one pager step
  CheckInfo: () => import('../../modules/checkInfo'),

  // Pricing Step
  Pricing: () => import('../../modules/pricing'),

  // Recap Step
  Recap: () => import('../../modules/recap'),

  // Payment Step
  Payment: () => import('../../modules/payment'),

  // Signature Step
  Signature: () => import('../../modules/signature'),

  // Account Creation Step
  AccountCreation: () => import('../../modules/accountCreation'),
  AdditionalInfos: () => import('../../modules/additionalInfos'),

  // Termination Step
  Termination: () => import('../../modules/termination'),

  // Success Step
  Success: () => import('../../modules/success'),
  Offer: () => import('../../modules/offer')
};

const lazyLoadStep = (stepName: string) => {
  return modules[stepName]?.();
};

const getStepLogic = async (stepName: string) => {
  const module = await lazyLoadStep(stepName);
  return module?.default?.stepLogic;
};

export const useStepLogic = () => ({
  getStepLogic,
  lazyLoadStep
});
