export default {
  fr: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    simpleCurrency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    },
    price: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    percent: {
      style: 'percent',
      useGrouping: false
    }
  },
  en: {
    price: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    simpleCurrency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    },
    percent: {
      style: 'percent',
      useGrouping: false
    }
  },
  de: {
    price: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    simpleCurrency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    },
    percent: {
      style: 'percent',
      useGrouping: false
    }
  }
};
