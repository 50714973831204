<script lang="ts" setup>
import { onMounted, ref } from 'vue';

import { useState } from '@/composables/states/useState';

const emit = defineEmits(['submit']);
const { stateData } = useState();
const checkbox = ref<HTMLElement>('');
const onSubmit = async () => {
  stateData.value.checkHumanConfirmed = true;

  emit('submit');
};
onMounted(() => {
  window.grecaptcha?.render(checkbox.value, {
    sitekey: import.meta.env.VITE_RECAPTCHA_V2_SITE_KEY,
    callback: onSubmit
  });
});
</script>

<template>
  <div :class="$style.overlay">
    <div :class="$style.content">
      <h2 :class="$style.title">{{ $t('Recaptcha.title') }}</h2>
      <p :class="$style.description">{{ $t('Recaptcha.description') }}</p>
    </div>
    <div ref="checkbox" :class="$style.checkbox" />
  </div>
</template>

<style module lang="scss">
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 24px;

  background: $white;
}
.content {
  display: flex;
  flex-direction: column;
}
.title {
  @include typo-title;
  text-align: center;
}
.description {
  color: $gray-600;
  text-align: center;
}
.checkbox {
  margin-top: 24px;
}
</style>
