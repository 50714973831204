<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useProduct } from '@/composables/app/useProduct';
import { useMobileApp } from '@/composables/utils/useMobileApp';

const { isMobileApp } = useMobileApp();
const { isGli } = useProduct();
const { t } = useI18n();

const props = defineProps({
  message: {
    type: String,
    default: ''
  }
});

const messageComputed = computed(() => {
  if (props.message) return props.message;
  if (isGli.value) return t('Onboarding.LegalNoticeGli');
  return t('Onboarding.LegalNotice');
});

const hasIntercom = !isMobileApp.value;
</script>

<template>
  <div :class="[$style.legalNotice, hasIntercom && $style.hasIntercom]">
    <p v-sanitize="messageComputed" />
  </div>
</template>

<style lang="scss" module>
.legalNotice {
  p,
  a {
    color: $gray-400;
    font-size: 14px;
    line-height: 18px;
  }

  @include below('small') {
    position: static;
    top: 100%;
    p,
    a {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
</style>
