import { get, set } from 'lodash-es';
import { computed, reactive, ref } from 'vue';

import ContractService from '@/api/contract';
import { ContractCreatedResponse, ContractResponse } from '@/api/contract/contract.types';

import { IContractData } from './useContract.types';

const contractData = reactive({} as IContractData);

const contractCreated = ref(false);
const contractFetched = ref(false);

const contractId = computed(() => contractData.id);

const fetchContract = async (forcedContractId?: number) => {
  const contract = await ContractService.getContract(forcedContractId || contractId.value);
  saveContractFetched(contract);
};

const saveContractCreation = (data: ContractCreatedResponse) => {
  contractCreated.value = true;
  Object.assign(contractData, { ...contractData, id: data.contract.id });
};

const saveContractFetched = (data: ContractResponse) => {
  contractFetched.value = true;
  Object.assign(contractData, { ...contractData, ...data });
};

const setContractData = <T>(path: string, data: T) => {
  return set(contractData, path, data);
};

const getContractData = <T>(path: string): T => {
  return get(contractData, path);
};

const resetContractData = () => {
  Object.assign(contractData, {});
};

export const useContract = () => {
  return {
    saveContractCreation,
    saveContractFetched,
    setContractData,
    getContractData,
    resetContractData,
    fetchContract,
    contractData,
    contractCreated,
    contractFetched,
    contractId
  };
};
