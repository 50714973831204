import axios, { AxiosInstance } from 'axios';

import { HttpVerbsEnum } from '@/api/types';

const { create } = axios;
const instances: AxiosInstance[] = [];
// new instances "inherit" existing interceptors

axios.create = function (...args) {
  const instance = create.apply(this, args);
  instances.push(instance);

  for (const type in axios.interceptors) {
    axios.interceptors[type].handlers.forEach(({ fulfilled, rejected }) =>
      instance.interceptors[type].use(fulfilled, rejected)
    );
  }

  return instance;
};

// new interceptors are being "inherited" to existing instances
for (const type in axios.interceptors) {
  ['eject', 'use'].forEach((method) => {
    const original = axios.interceptors[type][method];

    axios.interceptors[type][method] = function (...args) {
      const result = original.apply(axios.interceptors[type], args);
      instances.forEach((instance) => instance.interceptors[type][method](...args));
      return result;
    };
  });
}

const isPollingRequest = (url: string, method: string | undefined) => {
  return !!url.match(/\/contracts\/.+$/) && method === HttpVerbsEnum.GET;
};

let pollingStartTime = 0;

// Global Request Interceptor
axios.interceptors.request.use(
  function (config) {
    config.headers = config.headers || {};
    config.headers['request-start'] = window.performance.now();
    pollingStartTime = window.performance.now();
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Global Response Interceptor
axios.interceptors.response.use(
  function (response) {
    const { status, config, data } = response;
    const requestUrl = `${config.baseURL}${config.url}`;
    const isPolling = isPollingRequest(requestUrl, config.method);
    config.headers = config.headers || {};

    // Don't send "API Call" tracking if polling request not finished
    if (isPolling && data.is_signed !== true) return response;

    const requestStart: number = isPolling
      ? pollingStartTime
      : new Number(config.headers['request-start']).valueOf();

    window.LukoTracking.trackEvent({
      id: 'API Call',
      properties: {
        api: {
          response_time: window.performance.now() - requestStart,
          status_code: status,
          request_url: requestUrl,
          polling: isPolling
        }
      }
    });
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axios;
