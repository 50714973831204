import { useHead } from '@vueuse/head';
import { computed, ref } from 'vue';

import { useEnv } from '@/composables/utils/useEnv';
import { useIntercom } from '@/composables/utils/useIntercom';
import { useStorage } from '@/composables/utils/useStorage';
import { TokenType } from '@/types/Token';

const { getFromSessionStorage, setInSessionStorage } = useStorage();
const { isE2E } = useEnv();

const mobileAppRef = ref(getFromSessionStorage('is_mobile_app') || false);

const isMobileApp = computed<boolean>({
  get: () => !!mobileAppRef.value,
  set: (value) => {
    mobileAppRef.value = value;
    setInSessionStorage('is_mobile_app', value);
  }
});

const sendEvent = (
  eventName: string,
  params?: { token?: TokenType; intercomInitialMessage?: string; product?: string }
) => {
  const mobileAppInterface = window.ReactNativeWebView;
  window.Logger.$log('Mobile Event', eventName, params || '');
  if (mobileAppInterface) {
    if (!params) {
      mobileAppInterface.postMessage(eventName);
    } else {
      mobileAppInterface.postMessage(
        JSON.stringify({
          event: eventName,
          params
        })
      );
    }
  } else {
    if (isE2E.value) {
      if (!window.mobileAppEvents) {
        window.mobileAppEvents = [];
      }
      return window.mobileAppEvents.push(eventName);
    }
    if (eventName === 'intercom') {
      const { showIntercom } = useIntercom();
      showIntercom(params?.intercomInitialMessage);
    }
  }
};

// Open Intercom in the app
const openIntercom = (params) => sendEvent('intercom', params);

// Open the share modal on the app
const openDoc = (params) => sendEvent('open_doc', params);

// Close the webview with success feedback
const closeWebview = () => sendEvent('close_succeed');

// Close the webview with failed feedback
const failedWebview = () => sendEvent('close_failed');

const cancelWebview = () => sendEvent('close_cancelled');

// Indicate to the app that the page is fully loaded
const pageLoaded = () => {
  if (!isMobileApp.value) {
    return;
  }
  useHead({
    meta: [
      {
        name: `viewport`,
        content: 'width=device-width, initial-scale=1,  maximum-scale=1'
      }
    ]
  });
  sendEvent('page_loaded');
};

// When a contract subscription succeed we send the token
const contractSubscriptionSuccess = (token: TokenType | undefined, product: string | undefined) =>
  sendEvent('contract_subscription_success', { token, product });

export const useMobileApp = () => {
  return {
    isMobileApp,
    openIntercom,
    failedWebview,
    closeWebview,
    pageLoaded,
    openDoc,
    contractSubscriptionSuccess,
    cancelWebview
  };
};
