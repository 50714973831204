import { delay } from 'lodash-es';
import { computed, ref, watch } from 'vue';

import { useFlows } from '@/composables/app/useFlows';
import { useProduct } from '@/composables/app/useProduct';
import { useStepLogic } from '@/composables/app/useStepLogic';
import { useSteps } from '@/composables/app/useSteps';
import { useProspect } from '@/composables/states/useProspect';
import { useState } from '@/composables/states/useState';
import { useLoader } from '@/composables/utils/useLoader';
import { useProspectClean } from '@/composables/utils/useProspectClean';
import { useUrls } from '@/composables/utils/useUrls';
import { i18n } from '@/i18n';
import { router } from '@/router/router';

import { StepUpdaterActionEnum } from './../useSteps/useSteps.types';
import { RestartReason } from './useNavigation.type';

const { getProspectData, resetProspect, PROSPECT_DATA_PATH } = useProspect();
const { stateData, resetState, removePersistState, resetStepState } = useState();
const { cmsUrl, TUIOUrl, MILAUrl } = useUrls();
const { guessProduct } = useFlows();
const { cleanProspect } = useProspectClean();
const { getStepLogic } = useStepLogic();
const { isEsProduct, isGli, isDeProduct } = useProduct();
const { launchLoader } = useLoader();

const {
  stepsList,
  isPostProspectStep,
  currentStep,
  currentStepIndex,
  checkIsPricingStep,
  updateSteps
} = useSteps();

const isFirstLanded = ref<boolean>(true);
const isGoingBack = ref<boolean>(false);
const isGoingFast = ref<boolean>(false);

const stepBeforeUnavailable = ref<string>('');

const isBypass = computed({
  get: () => !!stateData.value.bypass,
  set: (bool: boolean) => (stateData.value.bypass = bool)
});

const isOneTimeBypass = computed({
  get: () => !!stateData.value.oneTimeBypass,
  set: (bool: boolean) => (stateData.value.oneTimeBypass = bool)
});

const goNext = async () => {
  stateData.value.slowConnectionLoader = true;
  if (handleStandaloneAccess()) {
    return;
  }
  isGoingBack.value = false;
  let nextStep: null | string = null;
  const currentStepName = currentStep.value;

  if (!isPostProspectStep.value) {
    guessProduct();
  }
  if (currentStepName && stepsList.value.includes(currentStepName)) {
    const stepLogic = await getStepLogic(currentStepName);
    if (stepLogic) {
      if (stepLogic.shouldDeadEnd?.()) {
        stepBeforeUnavailable.value = currentStep.value;
        nextStep = 'Unavailable';
      } else if (!stepLogic.isDataValid.value) {
        goTo(currentStepName, true);
        window.Logger.$logError(
          `We are stuck at ${currentStepName}. isDataValid.value: ${stepLogic.isDataValid.value}`
        );
        return 'stuck';
      }
    }
  }

  if (isFirstLanded.value && !isPostProspectStep.value) {
    resetStepState();
    if (getProspectData(PROSPECT_DATA_PATH.product) !== 'DEFAULT') {
      isOneTimeBypass.value = true;
    }
  }

  if (nextStep !== 'Unavailable') {
    for (const [stepIndex, step] of Object.entries(stepsList.value)) {
      const stepLogic = await getStepLogic(step);

      if (stepLogic.shouldBeSkipped?.()) continue;

      stepLogic?.prepareProspectData?.();
      if (isBypass.value && stepLogic.isDataValid.value && !stepLogic.forbidBypass?.()) {
        continue;
      }

      if (!stepLogic.isDataValid.value) {
        isFirstLanded.value = false;
        nextStep = step;
        window.Logger.$logError(
          `We are stuck at ${currentStepName}. isDataValid.value: ${stepLogic.isDataValid.value}`
        );
        break;
      }

      if (
        !isGoingFast.value &&
        (isFirstLanded.value || parseInt(stepIndex) > currentStepIndex.value) &&
        (!isPostProspectStep.value || parseInt(stepIndex) > currentStepIndex.value)
      ) {
        isFirstLanded.value = false;
        nextStep = step;
        break;
      }
    }
  }
  if (checkIsPricingStep(nextStep)) cleanProspect();

  if (currentStepName) window.LukoTracking.trackEvent({ id: 'Step Submitted' }); // prevents to fire tracking when the first step is loaded

  goTo(nextStep, true);
  return;
};

// Handle specific standalone access
const handleStandaloneAccess = () => {
  const { currentRoute } = router;
  const hash = currentRoute.value.hash?.replace('#', '');

  // Signature Url
  if (stateData.value.hasSignatureUrl) {
    stateData.value.hasSignatureUrl = undefined;
    goTo('Signature');
    return true;
  }
  // Termination Url
  if (hash === 'Termination' && isFirstLanded.value) {
    goTo('Termination');
    return true;
  }

  if (hash === 'PricingB' && !stepsList.value.includes('PricingB')) {
    const key = getProspectData(PROSPECT_DATA_PATH.product);
    updateSteps([
      {
        action: StepUpdaterActionEnum.REPLACE,
        stepName: 'Pricing',
        with: 'PricingB',
        product: key
      }
    ]);
  }

  return false;
};

// Go To a specific step (forward)
const goTo = (stepName, toPush = false) => {
  const { currentRoute } = router;

  if (toPush && stepName !== currentStep.value) {
    router.push({ hash: `#${stepName}`, query: currentRoute.value.query });
  } else {
    router.replace({ hash: `#${stepName}`, query: currentRoute.value.query });
  }
  isFirstLanded.value = false;
};

const goToFirst = (toPush = false) => {
  goTo(stepsList.value[0], toPush);
};

// Used to navigate back in the app
const goBack = async () => {
  const currentStepIndex = stepsList.value.indexOf(currentStep.value);
  if (currentStepIndex === 0) {
    return (window.location.href = cmsUrl.value);
  }
  if (currentStep.value.includes('Unavailable')) {
    return goBackTo(stepBeforeUnavailable.value);
  }
  isOneTimeBypass.value = false;
  await goBackTo(false);
};

// Used to navigate to a specific step
const goBackTo = async (step?) => {
  isGoingBack.value = true;
  let stepToGo = step;
  if (!stepToGo) {
    const currentStepIndex = stepsList.value.indexOf(currentStep.value);
    const stepsReversed = [...stepsList.value.slice(0, currentStepIndex)].reverse();
    for (const [_, step] of Object.entries(stepsReversed)) {
      const stepLogic = await getStepLogic(step);
      if (stepLogic.shouldBeSkipped?.(isGoingBack.value)) continue;
      stepToGo = step;
      break;
    }
  }
  router.replace({ hash: `#${stepToGo}` });
};

// Handle Forward and backward brower buttons
const hashChanged = async (step: string) => {
  const indexCurrentStep = stepsList.value.indexOf(currentStep.value);
  const indexNewStep = stepsList.value.indexOf(step);
  // Backward
  if (indexCurrentStep > indexNewStep && stepsList.value.indexOf(step) !== -1) {
    goBack();
  } else {
    await goNext();
  }
};

const goFast = async (stepName?: string) => {
  isGoingFast.value = true;

  if (!currentStep.value || currentStep.value !== stepName) {
    await goNext();
    isGoingFast.value = false;
    window.LukoTracking.trackEvent({
      id: 'Step Submitted',
      properties: {
        is_gofast: true
      }
    });
  }
};

const restartOnboard = (reason: RestartReason) => {
  window.LukoTracking.trackEvent({
    id: 'Restart Onboarding',
    properties: { prospectdata: { restart_reason: reason } }
  });
  resetProspect();
  resetState();
  window.location.replace(window.location.pathname + window.location.search);
  return;
};

// Temporary block subscription for spanish products
watch(isEsProduct, async (isSpanishProduct) => {
  if (isSpanishProduct) {
    launchLoader(false, {
      title: i18n.global.t('Loader.redirect.title', { partner: 'Tuio' }),
      description: i18n.global.t('Loader.redirect.description'),
      isForced: true
    });
    delay(function () {
      resetProspect();
      removePersistState();
      window.location.replace(TUIOUrl);
      resetState();
    }, 1500);
  }
});

// Temporary block subscription for FR_RENT products
watch([isGli], async ([isGLIProduct]) => {
  if (isGLIProduct) {
    launchLoader(false, {
      title: i18n.global.t('Loader.redirect.title', { partner: 'MILA' }),
      description: i18n.global.t('Loader.redirect.description'),
      isForced: true
    });
    delay(function () {
      removePersistState();
      window.location.replace(MILAUrl);
    }, 1500);
  }
});

// Temporary block subscription for DE products
watch([isDeProduct, currentStepIndex], async ([isDe, index]) => {
  if (isDe && (index > 0 || isFirstLanded.value)) {
    removePersistState();
    goTo('Maintenance');
  }
});

export const useNavigation = () => ({
  restartOnboard,
  goFast,
  goBackTo,
  goBack,
  handleStandaloneAccess,
  goNext,
  goToFirst,
  goTo,
  hashChanged,
  isGoingBack,
  isGoingFast,
  isFirstLanded,
  isBypass,
  isOneTimeBypass
});
