import { SignatureRequestModeEnum } from '@/api/contract/contract.types';
import { useState } from '@/composables/states/useState';
import { useSignature } from '@/modules/signature/composables/useSignature';

const { stateData } = useState();
const { cancelSignature, signatureData } = useSignature();

export const leaveSignatureGuard = async (to, from, next) => {
  if (
    from.hash === '#Signature' &&
    ['#Payment', '#Pricing'].includes(to.hash) &&
    stateData.value.paymentDone &&
    !stateData.value.signatureDone
  ) {
    window.LukoTracking.trackEvent({
      id: 'Click Leave Signature'
    });
    return signatureData.mode === SignatureRequestModeEnum.LUKOSIGN_EMBEDDED
      ? new Promise((resolve) => {
          signatureData.leaveSignaturePopupCallback = async (value) => {
            if (value) {
              await cancelSignature();
              resolve(next());
            } else {
              resolve(next(false));
            }
          };
        })
      : next();
  }
  return next();
};
