import { useUser } from '@/composables/app/useUser';

export const jwtGuard = async (to, from, next) => {
  const { token, t, ...rest } = to.query;
  const { jwtToken, fetchUserLoggedData, fillUserDataFromSession } = useUser();
  const catchedToken = token || t;

  fillUserDataFromSession();

  if (catchedToken) {
    jwtToken.value = catchedToken;
    fetchUserLoggedData(); // delete await
    return next(Object.assign({}, to, { query: rest }));
  } else if (jwtToken.value) {
    fetchUserLoggedData();
  }
  return next();
};
