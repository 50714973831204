import PartnerAPI from '@/api/PartnerAPI';

import { CouponResponse } from './coupon.types';

export default {
  async getCouponData(coupon_code: string): Promise<CouponResponse | null> {
    try {
      const { data } = await PartnerAPI.get<CouponResponse>(`/coupons/${coupon_code}/validation`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};
