import { App } from 'vue';

const allowedTags = /<(?!\/?(a|b|br|code|em|i|span|strike|strong|u|ul|ol|li)(\s|\/|>))/gi;
const allowedAttributes = /<(?=[^>]+\son[^>]*?>)/gi;

export const sanitizeHtml = (val: string) => {
  if (!val) {
    return '';
  }
  return val.replace(allowedTags, '&lt;').replace(allowedAttributes, '&lt;');
};

export const install = (app: App): void => {
  app.directive('sanitize', (el, binding) => {
    if (binding.value !== binding.oldValue) {
      el.innerHTML = sanitizeHtml(binding.value);
    }
  });
};
