import { captureException } from '@sentry/minimal';
import { computed, reactive, ref } from 'vue';

import ContractService from '@/api/contract';
import { ContractCreatedResponse, SignatureRequestModeEnum } from '@/api/contract/contract.types';
import { useNavigation } from '@/composables/app/useNavigation';
import { RestartReason } from '@/composables/app/useNavigation/useNavigation.type';
import { useSteps } from '@/composables/app/useSteps';
import { useUser } from '@/composables/app/useUser';
import { UserPathEnum } from '@/composables/app/useUser/useUser.types';
import { useContract } from '@/composables/states/useContract/useContract';
import { useProspect } from '@/composables/states/useProspect';
import { useQuote } from '@/composables/states/useQuote';
import { useState } from '@/composables/states/useState';
import { useCookies } from '@/composables/utils/useCookies';
import { useDuplicatedContractChecker } from '@/composables/utils/useDuplicatedContractChecker';
import { useError } from '@/composables/utils/useError';
import { ErrorPopupType } from '@/composables/utils/useError/useError.types';
import { useLoader } from '@/composables/utils/useLoader';
import { useStorage } from '@/composables/utils/useStorage';
import { ValidLocalStoragePaths } from '@/composables/utils/useStorage/useStorage.type';
import { i18n } from '@/i18n';

const { getProspectData, setProspectData, PROSPECT_DATA_PATH, prospectData, resetProspectKey } =
  useProspect();
const { stateData } = useState();
const { jwtToken, setUserData, getUserData } = useUser();
const { goNext, goBack, restartOnboard, goFast } = useNavigation();
const { stepsList } = useSteps();
const fetchedNewUrl = ref(false);
const { mergeCookie, CookieNamesEnum } = useCookies();
const { t } = i18n.global;
const { showErrorPopup } = useError();
const { getRules: getContractCheckerRules } = useDuplicatedContractChecker();
const { setInLocalStorage } = useStorage();
const { fetchContract, contractData } = useContract();
const { launchLoader, loaderLaunched } = useLoader();

const signatureData = reactive<{
  id: string | null | undefined;
  hashedContract: string | null | undefined;
  price: number | null | undefined;
  url: string | null | undefined;
  mode: SignatureRequestModeEnum | null;
  isLoading: boolean;
  leaveSignaturePopupCallback: null | ((unknown) => unknown);
}>({
  id: null,
  hashedContract: null,
  price: null,
  url: null,
  mode: null,
  leaveSignaturePopupCallback: null,
  isLoading: false
});

const initSignatureData = () => {
  Object.keys(signatureData).forEach((key) => {
    if (['leaveSignaturePopupCallback', 'isLoading'].includes(key)) return;
    signatureData[key] = null;
  });
};

const signatureMode = computed(() => {
  return stepsList.value?.includes('Signature')
    ? SignatureRequestModeEnum.LUKOSIGN_EMBEDDED
    : SignatureRequestModeEnum.SKIP;
});

const isLukoSign = computed(
  () => signatureMode.value === SignatureRequestModeEnum.LUKOSIGN_EMBEDDED
);

const dispatchContractData = (prospectContract: ContractCreatedResponse) => {
  const prospectSignUrl = `${window.location.origin}/onboard/sign?data=${btoa(
    JSON.stringify({
      id: prospectContract.contract.id,
      pk: getProspectData(PROSPECT_DATA_PATH.key),
      date: Date.now()
    })
  )}&token=${prospectContract.user.jwt_token}`;

  jwtToken.value = prospectContract.user.jwt_token;

  setProspectData(PROSPECT_DATA_PATH.sign_url, prospectSignUrl);
  setUserData(UserPathEnum.userId, prospectContract.user.id);
  setUserData(UserPathEnum.contractId, prospectContract.contract.id);
};

const beforeInitSignature = async () => {
  // user directly come from sign_url
  if (stateData.value.signatureUrlData) {
    const signUrlCreatedDate = stateData.value.signatureUrlData.date;
    if (Math.abs(Date.now() - signUrlCreatedDate) / 1000 / 3600 >= 48) {
      return showErrorPopup({
        type: ErrorPopupType._Old,
        text: i18n.global.t('Signature.error.tooLate'),
        cta: i18n.global.t('Error.ok'),
        onCta: () => restartOnboard(RestartReason.SIGNATURE_SESSION_EXPIRED)
      });
    }
  }

  if (!getUserData(UserPathEnum.contractId)) {
    stateData.value.paymentDone = false;
    stateData.value.pricingDone = false;
    if (getProspectData(PROSPECT_DATA_PATH.key)) {
      return goBack();
    } else {
      return restartOnboard(RestartReason.MISSING_PK_FOR_SIGNATURE);
    }
  }
};

const initSignature = async () => {
  if (!loaderLaunched.value) {
    launchLoader(undefined, { title: t('Pricing.waitContract') });
  }
  await fetchContract();

  setProspectData(PROSPECT_DATA_PATH.product, contractData.product_code);

  if (contractData.need_termination === true) {
    stateData.value.needsTermination = true;
  }

  if (contractData.is_signed) {
    return showErrorPopup({ type: ErrorPopupType.AlreadySigned });
  }
  signatureData.price = contractData.price;

  const signatureRequest = await ContractService.getSignatureRequest(
    getUserData(UserPathEnum.contractId)
  );
  signatureData.id = signatureRequest.id;
  signatureData.url = signatureRequest.data.sign_url || signatureRequest.data.pdf_url;
  signatureData.mode = signatureRequest.mode;

  fetchedNewUrl.value = true;
};

const initBackgroundSignature = async () => {
  const signatureRequest = await ContractService.getSignatureRequest(
    getUserData(UserPathEnum.contractId)
  );
  signatureData.id = signatureRequest.id;
  signatureData.url = signatureRequest.data.sign_url || signatureRequest.data.pdf_url;
  signatureData.mode = signatureRequest.mode;
};

const cancelSignature = async () => {
  const { doQuote } = useQuote();

  resetProspectKey();
  await doQuote();

  stateData.value.paymentDone = false;
  stateData.value.signatureDone = false;
  stateData.value.pricingDone = false;
  initSignatureData();
  if (stateData.value.needsAccountCreation) {
    //jwtToken.value = null;
  }
};

const successSignature = async () => {
  window.LukoTracking.trackEvent({ id: 'Contract Subscribed' });

  mergeCookie(CookieNamesEnum.LK_CMS, { pk: undefined, petName: undefined });
  stateData.value.signatureDone = true;
  initSignatureData();
};

const onSignContract = async () => {
  try {
    signatureData.isLoading = true;
    if (!stateData.value.loadingData) {
      launchLoader(true);
    }
    if (!signatureData.id) throw new Error('missing request_id');
    if (!signatureData.hashedContract) throw new Error('missing contract data');

    await ContractService.signLukoSignContract({
      request_id: signatureData.id,
      file_hash: signatureData.hashedContract || '',
      consent: true,
      tracking_info: {
        session_id: window.amplitude?.getInstance()?.getSessionId() || 'dev',
        device_id: window.amplitude?.getInstance()?.getDeviceId() || 'dev',
        user_agent: window.navigator?.userAgent,
        amplitude_id: window.amplitude?.getInstance()?.getSessionId() || 'dev',
        product: getProspectData(PROSPECT_DATA_PATH.product),
        user_id: getUserData(UserPathEnum.userId)
      }
    });
    await successSignature();
    const rule = getContractCheckerRules(prospectData);
    if (rule?.getHash) {
      setInLocalStorage(ValidLocalStoragePaths.SIGNED_CONTRACT, await rule.getHash());
    }
    await goNext();
  } catch (error) {
    captureException(error);
  } finally {
    signatureData.isLoading = false;
  }
};

const ExistingContractPrice = computed(() => signatureData.price ?? null);

const getDocHash = async (arrayBuffer: ArrayBuffer) => {
  const buffer = await window.crypto.subtle.digest('SHA-256', arrayBuffer);
  return Array.prototype.map
    .call(new Uint8Array(buffer), (x) => ('00' + x.toString(16)).slice(-2))
    .join('');
};

const getDocName = (docType: string) => {
  return t(`Signature.Docs.${docType}`);
};

const passSignature = async () => {
  await fetchContract();
  stateData.value.needsAccountCreation = !contractData.users[0].account_created;
  stateData.value.needsTermination = contractData.need_termination;
  stateData.value.pricingDone = true;
  stateData.value.recapDone = true;
  stateData.value.paymentDone = true;
  stateData.value.signatureDone = true;
  goFast();
};

export const useSignature = () => ({
  beforeInitSignature,
  signatureMode,
  onSignContract,
  isLukoSign,
  dispatchContractData,
  initSignature,
  fetchedNewUrl,
  cancelSignature,
  successSignature,
  passSignature,
  signatureData,
  initSignatureData,
  ExistingContractPrice,
  getDocHash,
  getDocName,
  initBackgroundSignature
});
