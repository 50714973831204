import { ProductEnum } from '@/types/Product';

export type StepsPayload = {
  [key in keyof typeof ProductEnum]?: Array<string>;
};

export interface StepInterface {
  step: string;
  pill: PillEnum;
  group: PillGroupEnum;
  originalIndex: number;
}

export interface StepUpdaterInterface {
  stepName: string;
  action: StepUpdaterActionEnum;
  toReplace?: string;
  with?: string;
  after?: string;
  before?: string;
  product?: string;
  pill?: PillEnum;
  group?: PillGroupEnum;
}

export enum StepUpdaterActionEnum {
  ADD_AFTER = 'add_after',
  ADD_BEFORE = 'add_before',
  MOVE_AFTER = 'move_after',
  MOVE_BEFORE = 'move_before',
  DELETE = 'delete',
  REPLACE = 'replace',
  REPLACE_PILL = 'replace_pill'
}

export enum PillEnum {
  PROPERTY_INFO = 'propertyInfo',
  USER_INFO = 'userInfo',
  FORMER_CONTRACT = 'formerContract',
  PRICING = 'pricing',
  PAYMENT = 'payment',
  SIGNATURE = 'signature',
  ACCOUNT_CREATION = 'accountCreation',
  TERMINATION = 'termination',
  OFFER = 'offer',
  SUCCESS = 'success',
  SCOOTER_INFO = 'scooterInfo',
  PET_INFO = 'petInfo',
  LOAN_INFO = 'loanInfo',
  RECAP = 'recap',
  CONTRACT = 'contract'
}

export enum PillGroupEnum {
  PROSPECT = 'prospect',
  QUOTE = 'quote',
  POST = 'post'
}
