import { computed, ref } from 'vue';

import { useSteps } from '@/composables/app/useSteps';
import { useState } from '@/composables/states/useState';
import { i18n } from '@/i18n';

import { LoadingData } from './useLoader.types';

let intervalIds: NodeJS.Timer[] = [];
const checkProgressBars = ref(false);

export const useLoader = () => {
  const { stateData } = useState();
  const { checkIsPricingStep, checkIsSignatureStep, checkIsRecapStep, checkIsPaymentStep } =
    useSteps();
  const DELAY = 4000;

  const checkStepHasOwnRemoveLoader = (stepName) =>
    checkIsPricingStep(stepName) ||
    checkIsRecapStep(stepName) ||
    checkIsPaymentStep(stepName) ||
    checkIsSignatureStep(stepName);
  const { t } = i18n.global;
  const loaderInformation = computed(() => [
    {
      title: t('Loader.information.noConflict.title'),
      description: t('Loader.information.noConflict.description')
    },
    {
      title: t('Loader.information.transparency.title'),
      description: t('Loader.information.transparency.description')
    },
    {
      title: t('Loader.information.understandable.title'),
      description: t('Loader.information.understandable.description')
    },
    {
      title: t('Loader.information.customizable.title'),
      description: t('Loader.information.customizable.description')
    }
  ]);
  const previousState = computed(() => stateData.value.loadingData);

  const setLoadingData = (loadingData: LoadingData | null) => {
    stateData.value.loadingData = loadingData;
  };

  const setRandomLoaderInformation = () => {
    let randomInformation: LoadingData;
    do {
      randomInformation =
        loaderInformation.value[(loaderInformation.value.length * Math.random()) | 0];
    } while (
      previousState.value &&
      previousState.value.title === randomInformation.title &&
      previousState.value.description === randomInformation.description
    );
    setLoadingData({ ...randomInformation, isRandom: true });
  };

  const launchLoader = (isRandom = false, loadingData?: LoadingData) => {
    if (isRandom) {
      setRandomLoaderInformation();
      intervalIds.push(setInterval(setRandomLoaderInformation, DELAY));
    } else if (loadingData) {
      setLoadingData(loadingData);
    }
  };

  const removeLoader = async () => {
    if (stateData.value.loadingData?.isForced) return;

    if (stateData.value.loadingData?.progressBars?.length) {
      checkProgressBars.value = true;
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    setLoadingData(null);
    if (intervalIds.length) {
      intervalIds.forEach((intervalId) => clearInterval(intervalId));
    }
    intervalIds = [];
    checkProgressBars.value = false;
  };

  const loaderLaunched = computed(() => !!stateData.value.loadingData);

  return {
    checkStepHasOwnRemoveLoader,
    launchLoader,
    removeLoader,
    loaderLaunched,
    checkProgressBars
  };
};
