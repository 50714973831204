// This composables isn't used in the project

import { computed } from 'vue';

import { useProduct } from '@/composables/app/useProduct';
import { useProspect } from '@/composables/states/useProspect';
import { HomeOwnerTypeEnum } from '@/types/Home';
import { ProductEnum, ProductToParamEnum } from '@/types/Product';
import { TerminationReasonEnum } from '@/types/Termination';

const { getProspectData, setProspectData, PROSPECT_DATA_PATH } = useProspect();
const { isDeProduct, isFrProduct, productLocal } = useProduct();

const hasHisOwnFlow = (product) => ProductToParamEnum[product];

const isMovingWithLuko = computed(
  () =>
    getProspectData(PROSPECT_DATA_PATH.termination_reason) ===
    TerminationReasonEnum.MOVING_WITH_LUKO
);

const DE_product_flow = () => {
  if (!isDeProduct.value) setProspectData(PROSPECT_DATA_PATH.product, ProductEnum.DE_HOME_CONTENT);
};

const FR_product_flow = () => {
  let product = getProspectData(PROSPECT_DATA_PATH.product) || 'DEFAULT';

  if (!isFrProduct.value) {
    product = 'DEFAULT';
  }

  const ownerType = getProspectData(PROSPECT_DATA_PATH.owner_type);

  if (product === ProductEnum.FR_MORTGAGE) {
    product = ProductEnum.FR_MORTGAGE;
  } else if (ownerType === HomeOwnerTypeEnum.LANDLORD) {
    product = ProductEnum.FR_LANDLORD;
  } else if (product === ProductEnum.FR_RENT) {
    product = ProductEnum.FR_RENT;
  } else if (product !== 'DEFAULT') {
    product = ProductEnum.FR_HOME;
  }

  setProspectData(PROSPECT_DATA_PATH.product, product);
};

const guessProduct = () => {
  let countryCode = getProspectData(PROSPECT_DATA_PATH.subscriber.country_code);
  if (!countryCode && productLocal.value) {
    countryCode = productLocal.value;
  }
  if (!countryCode) {
    countryCode = 'FR';
  }

  const productFlows = {
    DE: DE_product_flow,
    FR: FR_product_flow
  };

  const product = getProspectData(PROSPECT_DATA_PATH.product);
  if (product && hasHisOwnFlow(product)) {
    return;
  }

  return productFlows[countryCode]();
};

export const useFlows = () => ({
  guessProduct,
  isMovingWithLuko
});
