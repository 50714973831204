import { useCrossSell } from '@/composables/app/useCrossSell';
import { useUser } from '@/composables/app/useUser';
import { useProspect } from '@/composables/states/useProspect';

export const crossSellGuard = async (to, _, next) => {
  const { query } = to;
  const { landedWithCrossSell } = useCrossSell();
  const { getUserData, isLoggedIn } = useUser();
  const { setProspectData, PROSPECT_DATA_PATH } = useProspect();

  if (query.cross_sell === 'true') {
    landedWithCrossSell.value = true;

    if (isLoggedIn.value) {
      setProspectData(PROSPECT_DATA_PATH.subscriber.birth_date, getUserData('birthdate'));
      setProspectData(PROSPECT_DATA_PATH.subscriber.email, getUserData('email'));
      setProspectData(PROSPECT_DATA_PATH.subscriber.first_name, getUserData('firstname'));
      setProspectData(PROSPECT_DATA_PATH.subscriber.last_name, getUserData('lastname'));
      setProspectData(PROSPECT_DATA_PATH.subscriber.phone, getUserData('phone'));
    }
  }

  next();
};
