import { useUser } from '@/composables/app/useUser';
import { useStorage } from '@/composables/utils/useStorage';
import BaseAPI from '@/lib/BaseAPI';

const { getFromSessionStorage } = useStorage();

const PartnerAPI = BaseAPI.create({
  baseURL: import.meta.env.VITE_API_PARTNER_URL,
  withCredentials: true
});

const getInitHeader = () => {
  const { jwtToken } = useUser();
  const key = getFromSessionStorage('public_key') ?? import.meta.env.VITE_API_PARTNER_KEY;
  const headers = {
    Authorization: `Bearer ${key}`,
    'Content-Type': 'application/json'
  };
  if (jwtToken.value) {
    headers['Luko-User-JWT'] = jwtToken.value;
  }
  return headers;
};

// Request Interceptor
PartnerAPI.interceptors.request.use(function (config) {
  config.headers = { ...getInitHeader(), ...config.headers };

  return config;
});

// Response Interceptor
PartnerAPI.interceptors.response.use(function (response) {
  return response;
});

export default PartnerAPI;
