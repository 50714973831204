import { merge } from 'lodash-es';
import mitt from 'mitt';

import { useComponentManager } from '@/composables/app/useComponentManager';
import { useCrossSell } from '@/composables/app/useCrossSell';
import { useNavigation } from '@/composables/app/useNavigation';
import { useSteps } from '@/composables/app/useSteps';
import { StepsPayload, StepUpdaterInterface } from '@/composables/app/useSteps/useSteps.types';
import { useUser } from '@/composables/app/useUser';
import { useProspect } from '@/composables/states/useProspect';
import { useUnleash } from '@/composables/states/useUnleash';
import { UnleashFlagKeys } from '@/composables/states/useUnleash/useUnleash.types';
import { useErrorTrigger } from '@/composables/utils/useErrorTrigger';
import { useMobileApp } from '@/composables/utils/useMobileApp';

const { on, emit } = mitt();

const { prospectData, setProspectData } = useProspect();
const { userData } = useUser();
const { updateSteps } = useSteps();

const { overwriteComponentsOrder, replaceComponent } = useComponentManager();

const { isMobileApp } = useMobileApp();

const { forceFlag, forceVariant } = useUnleash();
const { replaceTemplateId } = useCrossSell();
const { goFast } = useNavigation();
const { triggerError } = useErrorTrigger();
// See window events doc
// https://www.notion.so/luko/Window-events-9bd4580aa2b94529abe2053fc1bf372a

window.events = {
  $on: (...args) => on(...args),
  $emit: (...args) => emit(...args)
};

// Steps
window.events.$on('updateSteps', (payload) => {
  window.Logger.$log('Steps overwritten: ', payload);
  updateSteps(payload as StepUpdaterInterface[]);
});

// Components
window.events.$on('setComponentsOrder', (payload) => {
  window.Logger.$log('Components order overwritten: ', payload);
  overwriteComponentsOrder(payload as StepsPayload);
});

window.events.$on('replaceComponent', (payload) => {
  window.Logger.$log('Component replaced: ', payload);
  replaceComponent(payload);
});

// MobileApp
window.events.$on('setMobileApp', (value) => {
  window.Logger.$log('Setting mobileApp: ', value);
  isMobileApp.value = value as boolean;
});

//Feature Flags
window.events.$on('enableFeature', (value) => {
  window.Logger.$log('Feature Enabled: ', value);
  forceFlag(value as UnleashFlagKeys, true);
});

window.events.$on('disableFeature', (value) => {
  window.Logger.$log('Feature Disabled: ', value);
  forceFlag(value as UnleashFlagKeys, false);
});

//Cross-Sell
window.events.$on('replaceCrossSell', (value) => {
  const crossSellPayload = value as string[];
  if (crossSellPayload.length === 2) {
    window.Logger.$log('Cross-sell replaced: ', crossSellPayload);
    replaceTemplateId(crossSellPayload[0], crossSellPayload[1]);
  }
});

window.events.$on('injectUserData', (value) => {
  merge(userData, value);
});

window.events.$on('triggerError', (type) => {
  triggerError(type as string);
});

window.events.$on('forceVariant', (payload: { id: UnleashFlagKeys; variation: string }) => {
  if (payload?.id && payload?.variation) {
    forceVariant(payload.id, payload.variation);
    window.Logger.$log('Variant forced: ', payload.id, payload.variation);
  } else {
    window.Logger.$log('Missing variant key or value ');
  }
});

// Prospect Data
window.events.$on('setProspectData', (payload: { path: string; value: unknown }) => {
  if (!payload?.path && !payload?.value) return;
  window.Logger.$log('Prospect data updated: ', payload);
  setProspectData(payload?.path, payload?.value);
});

window.events.$on('injectProspect', (value) => {
  merge(prospectData, value);
  goFast();
});
