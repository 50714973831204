import { useNavigation } from '@/composables/app/useNavigation';

export const bypassGuard = async (to, _, next) => {
  const { bypass } = to.query;

  if (bypass) {
    const { isBypass } = useNavigation();
    isBypass.value = true;
  }

  next();
};
