<script lang="ts" setup>
import { computed } from 'vue';

import LegalNotice from '@/components/LegalNotice/LegalNotice.vue';
import { useCoupon } from '@/composables/app/useCoupon';
import { useFlows } from '@/composables/app/useFlows';
import { useNavigation } from '@/composables/app/useNavigation';
import { useSteps } from '@/composables/app/useSteps';

const { currentStep, currentStepIndex, isPricingStep } = useSteps();
const { isMovingWithLuko } = useFlows();
const { couponShouldBeDisplayed } = useCoupon();
const { isOneTimeBypass } = useNavigation();

defineProps({
  hasLegalNotice: {
    type: Boolean,
    default: true
  }
});

const showLegalNotice = computed(() =>
  [
    [
      !['Unavailable', 'UnavailableSuccess'].includes(currentStep.value),
      currentStepIndex.value === 0,
      !isPricingStep.value
    ],
    [currentStepIndex.value === 1, isOneTimeBypass.value, !isMovingWithLuko.value]
  ].some((e) => e.every(Boolean))
);
</script>

<template>
  <section :class="[$style.root, { [$style.rootWithCoupon]: couponShouldBeDisplayed }]">
    <div :class="$style.content">
      <slot></slot>
    </div>

    <div>
      <slot name="controls"></slot>
      <transition name="fade" mode="out-in">
        <LegalNotice
          v-if="showLegalNotice && hasLegalNotice"
          :class="$style.legalNotice"
          :is-static="true"
        />
        <div v-else></div>
        <!-- ⬆ Empty element on purpose -->
      </transition>
    </div>
  </section>
</template>

<style lang="scss" module>
.root {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  width: 100%;
  max-width: calc(720px + 24px + 24px);
  min-height: calc(100vh - 64px);
  margin: 0 auto;
  padding: 24px;

  color: black;

  @include below(small) {
    gap: 32px;
    justify-content: space-between;
    padding: 16px;
  }
  @supports (-webkit-touch-callout: none) {
    /* The hack for Safari */
    min-height: -webkit-fill-available;
  }
}
.rootWithCoupon {
  @include below(small) {
    min-height: calc(100vh - 104px);
  }
}
.content {
  display: flex;
  flex-direction: column;

  gap: 40px;
  justify-content: center;

  @include below(small) {
    gap: 32px;
  }
}

.legalNotice {
  margin-top: 40px;
  @include below(small) {
    margin-top: 32px;
  }
}
</style>
