<script lang="ts" setup>
import { Login, LukoButton, LukoPasswordField, LukoTextField } from '@demain-es/lukompo';
import { captureException } from '@sentry/minimal';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import LukoLink from '@/components/LukoLink';
import PopinContainer from '@/components/PopinContainer/PopinContainer.vue';
import { useUser } from '@/composables/app/useUser';
import { useState } from '@/composables/states/useState';
import { useError } from '@/composables/utils/useError';
import { useUrls } from '@/composables/utils/useUrls';
import { useValidation } from '@/composables/utils/useValidation';

const { isValidEmail } = useValidation();
const { t } = useI18n();
const { stateData } = useState();
const { login, userData } = useUser();
const { forgotPasswordUrl } = useUrls();
const { isUnauthorizedError } = useError();

const email = ref(isValidEmail(userData.email) ? userData.email : null);
const initialEmail = email.value;
const hasEmailAlready = !!initialEmail;
const hasEmailError = computed(() => (email.value ? !isValidEmail(email.value) : false));
const emailErrorMessage = ref<string>();

const password = ref<string>();
const passwordError = ref<string>();

const isButtonLoading = ref(false);

const checkForEmailErrorMessage = () => {
  if (hasEmailError.value) emailErrorMessage.value = t('Global.Errors.EmailField.invalid');
  else emailErrorMessage.value = undefined;
};

const loginCallback = computed(() => stateData.value.loginCallback);

const isDisplayed = ref(true);

const onLoginSuccess = async () => {
  await loginCallback.value?.();
  stateData.value.loginCallback = undefined;
  window.LukoTracking.trackEvent({
    id: 'Login'
  });
};

onMounted(() => {
  window.LukoTracking.trackEvent({
    id: 'Login Popup'
  });
});

watch(isDisplayed, (value) => {
  if (!value) {
    stateData.value.loginCallback = undefined;
  }
});

onUnmounted(() => {
  stateData.value.loginCallback = undefined;
});

const onLogin = async () => {
  if (!email.value || !password.value) return;

  isButtonLoading.value = true;

  try {
    await login(email.value, password.value);

    passwordError.value = undefined;
    await onLoginSuccess();
  } catch (err) {
    window.Logger.$logError('Login fail', err);

    if (isUnauthorizedError(err)) {
      passwordError.value = t('Popups.LoginPopup.wrongPasswordError');
      return;
    }

    captureException(err);
  } finally {
    isButtonLoading.value = false;
  }
};
</script>

<template>
  <PopinContainer
    v-model:isDisplayed="isDisplayed"
    :icon="Login"
    :title="$t('Popups.LoginPopup.heading')"
    :description="hasEmailAlready ? $t('Popups.LoginPopup.description') : undefined"
    has-row-buttons
    :on-submit="onLogin"
    data-test="LoginPopup"
    data-luko-tracking="LoginPopup"
  >
    <div :class="$style.container">
      <LukoTextField
        v-model="email"
        :label="$t('Popups.LoginPopup.emailLabel')"
        type="email"
        required
        :autofocus="!hasEmailAlready"
        :error="emailErrorMessage"
        :disabled="hasEmailAlready"
        data-test="LoginPopupEmail"
        @blur="checkForEmailErrorMessage"
      />
      <LukoPasswordField
        v-model="password"
        :label="$t('Popups.LoginPopup.passwordLabel')"
        :error="passwordError"
        required
        :autofocus="hasEmailAlready"
        data-test="LoginPopupPassword"
      />
    </div>
    <template #buttons>
      <LukoLink target="_blank" :href="forgotPasswordUrl">
        <LukoButton class="w-full" light type="button">
          {{ $t('Popups.LoginPopup.forgotPassword') }}
        </LukoButton>
      </LukoLink>
      <LukoButton
        data-test="LoginPopupLoginButton"
        type="submit"
        :loading="isButtonLoading"
        :disabled="hasEmailError || !password || !email"
      >
        {{ $t('Popups.LoginPopup.button') }}
      </LukoButton>
    </template>
  </PopinContainer>
</template>

<style lang="scss" module>
.container {
  display: grid;
  grid-gap: 24px 8px;
  grid-template-columns: 1fr;
}
</style>
