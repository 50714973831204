export enum ClaimHistoryEnum {
  ZERO = '0',
  ONE = '1',
  TWO = '2',
  TWO_PLUS = '2+',
  THREE_PLUS = '3+',
  UNKNOWN = 'Unknown',
  MISSING = 'Missing',
  MAX_CLAIMS_DE = 1,
  MAX_CLAIMS = 2
}
