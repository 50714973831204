import { DateTimeFormats } from '@intlify/core-base';

export default {
  fr: {
    xshort: {
      day: 'numeric',
      month: 'short'
    },
    short: {
      month: 'short',
      year: 'numeric'
    },
    small: {
      month: 'long',
      year: 'numeric'
    },
    medium: {
      day: 'numeric',
      month: 'long'
    },
    long: {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    },
    'full-numeric': {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    weekday: {
      weekday: 'long'
    },
    month: {
      month: 'long'
    }
  },
  en: {
    xshort: {
      day: 'numeric',
      month: 'short'
    },
    short: {
      month: 'short',
      year: 'numeric'
    },
    small: {
      month: 'long',
      year: 'numeric'
    },
    medium: {
      day: 'numeric',
      month: 'long'
    },
    long: {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    },
    'full-numeric': {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    weekday: {
      weekday: 'long'
    },
    month: {
      month: 'long'
    }
  },
  de: {
    xshort: {
      day: 'numeric',
      month: 'short'
    },
    short: {
      month: 'short',
      year: 'numeric'
    },
    small: {
      month: 'long',
      year: 'numeric'
    },
    medium: {
      day: 'numeric',
      month: 'long'
    },
    long: {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    },
    'full-numeric': {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    weekday: {
      weekday: 'long'
    },
    month: {
      month: 'long'
    }
  }
} as DateTimeFormats;
