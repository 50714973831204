import { createGtm } from '@gtm-support/vue-gtm';
import { App } from '@vue/runtime-core';

import { router } from '@/router/router';

export const install = (app: App): void => {
  if (import.meta.env.VITE_AMPLIFY === 'true' && import.meta.env.VITE_APP_GTM_ID) {
    app.use(
      createGtm({
        id: import.meta.env.VITE_APP_GTM_ID,
        defer: true,
        vueRouter: router
      })
    );
  }
};
