import { toRaw } from 'vue';

import PartnerAPI from '@/api/PartnerAPI';
import { Prospect } from '@/api/prospect/types';
import { i18n } from '@/i18n';

export default {
  async getProspectByKey(prospectKey: string): Promise<Prospect> {
    try {
      const { data } = await PartnerAPI.get<Prospect>(`/prospect/${prospectKey}`);
      if (data.key) {
        return Promise.resolve(data);
      }

      throw new Error('missing prospect key');
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getLatestProspectByKey(prospectKey: string): Promise<Prospect> {
    try {
      const { data } = await PartnerAPI.get<Prospect>(`/prospect/${prospectKey}/latest`);

      if (data.key) {
        return Promise.resolve(data);
      }

      throw new Error('missing prospect key');
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async saveProspectApi(prospectData: Prospect): Promise<Prospect> {
    const { data } = await PartnerAPI.post<Prospect>(`/prospect`, toRaw(prospectData));
    if (data.key) return data;

    throw new Error('missing prospect key');
  },

  async sendOfferPdf(prospectKey: string): Promise<Prospect> {
    try {
      const response = await PartnerAPI.post<Prospect>(
        `/prospect/${prospectKey}/reminder?lang=${i18n.global.locale}`,
        {}
      );
      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async sendLegalPdf(prospectKey: string): Promise<{ sent_date: string }> {
    try {
      const response = await PartnerAPI.post(
        `/prospect/${prospectKey}/legal_recap?lang=${i18n.global.locale}`
      );

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  getOfferPdfUrl(prospectKey, local) {
    return `${
      import.meta.env.VITE_API_PARTNER_URL
    }/prospect/${prospectKey}/documents/offer.pdf?lang=${local}`;
  }
};
