/* eslint-disable no-console */
import { useEnv } from '@/composables/utils/useEnv';

const { isDev, devEnvForced } = useEnv();

window.Logger = {
  $log: (message, ...params) => {
    if (isDev || devEnvForced.value) console.log(message, ...params);
  },
  $logError: (message, ...params) => {
    if (isDev || devEnvForced.value) console.error(message, ...params);
  },
  $warn: (message, ...params) => {
    if (isDev || devEnvForced.value) console.warn(message, ...params);
  }
};
