import axios from 'axios';
import { computed, ref } from 'vue';

import { useUser } from '@/composables/app/useUser/useUser';
import { useProspect } from '@/composables/states/useProspect';
import { useState } from '@/composables/states/useState';
import { useStorage } from '@/composables/utils/useStorage';

import {
  ErrorPopupType,
  ErrorResponseType,
  HTTPErrorType,
  IErrorData,
  IErrorInfo,
  PaymentErrorType,
  ProspectErrorType
} from './useError.types';

const errorData = ref<null | IErrorData>(null);

const { stateData } = useState();

const isNetworkError = (error) => {
  return error?.code === 'ERR_NETWORK';
};

const isVpnError = (error) => {
  return error.response?.data?.type === ErrorResponseType.UnauthorizedIPOperator;
};

const isUnauthorizedError = (error) => {
  return (
    error.response?.status === HTTPErrorType.Unauthorized &&
    error.response?.data?.type !== ErrorResponseType.UnauthorizedIPOperator
  );
};

const isBadRequestError = (error) => {
  return error.response?.status === HTTPErrorType.BadRequest;
};

const is404Error = (error) => {
  return error.response?.status === HTTPErrorType.NotFound;
};

const isServerError = (error) => {
  if (!axios.isAxiosError(error)) return false;

  return (
    (!error?.response?.status && error?.code === 'ERR_NETWORK') ||
    String(error?.response?.status).charAt(0) === '5'
  );
};

const isProspectLockedError = (error) => {
  return error.response?.data?.type === ErrorResponseType.ProspectLocked;
};

const isProspectSignedError = (error) => {
  return error.response?.data?.type === ErrorResponseType.ProspectSigned;
};

const isProspectExcludedTerritoryError = (error) => {
  return error.response?.data?.type === ProspectErrorType.ExcludedTerritoryError;
};

const isActiveMandateAlreadyExistingError = (error) => {
  return error.response?.data?.type === PaymentErrorType.ActiveMandateAlreadyExisting;
};

const isComplyAdvantageError = (error) => {
  return error.response?.data?.type === ErrorResponseType.ComplyAdvantageError;
};

const isIntentNotReady = (error) => {
  return error.response?.data?.type === PaymentErrorType.IntentNotReadyToBeCaptured;
};

const isPricingMismatchError = (error) => {
  return error.response?.data?.type === ErrorResponseType.PricingMismatchError;
};

const isSepaNotAuthorized = (error) => {
  return error.response?.data?.type === ErrorResponseType.SepaNotAuthorized;
};

const isSubscriptionInThePastError = (error) => {
  return error.response?.data?.type === ErrorResponseType.SubscriptionInThePastError;
};

const isDeductibleError = (error) => {
  return isInvalidInputError(error) && error.response?.data?.message?.includes('deductible');
};

const isPostcodeError = (error) => {
  return isInvalidInputError(error) && error.response?.data?.message?.includes('postcode');
};

const isContentValueError = (error) => {
  return error.response?.data?.type === ErrorResponseType.InvalidContentValueError;
};

const isInvalidInputError = (error) => {
  return error.response?.data?.message?.includes('Invalid');
};

const isAlreadySignedError = (error) => {
  return [
    ErrorResponseType.AlreadySignedContractError,
    ErrorResponseType.AlreadySignedDocumentSignatureRequestError,
    ErrorResponseType.AlreadySignedSignatureRequestError
  ].includes(error.response?.data?.type);
};

const isCreatedAccountError = (error) => {
  return (
    ['Account already created', 'User already exist'].includes(error.response?.data?.message) &&
    error.response?.data?.type === ErrorResponseType.CreatedAccount &&
    error.request?.responseURL?.includes('/user/account')
  );
};

const isMovingError = (error) => {
  return [
    ErrorResponseType.NoMovingWithLukoInMoreThanAMonth,
    ErrorResponseType.NoMovingWithLukoInThePast
  ].includes(error.response?.data?.type);
};

const showErrorPopup = (eData: IErrorData) => {
  errorData.value = eData;
};

// Set isAwait true to wait for callback execution
const handleUnauthorizedError = (callback, isAwait = false) => {
  return new Promise<void>((resolve) => {
    stateData.value.loginCallback = async () => {
      resolve(isAwait ? await callback() : callback());
    };
  });
};

const handleProspectLocked = (error) => {
  const { jwtToken } = useUser();
  const { getProspectData, PROSPECT_DATA_PATH } = useProspect();
  const { getFromLocalStorage } = useStorage();

  const contractId =
    getFromLocalStorage('contractId') || getProspectData(PROSPECT_DATA_PATH.contract_id);

  if (isProspectLockedError(error)) {
    return showErrorPopup({
      type: ErrorPopupType.ProspectLocked,
      error
    });
  }

  if (isProspectSignedError(error)) {
    return showErrorPopup({
      type: ErrorPopupType.ProspectSigned,
      error
    });
  }

  if (!jwtToken.value || !contractId) {
    return showErrorPopup({
      type: ErrorPopupType.ProspectMissingContract,
      error
    });
  }
};

const getErrorInfo = (error: unknown): IErrorInfo => {
  if (!error) return {};

  if (axios.isAxiosError(error)) {
    return {
      message: error.response?.data?.message || error.message,
      request_url: error.config?.url,
      status: error.response?.status,
      request: cleanRequestData(error.response?.config?.data),
      response: error.response?.data || {
        type: error.message // Network Error, Request aborted
      }
    };
  }
  if (error instanceof Error) {
    return {
      message: error.toString(),
      cause: error.cause
    };
  }

  if (error instanceof Event && error.type === 'error') {
    return {
      message: (error.target as Element)?.outerHTML
    };
  }

  return {
    message: error && typeof error === 'object' ? error.toString() : 'undefined error'
  };
};

const hasAlreadyErrorPopup = computed(() => !!errorData.value);

const cleanRequestData = (request) => {
  try {
    const parsedRequest = JSON.parse(request);
    return parsedRequest;
  } catch (err) {
    return request;
  }
};

export const useError = () => {
  return {
    getErrorInfo,
    isNetworkError,
    isUnauthorizedError,
    isBadRequestError,
    is404Error,
    isServerError,
    isProspectExcludedTerritoryError,
    isProspectLockedError,
    isProspectSignedError,
    isActiveMandateAlreadyExistingError,
    isComplyAdvantageError,
    isAlreadySignedError,
    isSepaNotAuthorized,
    isSubscriptionInThePastError,
    isCreatedAccountError,
    isVpnError,
    isMovingError,
    showErrorPopup,
    handleUnauthorizedError,
    isPricingMismatchError,
    isPostcodeError,
    isDeductibleError,
    isContentValueError,
    handleProspectLocked,
    hasAlreadyErrorPopup,
    isIntentNotReady,
    errorData
  };
};
