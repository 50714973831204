import { computed, reactive, ref, watch } from 'vue';

import CouponService from '@/api/coupon';
import { CouponData } from '@/api/coupon/coupon.types';
import { CouponRedeemTypeEnum } from '@/api/coupon/coupon.types';
import { usePartner } from '@/composables/app/usePartner/usePartner';
import { useProduct } from '@/composables/app/useProduct';
import { useSteps } from '@/composables/app/useSteps';
import { useProspect } from '@/composables/states/useProspect';
import { HTTPErrorType } from '@/composables/utils/useError/useError.types';
import { useNumber } from '@/composables/utils/useNumber';
import { useStorage } from '@/composables/utils/useStorage';
import { i18n } from '@/i18n';
import { PaymentFrequencyEnum } from '@/types/Payment';

import { CouponsCode } from './useCoupon.types';

const { setProspectData, getProspectData, PROSPECT_DATA_PATH } = useProspect();
const { setInSessionStorage, getFromSessionStorage } = useStorage();
const { formatCentsNumber } = useNumber();
const { isPricingStep, isPostProspectStep, isRecapStep } = useSteps();
const { isAggregator } = usePartner();
const { isMortgage } = useProduct();

const couponData: CouponData = reactive({
  code: null,
  email: null,
  amount: null,
  redeem_type: null,
  success_msg: null,
  banner_msg: null,
  // Post Pricing fields
  premium_monthly: null,
  premium_yearly: null,
  premium_monthly_disc: null,
  premium_yearly_disc: null,
  applied: false
});

const isFetching = ref(false);
const isReferral = computed(() => couponData.code === CouponsCode.EMAIL);

// Fetch coupon data from the server
const fetchCoupon = async (couponCode: string) => {
  if (!couponCode) {
    return resetCoupon(true);
  }
  if (!isFetching.value) {
    isFetching.value = true;
    setProspectData(PROSPECT_DATA_PATH.coupon, couponCode);
    try {
      const data = await CouponService.getCouponData(couponCode);
      if (data?.code) {
        if (data.code === CouponsCode.EMAIL) {
          couponData.email = couponCode;
        }
        couponData.code = data.code;
        couponData.amount = data.redeem_amount;
        couponData.redeem_type = data.redeem_type;
        couponData.success_msg = data.success_msg;
        couponData.banner_msg = data.banner_msg;

        setProspectData(PROSPECT_DATA_PATH.coupon, couponCode);
        setInSessionStorage('coupon', couponCode);
      } else {
        resetCoupon(true);
      }
      isFetching.value = false;
    } catch (err) {
      if (err.response?.status === HTTPErrorType.NotFound) {
        resetCoupon(true);
      }
      if (err.response?.status === HTTPErrorType.RateLimit) {
        resetCoupon(true);
      }
      isFetching.value = false;
    }
  }
};

// Apply coupon from the current quote
const applyCoupon = (data) => {
  if (data) {
    const NUMBER_OF_MONTHS_PAID_UPFRONT = 2;

    couponData.premium_monthly = data.premiumMonthly;
    couponData.premium_yearly = data.premiumYearly;
    couponData.applied = true;

    const monthlyPrice = data.premiumMonthly * NUMBER_OF_MONTHS_PAID_UPFRONT;

    couponData.premium_monthly_disc = monthlyPrice - data.amount_monthly;
    couponData.premium_yearly_disc = data.premiumYearly - data.amount_yearly;
    if (data.premiumMonthly < data.amount_monthly) {
      couponData.premium_monthly_disc = data.premiumMonthly;
    }
    if (isReferral.value) {
      couponData.amount = data.amount_yearly;
    }
  }
};

// Coupon rules
const CouponTranslationData = computed(() => ({
  value: couponData.amount,
  amount: formatCentsNumber(couponData.amount),
  coupon: couponData.code,
  quote: formatCentsNumber(couponData.premium_monthly),
  quoteDiscMonthly: formatCentsNumber(couponData.premium_monthly_disc),
  quoteDiscYearly: formatCentsNumber(couponData.premium_yearly_disc),
  left: formatCentsNumber((couponData.amount || 0) - (couponData.premium_monthly || 0))
}));

const couponRules = computed(() => [
  // REFFERAL
  {
    id: 'Refferal apply for monthly payment if small coupon',
    bannerMessage: i18n.global.t('Coupon.Banner.referral', CouponTranslationData.value),
    successMessage: i18n.global.t('Coupon.Success.referral.monthly', CouponTranslationData.value),
    conditions: [
      (data) =>
        isReferral.value &&
        data.redeem_type === CouponRedeemTypeEnum.AMOUNT &&
        (getProspectData(PROSPECT_DATA_PATH.billing_frequency) || PaymentFrequencyEnum.MONTHLY) ===
          PaymentFrequencyEnum.MONTHLY &&
        (data?.amount < (couponData.premium_monthly || 0) || isMortgage.value)
    ]
  },
  {
    id: 'Refferal applied for monthly payment if too big coupon',
    bannerMessage: i18n.global.t('Coupon.Banner.referral', CouponTranslationData.value),
    successMessage: i18n.global.t('Coupon.Success.referral.bigger', CouponTranslationData.value),
    conditions: [
      (data) =>
        isReferral.value &&
        data.redeem_type === CouponRedeemTypeEnum.AMOUNT &&
        (getProspectData(PROSPECT_DATA_PATH.billing_frequency) || PaymentFrequencyEnum.MONTHLY) ===
          PaymentFrequencyEnum.MONTHLY &&
        data?.amount >= (data.premium_monthly || 0)
    ]
  },
  {
    id: 'Refferal apply for yearly payment',
    bannerMessage: i18n.global.t('Coupon.Banner.referral', CouponTranslationData.value),
    successMessage: i18n.global.t('Coupon.Success.referral.yearly', CouponTranslationData.value),
    conditions: [
      (data) =>
        isReferral.value &&
        data.redeem_type === CouponRedeemTypeEnum.AMOUNT &&
        (getProspectData(PROSPECT_DATA_PATH.billing_frequency) || PaymentFrequencyEnum.MONTHLY) ===
          PaymentFrequencyEnum.YEARLY
    ]
  },
  // MONTH
  {
    id: 'Month Off applied before pricing',
    bannerMessage: i18n.global.tc(
      'Coupon.Banner.month',
      CouponTranslationData.value.value as number,
      CouponTranslationData.value
    ),
    successMessage: i18n.global.tc(
      'Coupon.Success.month.yearly',
      CouponTranslationData.value.value as number,
      CouponTranslationData.value
    ),
    conditions: [(data) => data.redeem_type === CouponRedeemTypeEnum.MONTH && !couponData.applied]
  },
  {
    id: '1 Month Off applied',
    bannerMessage: isAggregator.value
      ? i18n.global.tc(
          'Coupon.Banner.aggregMonth',
          CouponTranslationData.value.value as number,
          CouponTranslationData.value
        )
      : i18n.global.tc(
          'Coupon.Banner.month',
          CouponTranslationData.value.value as number,
          CouponTranslationData.value
        ),
    successMessage: i18n.global.t('Coupon.Success.month.oneMonth', CouponTranslationData.value),
    conditions: [
      (data) =>
        data.redeem_type === CouponRedeemTypeEnum.MONTH &&
        data.amount === 1 &&
        (getProspectData(PROSPECT_DATA_PATH.billing_frequency) || PaymentFrequencyEnum.MONTHLY) ===
          PaymentFrequencyEnum.MONTHLY
    ]
  },
  {
    id: 'Multiple Month Off applied for monthly payment',
    bannerMessage: isAggregator.value
      ? i18n.global.tc(
          'Coupon.Banner.aggregMonth',
          CouponTranslationData.value.value as number,
          CouponTranslationData.value
        )
      : i18n.global.tc(
          'Coupon.Banner.month',
          CouponTranslationData.value.value as number,
          CouponTranslationData.value
        ),
    successMessage: i18n.global.t(
      'Coupon.Success.month.multipleMonth',
      CouponTranslationData.value
    ),
    conditions: [
      (data) =>
        data.redeem_type === CouponRedeemTypeEnum.MONTH &&
        data.amount > 1 &&
        (getProspectData(PROSPECT_DATA_PATH.billing_frequency) || PaymentFrequencyEnum.MONTHLY) ===
          PaymentFrequencyEnum.MONTHLY
    ]
  },
  {
    id: 'Multiple Months Off applied for yearly payment',
    bannerMessage: isAggregator.value
      ? i18n.global.tc(
          'Coupon.Banner.aggregMonth',
          CouponTranslationData.value.value as number,
          CouponTranslationData.value
        )
      : i18n.global.tc(
          'Coupon.Banner.month',
          CouponTranslationData.value.value as number,
          CouponTranslationData.value
        ),
    successMessage: i18n.global.tc(
      'Coupon.Success.month.yearly',
      CouponTranslationData.value.value as number,
      CouponTranslationData.value
    ),
    conditions: [
      (data) =>
        data.redeem_type === CouponRedeemTypeEnum.MONTH &&
        (getProspectData(PROSPECT_DATA_PATH.billing_frequency) || PaymentFrequencyEnum.MONTHLY) ===
          PaymentFrequencyEnum.YEARLY
    ]
  },

  // AMOUNT
  {
    id: 'Amount Off applied before pricing for Aggregs',
    bannerMessage: i18n.global.t('Coupon.Banner.amount', CouponTranslationData.value),
    successMessage: i18n.global.t('Coupon.Success.amount.monthly', CouponTranslationData.value),
    conditions: [(data) => data.redeem_type === CouponRedeemTypeEnum.AMOUNT && !couponData.applied]
  },
  {
    id: 'Amount Off applied for monthly payment if small coupon',
    bannerMessage: isAggregator.value
      ? i18n.global.t('Coupon.Banner.aggregAmount', CouponTranslationData.value)
      : i18n.global.t('Coupon.Banner.amount', CouponTranslationData.value),
    successMessage: i18n.global.t('Coupon.Success.amount.monthly', CouponTranslationData.value),
    conditions: [
      (data) =>
        data.redeem_type === CouponRedeemTypeEnum.AMOUNT &&
        (getProspectData(PROSPECT_DATA_PATH.billing_frequency) || PaymentFrequencyEnum.MONTHLY) ===
          PaymentFrequencyEnum.MONTHLY &&
        data?.amount < (couponData.premium_monthly || 0)
    ]
  },
  {
    id: 'Amount Off applied for monthly payment if too big coupon',
    bannerMessage: isAggregator.value
      ? i18n.global.t('Coupon.Banner.aggregAmount', CouponTranslationData.value)
      : i18n.global.t('Coupon.Banner.amount', CouponTranslationData.value),
    successMessage: i18n.global.t('Coupon.Success.amount.bigger', CouponTranslationData.value),
    conditions: [
      (data) =>
        data.redeem_type === CouponRedeemTypeEnum.AMOUNT &&
        (getProspectData(PROSPECT_DATA_PATH.billing_frequency) || PaymentFrequencyEnum.MONTHLY) ===
          PaymentFrequencyEnum.MONTHLY &&
        data?.amount >= (data.premium_monthly || 0)
    ]
  },
  {
    id: 'Amount Off applied for yearly payment',
    bannerMessage: isAggregator.value
      ? i18n.global.t('Coupon.Banner.aggregAmount', CouponTranslationData.value)
      : i18n.global.t('Coupon.Banner.amount', CouponTranslationData.value),
    successMessage: i18n.global.t('Coupon.Success.amount.yearly', CouponTranslationData.value),
    conditions: [
      (data) =>
        data.redeem_type === CouponRedeemTypeEnum.AMOUNT &&
        (getProspectData(PROSPECT_DATA_PATH.billing_frequency) || PaymentFrequencyEnum.MONTHLY) ===
          PaymentFrequencyEnum.YEARLY
    ]
  },

  // PERCENTAGE
  {
    id: 'Percentage Off applied',
    bannerMessage: i18n.global.t('Coupon.Banner.percentage', CouponTranslationData.value),
    successMessage: i18n.global.t('Coupon.Success.percentage', CouponTranslationData.value),
    conditions: [
      (data) => data.redeem_type === CouponRedeemTypeEnum.PERCENTAGE && !isAggregator.value
    ]
  },
  {
    id: 'Percentage Off applied for Aggregs before pricing',
    bannerMessage: i18n.global.t('Coupon.Banner.percentage', CouponTranslationData.value),
    successMessage: i18n.global.t('Coupon.Success.percentage', CouponTranslationData.value),
    conditions: [
      (data) =>
        data.redeem_type === CouponRedeemTypeEnum.PERCENTAGE &&
        isAggregator.value &&
        !couponData.applied
    ]
  },
  {
    id: 'Percentage Off applied for Aggregs at pricing',
    bannerMessage: i18n.global.t('Coupon.Banner.aggregPercentage', CouponTranslationData.value),
    successMessage: i18n.global.t('Coupon.Success.percentage', CouponTranslationData.value),
    conditions: [
      (data) =>
        data.redeem_type === CouponRedeemTypeEnum.PERCENTAGE &&
        isAggregator.value &&
        couponData.applied
    ]
  }
]);

const getRules = () => {
  for (const r of couponRules.value) {
    if (r.conditions.every((condition) => condition(couponData))) return r;
  }
};

// Get Banner message for the current coupon
const couponBannerMsg = computed(() => {
  if (couponData.banner_msg?.[i18n.global.locale]) {
    return couponData.banner_msg?.[i18n.global.locale];
  }
  const rule = getRules();
  return rule?.bannerMessage;
});

// Get success message for the current coupon
const couponSuccessMsg = computed(() => {
  if (couponData.success_msg?.[i18n.global.locale]) {
    return couponData.success_msg?.[i18n.global.locale];
  }
  const rule = getRules();
  return rule?.successMessage;
});

// Should we display coupon in the app
const couponShouldBeDisplayed = computed(() => {
  return (
    (!!couponData.code && !isPostProspectStep.value) ||
    (couponData.code && (isPricingStep.value || isRecapStep.value) && couponData.applied)
  );
});

watch(couponShouldBeDisplayed, (value, oldvalue) => {
  if (oldvalue === undefined || oldvalue === null) return;
  window.LukoTracking.trackEvent({
    id: value ? 'Coupon Applied' : 'Coupon Deleted',
    properties: {
      coupon: {
        amount: couponData?.amount,
        code: couponData?.code,
        message: couponBannerMsg.value,
        success: couponData?.applied
      }
    }
  });
});

// Restore coupon data from prospect data or the session storage
const restoreCoupon = () => {
  const couponCode: string | null =
    getProspectData(PROSPECT_DATA_PATH.coupon) || getFromSessionStorage('coupon');
  if (couponCode && !couponData.code) {
    fetchCoupon(couponCode);
  }
};

// Is coupon valid
const validCoupon = computed(() => {
  return couponData.code && couponData.applied && couponData.amount;
});

// Reset coupon data
const resetCoupon = (hard = false) => {
  if (hard) {
    couponData.code = null;
  }
  couponData.amount = null;
  couponData.redeem_type = null;
  couponData.success_msg = null;
  couponData.banner_msg = null;
  couponData.premium_monthly = null;
  couponData.premium_yearly = null;
  couponData.premium_monthly_disc = null;
  couponData.premium_yearly_disc = null;
  couponData.applied = false;
  setProspectData(PROSPECT_DATA_PATH.coupon, null);
  setInSessionStorage('coupon', null);
};

export const useCoupon = () => {
  return {
    restoreCoupon,
    fetchCoupon,
    applyCoupon,
    resetCoupon,
    validCoupon,
    couponData,
    couponBannerMsg,
    couponSuccessMsg,
    couponShouldBeDisplayed,
    isReferral
  };
};
