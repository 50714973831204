import { useProspect } from '@/composables/states/useProspect';

export const cleanGuard = async (to, from, next) => {
  const { path, hash, query } = to;
  if (path.includes('#') && !hash) {
    const parse = path.split('#');
    return next({ hash: `#${parse[1]}`, path: parse[0], query });
  }

  // clean persisted prospect
  const { removePersistedProspect, getPersistedProspect } = useProspect();
  const product = query.product || query['$.product'];
  if (product) {
    const persistedProspect = getPersistedProspect();
    if (persistedProspect && persistedProspect.product !== product) {
      removePersistedProspect();
    }
  }

  return next();
};
