import { Plugin } from '@demain-es/lukosiris';

import { useEnv } from '@/composables/utils/useEnv';
import { router } from '@/router/router';

import { getEventData, getUserData } from './tracking';

const { isDev, isE2E } = useEnv();

if (import.meta.env.VITE_AMPLIFY === 'true' || isE2E.value) {
  new Plugin({
    projectId: 'Onboarding',
    vueRouter: router,
    loadEventData: getEventData,
    loadUserData: getUserData,
    amplitudeInstance: isDev ? 'lukosiris-dev' : 'lukosiris',
    amplitudeCoverKey: import.meta.env.VITE_APP_AMPLITUDE_COVER_KEY,
    amplitudeKey: import.meta.env.VITE_APP_AMPLITUDE_KEY,
    verbose: isDev
  }).initialize();
} else {
  window.LukoTracking = {
    trackEvent: (properties) => {
      window.Logger.$log('TRACKING DEBUG', properties.id, { properties, ...getEventData() });
    }
  };
}
