<script lang="ts" setup>
import { LukoPopin } from '@demain-es/lukompo';
import { computed } from 'vue';

const props = defineProps({
  isDisplayed: {
    type: Boolean,
    required: true
  },
  icon: {
    type: Function,
    default: undefined
  },
  theme: {
    type: String,
    default: undefined
  },
  title: {
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  },
  hasRowButtons: {
    type: Boolean,
    default: false
  },
  onSubmit: {
    type: Function,
    default: null
  },
  hasOutsideClick: {
    type: Boolean,
    default: true
  },
  isUnclosable: {
    type: Boolean,
    default: false
  },
  wide: {
    type: Boolean,
    default: false
  },
  large: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['update:isDisplayed']);

const computedIsDisplayed = computed({
  get: () => props.isDisplayed,
  set: (value) => emit('update:isDisplayed', value)
});
</script>

<template>
  <LukoPopin
    v-model="computedIsDisplayed"
    v-bind="$attrs"
    :unclosable="isUnclosable"
    :title="title"
    :subtitle="description"
    :icon="icon"
    :theme="theme"
    :wide="wide"
    :large="large"
  >
    <form v-if="onSubmit" :class="$style.popinSlot" @submit.prevent="() => onSubmit()">
      <div v-if="$slots.default" :class="$style.content">
        <slot />
      </div>
      <div v-if="$slots.buttons" :class="[$style.buttons, { [$style.rowButtons]: hasRowButtons }]">
        <slot name="buttons" />
      </div>
    </form>
    <template v-else>
      <div v-if="$slots.default" :class="$style.content">
        <slot />
      </div>
      <div v-if="$slots.buttons" :class="[$style.buttons, { [$style.rowButtons]: hasRowButtons }]">
        <slot name="buttons" />
      </div>
    </template>
  </LukoPopin>
</template>

<style lang="scss" module>
.heading {
  text-align: center;
}

.title {
  @include typo-headline-bold;
  color: $gray-1000;
}

.title,
.description {
  margin-top: 8px;
  margin-bottom: 0;
}

.content {
  text-align: center;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.rowButtons {
  flex-direction: column-reverse;
  gap: 8px;

  @include above(small) {
    flex-direction: row;
    flex-wrap: wrap;

    > * {
      flex: 1;
    }
  }
}
.popinSlot {
  display: flex;
  flex-direction: column;
  gap: 48px;
}
</style>
