import * as Sentry from '@sentry/browser';
import { captureException } from '@sentry/minimal';
import get from 'lodash-es/get';
import set from 'lodash-es/set';
import { computed, reactive, ref, watch } from 'vue';

import AuthService from '@/api/auth';
import { IAuthUser, IIntercomTokens } from '@/api/auth/auth.types';
import { useContract } from '@/composables/states/useContract/useContract';
import { VALID_CONTRACT_PATHS } from '@/composables/states/useContract/useContract.types';
import { useProspect } from '@/composables/states/useProspect';
import { useState } from '@/composables/states/useState';
import { useCookies } from '@/composables/utils/useCookies';
import { HTTPErrorType } from '@/composables/utils/useError/useError.types';
import { useIntercom } from '@/composables/utils/useIntercom';
import { useStorage } from '@/composables/utils/useStorage';
import { ProductEnum } from '@/types/Product';
import { TokenType } from '@/types/Token';

import { IUser, UserPathEnum } from './useUser.types';

const { stateData } = useState();
const { getFromLocalStorage, setInLocalStorage, removeFromLocalStorage } = useStorage();
const { mergeCookie, CookieNamesEnum } = useCookies();
const { setProspectData, PROSPECT_DATA_PATH, MORTGAGE_PROSPECT_DATA_PATH } = useProspect();
const { setContractData } = useContract();

const userData = reactive({
  userId: undefined,
  email: '',
  contractId: undefined,
  firstname: '',
  birthdate: '',
  birth_country_code: undefined,
  lastname: '',
  gender: undefined,
  phone: undefined,
  giveback: undefined,
  contracts: undefined
});

const setUserData = <T>(key: UserPathEnum, data: T | unknown) => set(userData, key, data);
const getUserData = <T>(key: UserPathEnum): T => get(userData, key);

const jwtTokenRef = ref<TokenType>(getFromLocalStorage(UserPathEnum.token));
const intercomTokenRef = ref<TokenType>(getFromLocalStorage(UserPathEnum.intercomToken));

const jwtToken = computed({
  get: (): TokenType => jwtTokenRef.value,
  set: (token: TokenType) => {
    jwtTokenRef.value = token;
    if (token) {
      setInLocalStorage(UserPathEnum.token, token);
    } else {
      removeFromLocalStorage([UserPathEnum.token]);
    }
  }
});

const intercomToken = computed({
  get: (): TokenType => intercomTokenRef.value,
  set: (token: TokenType) => {
    intercomTokenRef.value = token;
    if (token) {
      setInLocalStorage(UserPathEnum.intercomToken, token);
    } else {
      removeFromLocalStorage([UserPathEnum.intercomToken]);
    }
  }
});

const isLoggedIn = computed(() => !!jwtToken.value && !!userData.email);

const login = async (mail: string, password: string) => {
  const data = await AuthService.login(mail, password);
  fillUserData(data.user, { jwt_token: data.jwt_token, intercom_tokens: data.intercom_tokens });
  Sentry.setUser({ id: data.user.id, mail });
};

const logout = () => {
  const { logoutIntercom } = useIntercom();
  logoutIntercom();
  resetUserData();
  jwtToken.value = null;
  intercomToken.value = null;
};

const register = async (payload) => {
  const data = await AuthService.createUser(payload);
  if (data) {
    fillUserData(data.user, { jwt_token: data.token, intercom_tokens: data.intercom_tokens });
    persistUserData();
  }
  return data;
};

const resetUserData = async () => {
  for (const key in userData) userData[key] = undefined;
  setProspectData(PROSPECT_DATA_PATH.subscriber.birth_country_code, null);
};

const persistUserData = () => {
  for (const key in userData) {
    setInLocalStorage(key, userData[key]);
  }
};

const isFetching = ref(false);
const isFetched = ref(false);
const fetchUserLoggedData = (forceFetch = false) => {
  if (forceFetch) isFetched.value = false;
  if (isFetched.value || isFetching.value) return;

  isFetching.value = true;
  return AuthService.getUser()
    .then((data) => {
      fillUserData(data, { intercom_tokens: data.intercom_tokens });
      isFetched.value = true;
    })
    .catch((err) => {
      if (err.response?.status === HTTPErrorType.NotFound) {
        jwtToken.value = null;
        return;
      }
      if (err.response?.status === HTTPErrorType.Unauthorized) {
        jwtToken.value = null;
        if (!stateData.value.loginCallback) {
          stateData.value.loginCallback = () => null;
        }
        return;
      }
      captureException(err);
    })
    .finally(() => {
      isFetching.value = false;
    });
};

const fillUserData = (
  user: IAuthUser,
  tokens?: { jwt_token?: TokenType; intercom_tokens?: IIntercomTokens }
) => {
  const { loginIntercom } = useIntercom();
  if (tokens?.jwt_token) {
    jwtToken.value = tokens.jwt_token;
  }
  if (tokens?.intercom_tokens?.web) {
    intercomToken.value = tokens.intercom_tokens.web;
    loginIntercom(user.email, tokens.intercom_tokens.web, user.firstname, user.lastname);
  }

  setUserData(UserPathEnum.userId, user.id);
  setUserData(UserPathEnum.email, user.email);
  setUserData(UserPathEnum.first_name, user.firstname);
  setUserData(UserPathEnum.last_name, user.lastname);
  setUserData(UserPathEnum.uuid, user.uuid);
  if (user.gender) setUserData(UserPathEnum.gender, user.gender);
  if (user.birthdate) setUserData(UserPathEnum.birth_date, user.birthdate);
  if (user.birth_country_code)
    setUserData(UserPathEnum.birth_country_code, user.birth_country_code);
  if (user.phone) setUserData(UserPathEnum.phone, user.phone);
  if (user.coupon) setUserData(UserPathEnum.coupon, user.coupon);
  if (user.charity) setUserData(UserPathEnum.giveback, user.charity);

  if (user.contractId) {
    setUserData(UserPathEnum.contractId, user.contractId);
    setContractData(VALID_CONTRACT_PATHS.ID, user.contractId);
  }
  if (user.account_created === false) {
    stateData.value.needsAccountCreation = true;
  }
  if (user.contracts) {
    setUserData(UserPathEnum.contracts, user.contracts);
  } else {
    getExistingContracts();
  }
};

// Remove starting and trailing ", that come from MyLuko
const trimDoubleQuote = (str) => {
  return typeof str === 'string' ? str.replace(/^"(.*)"$/, '$1') : str;
};

const fillUserDataFromSession = () => {
  const localEmail = trimDoubleQuote(getFromLocalStorage(UserPathEnum.email));
  if (!localEmail) return;

  const userLocal: IUser = {};
  userLocal.email = trimDoubleQuote(localEmail);
  userLocal.userId = trimDoubleQuote(getFromLocalStorage(UserPathEnum.userId));
  userLocal.phone = trimDoubleQuote(getFromLocalStorage(UserPathEnum.phone));
  userLocal.firstname = trimDoubleQuote(getFromLocalStorage(UserPathEnum.first_name));
  userLocal.birthdate = trimDoubleQuote(getFromLocalStorage(UserPathEnum.birth_date));
  userLocal.birth_country_code = trimDoubleQuote(
    getFromLocalStorage(UserPathEnum.birth_country_code)
  );
  userLocal.lastname = trimDoubleQuote(getFromLocalStorage(UserPathEnum.last_name));
  userLocal.contractId = getFromLocalStorage(UserPathEnum.contractId) || undefined;
  userLocal.uuid = getFromLocalStorage(UserPathEnum.uuid) || undefined;

  setContractData(
    VALID_CONTRACT_PATHS.ID,
    getFromLocalStorage(UserPathEnum.contractId) || undefined
  );

  Object.assign(userData, userLocal);
};

const getExistingContracts = async () => {
  if (!jwtToken.value) {
    return [];
  }
  try {
    if (!userData.contracts && !isFetching.value) {
      isFetching.value = true;
      const data = await AuthService.getUser();
      const contracts = data.contracts || [];
      setUserData(UserPathEnum.contracts, contracts);
      isFetching.value = false;
      return contracts;
    }
    return userData.contracts;
  } catch (err) {
    if (err.response?.status === HTTPErrorType.Unauthorized) {
      jwtToken.value = null;
      if (!stateData.value.loginCallback) {
        stateData.value.loginCallback = () => null;
      }
      return;
    }
    isFetching.value = false;
    captureException(err);
  }
};

const hasSubscribedProducts = (product: ProductEnum[]) => {
  if (userData.contracts && userData.contracts?.length > 0)
    return (
      userData.contracts.filter((contract) => product.includes(contract.product_code)).length > 0
    );
  return false;
};

watch(userData, (_) => {
  persistUserData();

  setProspectData(PROSPECT_DATA_PATH.subscriber.email, userData.email);
  setProspectData(PROSPECT_DATA_PATH.subscriber.first_name, userData.firstname);
  setProspectData(PROSPECT_DATA_PATH.subscriber.last_name, userData.lastname);
  setProspectData(PROSPECT_DATA_PATH.subscriber.phone, userData.phone);
  setProspectData(PROSPECT_DATA_PATH.subscriber.birth_date, userData.birthdate);
  setProspectData(PROSPECT_DATA_PATH.subscriber.birth_country_code, userData.birth_country_code);
  setProspectData(MORTGAGE_PROSPECT_DATA_PATH.subscriber.gender, userData.gender);

  mergeCookie(CookieNamesEnum.LK_CMS, {
    firstname: userData.firstname || undefined,
    lastname: userData.lastname || undefined
  });
});

export const useUser = () => {
  return {
    userData,
    jwtToken,
    intercomToken,
    isLoggedIn,
    getUserData,
    setUserData,
    fillUserData,
    persistUserData,
    resetUserData,
    logout,
    login,
    register,
    fillUserDataFromSession,
    fetchUserLoggedData,
    hasSubscribedProducts
  };
};
