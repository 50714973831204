import { useStorage } from '@/composables/utils/useStorage';

const { setInSessionStorage } = useStorage();

export const publicKeyGuard = async (to, _, next) => {
  if (to.query.public_key) {
    setInSessionStorage('public_key', to.query.public_key);
  }
  next();
};
