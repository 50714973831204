<template>
  <div :class="[$style.loaderContainer, { [$style.fullScreen]: fullScreen }]">
    <div :class="$style.loader" :data-test="loadingData?.isRandom ? 'RandomLoader' : 'Loader'">
      <img :class="$style.loaderImg" :src="loaderImage" alt="loader" />
      <transition name="opacity" mode="out-in">
        <div :key="loadingData?.title">
          <h2
            :class="[
              $style.text,
              $style.title,
              { [$style.infoTitle]: loadingData?.title && loadingData?.description }
            ]"
          >
            <slot name="title" />
          </h2>
          <p :class="$style.text">
            <slot name="description" />
          </p>
          <div v-if="loadingData?.progressBars?.length" :class="$style.progressBars">
            <ProgressBar
              v-for="(bar, i) in loadingData.progressBars"
              :key="i"
              :text="bar"
              :is-last="i + 1 === loadingData.progressBars.length"
              :should-check="checkProgressBars"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import { useState } from '@/composables/states/useState';
import { useLoader } from '@/composables/utils/useLoader';

import loaderImage from './assets/loader.gif';
import ProgressBar from './components/ProgressBar.vue';

const { stateData } = useState();
const { checkProgressBars } = useLoader();

defineProps({
  fullScreen: {
    type: Boolean,
    default: true
  }
});

// We need it for a trick to trigger transition animation
const loadingData = computed(() => stateData.value.loadingData);
</script>

<style lang="scss" module>
.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  background: $white;

  &.fullScreen {
    position: fixed;
    top: 0;
    left: 50%;
    z-index: 10;

    width: 100vw;
    height: 100vh;

    transform: translateX(-50%);
  }
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.loaderImg {
  width: 56px;
  height: 56px;
}

.text {
  padding: 0 24px;

  text-align: center;
}

.title {
  margin-top: 16px;

  @include below(small) {
    font-size: 26px;
  }
}

.infoTitle {
  font-size: 20px;
  line-height: 28px;
}

.progressBars {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100vw;
  max-width: 344px;
  margin: 0 auto;
  margin-top: 40px;
  padding: 0 20px;
}
</style>
