import { UnleashClient } from 'unleash-proxy-client';

import { useEnv } from '@/composables/utils/useEnv';

const { VITE_UNLEASH_URL, VITE_UNLEASH_KEY } = import.meta.env;
const { isDev, isE2E } = useEnv();

export const install = (): void => {
  const unleash = new UnleashClient({
    url: VITE_UNLEASH_URL,
    clientKey: VITE_UNLEASH_KEY,
    refreshInterval: 100000,
    appName: 'onboarding',
    environment: isDev ? 'development' : 'production'
  });

  if (!isE2E.value) {
    unleash.start();
    window.unleash = unleash;
  } else {
    window.unleash = {
      isEnabled: () => {
        return false;
      },
      getVariant: () => {
        return false;
      },
      getAllToggles: () => {
        return {};
      },
      updateContext: () => {
        return false;
      },
      on: () => {
        return false;
      }
    };
  }
};
