import { setupDevtoolsPlugin } from '@vue/devtools-api';
import { App, watch } from 'vue';

import { useComponentManager } from '@/composables/app/useComponentManager';
import { useCoupon } from '@/composables/app/useCoupon';
import { useCrossSell } from '@/composables/app/useCrossSell';
import { useNavigation } from '@/composables/app/useNavigation';
import { useSteps } from '@/composables/app/useSteps';
import { useUser } from '@/composables/app/useUser';
import { useContract } from '@/composables/states/useContract/useContract';
import { useExtraInfo } from '@/composables/states/useExtraInfo';
import { useProspect } from '@/composables/states/useProspect';
import { useQuote } from '@/composables/states/useQuote';
import { useState } from '@/composables/states/useState';
import { useUnleash } from '@/composables/states/useUnleash';
import { useEnv } from '@/composables/utils/useEnv';
import { useMobileApp } from '@/composables/utils/useMobileApp';
import { useSignature } from '@/modules/signature/composables/useSignature';

export function install(app: App) {
  const { prospectData } = useProspect();
  const { stateData } = useState();
  const { userData } = useUser();
  const { stepsList, currentStep } = useSteps();
  const { isFirstLanded } = useNavigation();
  const { componentsOrder } = useComponentManager();
  const { flags } = useUnleash();
  const { currentQuote } = useQuote();
  const { isDev, isE2E, devEnvForced } = useEnv();
  const { isMobileApp } = useMobileApp();
  const { crossSellTemplates, crossSellRules } = useCrossSell();
  const { couponData } = useCoupon();
  const { signatureData } = useSignature();
  const { contractData } = useContract();
  const { extraInfos } = useExtraInfo();
  const initInspector = (api) => {
    const inspectorId = 'luko-plugin';

    api.addInspector({
      id: inspectorId,
      label: 'Luko States',
      icon: 'vertical_split'
    });

    api.on.getInspectorTree((payload) => {
      if (payload.inspectorId === inspectorId) {
        payload.rootNodes = [
          {
            id: 'root',
            label: 'Dev',
            children: [
              {
                id: 'env',
                label: 'Environements'
              },
              {
                id: 'prospect',
                label: 'Prospect'
              },
              {
                id: 'state',
                label: 'State'
              },
              {
                id: 'user',
                label: 'User'
              },
              {
                id: 'quote',
                label: 'Quote'
              },
              {
                id: 'signature',
                label: 'Signature'
              },
              {
                id: 'contract',
                label: 'Contract'
              }
            ]
          },
          {
            id: 'root',
            label: 'Product',
            children: [
              {
                id: 'featureFlags',
                label: 'Feature Flags'
              },
              {
                id: 'crossSell',
                label: 'Cross-Sell'
              },
              {
                id: 'coupon',
                label: 'Coupon'
              },
              {
                id: 'extraInfo',
                label: 'Extra Infos'
              },
              {
                id: 'splittest',
                label: 'Split Test'
              }
            ]
          },
          {
            id: 'root',
            label: 'Navigation',
            children: [
              {
                id: 'stepsOrder',
                label: 'Steps Order'
              },
              {
                id: 'componentsOrder',
                label: 'Components Order'
              }
            ]
          }
        ];
      }
    });

    api.on.getInspectorState((payload) => {
      if (payload.inspectorId === inspectorId) {
        switch (payload.nodeId) {
          case 'env':
            payload.state = {
              env: {
                isDev: isDev,
                isE2E: isE2E.value,
                devEnvForced: devEnvForced.value,
                isMobileApp: isMobileApp.value
              }
            };
            break;
          case 'prospect':
            payload.state = {
              prospectData
            };
            break;
          case 'state':
            payload.state = {
              stateData: stateData.value
            };
            break;
          case 'user':
            payload.state = {
              userData
            };
            break;
          case 'featureFlags':
            payload.state = {
              flags: { ...flags.value }
            };
            break;
          case 'stepsOrder':
            payload.state = {
              stepOrder: { ...stepsList.value },
              isFirstLanded: { value: isFirstLanded.value }
            };
            break;
          case 'componentsOrder':
            payload.state = {
              componentsOrder
            };
            break;
          case 'quote':
            payload.state = {
              currentQuote
            };
            break;
          case 'crossSell':
            payload.state = {
              crossSellTemplates,
              crossSellEnable: {
                ...crossSellRules.value
                  .map((item) => {
                    if (item.conditions.every((condition) => condition(prospectData))) {
                      return item.id;
                    }
                  })
                  .filter((item) => !!item)
              }
            };
            break;
          case 'coupon':
            payload.state = {
              couponData
            };
            break;
          case 'signature':
            payload.state = {
              signatureData
            };
            break;
          case 'contract':
            payload.state = {
              contractData
            };
            break;
          case 'extraInfo':
            payload.state = {
              extraInfos
            };
            break;
        }
      }
    });

    watch(
      [
        prospectData,
        currentQuote,
        stateData,
        userData,
        flags,
        stepsList,
        componentsOrder,
        crossSellTemplates,
        couponData,
        crossSellRules
      ],
      () => {
        api.sendInspectorState(inspectorId);
      }
    );
  };

  const initTimeline = (api) => {
    api.addTimelineLayer({
      id: 'prospectData',
      color: 0x2d50e6,
      label: 'prospectData'
    });
    api.addTimelineLayer({
      id: 'stateData',
      color: 0x2d50e6,
      label: 'stateData'
    });
    api.addTimelineLayer({
      id: 'featureFlags',
      color: 0x2d50e6,
      label: 'featureFlags'
    });
    api.addTimelineLayer({
      id: 'coupon',
      color: 0x2d50e6,
      label: 'coupon'
    });
    api.addTimelineLayer({
      id: 'currentStep',
      color: 0x2d50e6,
      label: 'currentStep'
    });
    api.addTimelineLayer({
      id: 'stepsOrder',
      color: 0x2d50e6,
      label: 'stepsOrder'
    });
    api.addTimelineLayer({
      id: 'componentsOrder',
      color: 0x2d50e6,
      label: 'componentOrder'
    });

    watch(prospectData, (value) => {
      api.addTimelineEvent({
        layerId: 'prospectData',
        event: {
          time: Date.now(),
          data: JSON.parse(JSON.stringify(value))
        }
      });
    });

    watch(stateData, (value) => {
      api.addTimelineEvent({
        layerId: 'stateData',
        event: {
          time: Date.now(),
          data: JSON.parse(JSON.stringify(value))
        }
      });
    });

    watch(userData, (value) => {
      api.addTimelineEvent({
        layerId: 'userData',
        event: {
          time: Date.now(),
          data: JSON.parse(JSON.stringify(value))
        }
      });
    });

    watch(flags, (value) => {
      api.addTimelineEvent({
        layerId: 'featureFlags',
        event: {
          time: Date.now(),
          data: JSON.parse(JSON.stringify(value))
        }
      });
    });
    watch(couponData, (value) => {
      api.addTimelineEvent({
        layerId: 'coupon',
        event: {
          time: Date.now(),
          data: JSON.parse(JSON.stringify(value))
        }
      });
    });
    watch(currentStep, (value) => {
      api.addTimelineEvent({
        layerId: 'currentStep',
        event: {
          time: Date.now(),
          data: { currentStep: value }
        }
      });
    });

    watch(stepsList, (value) => {
      api.addTimelineEvent({
        layerId: 'stepsOrder',
        event: {
          time: Date.now(),
          data: JSON.parse(JSON.stringify({ ...value }))
        }
      });
    });

    watch(componentsOrder, (value) => {
      api.addTimelineEvent({
        layerId: 'componentsOrder',
        event: {
          time: Date.now(),
          data: JSON.parse(JSON.stringify(value))
        }
      });
    });
  };

  setupDevtoolsPlugin(
    {
      id: 'luko-devtools-plugin',
      label: 'Luko Plugin',
      packageName: 'luko-plugin',
      homepage: 'https://vuejs.org',
      componentStateTypes: ['Luko Plugin state'],
      app
    },
    (api) => {
      initInspector(api);
      initTimeline(api);
    }
  );
}
