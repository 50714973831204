import { loadLanguageAsync } from '@/i18n';
import { AVAILABLE_LOCALES, DEFAULT_LOCALE } from '@/i18n/locales';

let languageLoaded = false;

export const loadLanguageGuard = async (to, from, next) => {
  if (!to.params.locale)
    return next({
      name: to.name,
      query: to.query,
      hash: to.hash,
      params: { ...to.params, locale: DEFAULT_LOCALE }
    });
  if (languageLoaded) return next();

  const isValidLocal = AVAILABLE_LOCALES.includes(to.params.locale);
  const locale = isValidLocal ? to.params.locale : DEFAULT_LOCALE;

  await loadLanguageAsync(locale);
  languageLoaded = true;

  return next();
};
