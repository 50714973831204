export enum MortgageMainAssetTypeEnum {
  NEW = 'new',
  INSURED_BY_BANK = 'insured',
  INSURED_BY_REINSURER = 'reinsured',
  TO_SPECIFY = 'insured_or_reinsured'
}

export enum MortgageOwnerTypeEnum {
  MAIN = 'main',
  SECONDARY = 'secondary',
  INVESTMENT = 'investment'
}

export enum MortgageTypeEnum {
  DEPRECIABLE = 'depreciable',
  INTEREST_ONLY = 'interest_only',
  ZERO_INTEREST = 'zero_interest',
  BRIDGE = 'bridge'
}

export enum MortgageBankNameEnum {
  CREDIT_AGRICOLE = 'Crédit Agricole',
  CAISSE_D_EPARGNE = "Caisse d'Epargne",
  BANQUE_POPULAIRE = 'Banque Populaire',
  BOURSORAMA = 'Boursorama',
  LCL = 'LCL',
  BNP_PARIBAS = 'BNP Paribas',
  LA_BANQUE_POSTALE = 'La Banque Postale',
  SOCIETE_GENERALE = 'Société Générale',
  CREDIT_MUTUEL = 'Crédit Mutuel',
  CIC = 'CIC',
  HSBC = 'HSBC',
  FORTUNEO = 'Fortuneo',
  CREDIT_MUTUEL_ARKEA = 'Crédit Mutuel Arkea',
  CREDIT_DU_NORD = 'Crédit du Nord',
  CREDIT_FONCIER = 'Crédit Foncier',
  ING = 'ING',
  AXA = 'AXA Banque',
  BRED_BANQUE_POPULAIRE = 'BRED Banque Populaire',
  B_FOR_BANK = 'BforBANK',
  ORANGE = 'Orange Bank',
  BFM = 'Banque Française Mutualiste',
  CREDIT_IMMOBILIER_DE_FRANCE = 'Crédit Immobilier de France',
  BANQUE_PALATINE = 'Banque Palatine',
  CETELEM = 'Cetelem',
  FEDERAL_FINANCE_GESTION = 'Federal Finance',
  OTHER = 'OTHER'
}

// Amounts are cents, don't be scary
export enum MortgageFormLimitsEnum {
  MAX_AMOUNT_71_YEARS_OLD = 50000000,
  MAX_TOTAL_AMOUNT = 500000000,
  MIN_TOTAL_AMOUNT = 2000000,
  BRIDGE_MAX_LOAN_DURATION = 36,
  BRIDGE_MAX_DEFERRAL_DURATION = 36,
  BRIDGE_MAX_TOTAL_DURATION = 36,
  INTEREST_ONLY_MAX_LOAN_DURATION = 240,
  INTEREST_ONLY_MAX_DEFERRAL_DURATION = 240,
  INTEREST_ONLY_MAX_TOTAL_DURATION = 240,
  INTEREST_ONLY_MIN_TOTAL_AMOUNT = 20000000,
  DEPRECIABLE_ZERO_INTEREST_MAX_LOAN_DURATION = 360,
  DEPRECIABLE_ZERO_INTEREST_MAX_DEFERRAL_DURATION = 60,
  DEPRECIABLE_ZERO_INTEREST_MAX_TOTAL_DURATION = 420
}

export interface MortgageData {
  currentIndex: number;
  list: Array<MortgageAssetItem>;
}
export type MortgageAssetItem = MortgageAssetDefaultItem | MortgageAssetItemZeroInterest;

export interface MortgageAssetDefaultItem {
  type: MortgageTypeEnum;
  deferral_type: 'partial'; // 'partial' as default
  deferral_in_months: number;
  rate_percentage: number | undefined;
  initial_start_date: string;
  initial_amount: number | undefined;
  initial_duration_in_months?: number;
  itt_deductible: 90; // 90 as default
}
export interface MortgageAssetItemZeroInterest extends MortgageAssetDefaultItem {
  type: MortgageTypeEnum.ZERO_INTEREST;
  rate_percentage: never;
}

export enum MortgageAmountErrorTypeEnum {
  AMOUNT_TOO_LOW = 'AMOUNT_TOO_LOW',
  AMOUNT_TOO_HIGH = 'AMOUNT_TOO_HIGH'
}
