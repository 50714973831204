import cloneDeep from 'lodash-es/cloneDeep';
import merge from 'lodash-es/merge';
import { toRaw } from 'vue';

import { Prospect } from '@/api/prospect/types';
import { useCoupon } from '@/composables/app/useCoupon';
import { useCrossSell } from '@/composables/app/useCrossSell';
import { useMortgageSavingAmount } from '@/composables/app/useMortgageSavingAmount';
import { usePartner } from '@/composables/app/usePartner';
import { useRecaptcha } from '@/composables/app/useRecaptcha';
import { useUser } from '@/composables/app/useUser';
import { UserPathEnum } from '@/composables/app/useUser/useUser.types';
import { useContract } from '@/composables/states/useContract/useContract';
import { useExtraInfo } from '@/composables/states/useExtraInfo/useExtraInfo';
import { VALID_EXTRA_INFO_PATHS } from '@/composables/states/useExtraInfo/useExtraInfo.types';
import { useProspect } from '@/composables/states/useProspect';
import { useQuote } from '@/composables/states/useQuote';
import { useUnleash } from '@/composables/states/useUnleash';
import { useMobileApp } from '@/composables/utils/useMobileApp';
import { i18n } from '@/i18n/index';
import { usePaymentMethod } from '@/modules/payment/composables/usePaymentMethod';
import { useSignature } from '@/modules/signature/composables/useSignature';

export const getEventData = () => {
  const { prospectData } = useProspect();
  const { currentQuote } = useQuote();
  const { partnerName } = usePartner();
  const { getUserData, isLoggedIn } = useUser();
  const { signatureData } = useSignature();
  const { currentPaymentMethodDetails, currentPaymentMethodId, isExistingMethod } =
    usePaymentMethod();
  const { landedWithCrossSell, getCurrentTemplateId } = useCrossSell();
  const { isMobileApp } = useMobileApp();
  const { couponData, isReferral } = useCoupon();
  const { recaptchaData } = useRecaptcha();
  const { mortgageSavings, isMortgageSavingAmountVisible } = useMortgageSavingAmount();
  const { contractData } = useContract();
  const { extraInfos, getExtraInfo } = useExtraInfo();
  const { flags } = useUnleash();

  let guarantees_list: undefined | string[] = undefined;
  if (currentQuote?.currentPackage) {
    guarantees_list = [
      ...currentQuote.currentPackage.mandatory_bundles.map((b) => b.code),
      ...currentQuote.currentPackage.optional_bundles.filter((b) => b.subscribed).map((b) => b.code)
    ];
  }

  const prospectDataRaw = cloneDeep(toRaw(prospectData));
  const cleanedProspect = cleanProspectForTracking(cloneDeep(prospectDataRaw));

  return merge(cleanedProspect, {
    contract: {
      contract_id: getUserData(UserPathEnum.contractId)
    },
    coupon: isReferral.value ? 'referal' : prospectDataRaw.coupon,
    experiment: {
      flags: toRaw(flags.value),
      partner_test: {
        group: currentQuote?.funnel_segmentation_group,
        set: currentQuote?.funnel_segmentation_set,
        version: currentQuote?.funnel_segmentation_version
      }
    },
    other_info: { ...prospectDataRaw?.other_info, ...contractData.other_info, ...extraInfos },
    page: {
      lang: i18n.global.locale,
      webview: isMobileApp.value
    },
    partner: {
      name: partnerName?.value
    },
    payment: {
      payment_method: currentPaymentMethodId.value
        ? currentPaymentMethodDetails.value.type
        : undefined,
      payment_existing: currentPaymentMethodId.value ? isExistingMethod.value : undefined,
      payment_method_id: currentPaymentMethodId.value
        ? currentPaymentMethodDetails.value.id
        : undefined,
      billing_frequency: prospectDataRaw.billing_frequency
    },
    postonboarding: {
      sign_url: prospectDataRaw?.sign_url,
      sign_mode: signatureData.mode,
      cross_sell: {
        template: getCurrentTemplateId(prospectDataRaw),
        landed_with_cross_sell: landedWithCrossSell.value
      },
      source: getExtraInfo(VALID_EXTRA_INFO_PATHS.SOURCE), // to check
      source_rank: getExtraInfo(VALID_EXTRA_INFO_PATHS.SOURCE_RANK) // to check
    },
    pricing: {
      pack: currentQuote?.currentPackage?.code,
      discount_latitude: currentQuote?.discountLatitude || false,
      monthly_price:
        parseFloat(currentQuote?.currentPrice?.monthly?.total?.toFixed(2)) || undefined,
      coupon: {
        value: couponData.amount,
        type: couponData.redeem_type,
        name: couponData.code,
        applied: couponData.applied
      },
      mortgage_saving: {
        display: isMortgageSavingAmountVisible.value,
        value: mortgageSavings.value
      },
      ltv: currentQuote?.commercial?.ltv,
      guarantees_list: guarantees_list || []
    },
    monitoring: {
      truemail: {
        email_checked:
          prospectDataRaw?.email_validated === false && prospectDataRaw?.user?.subscriber?.email,
        email_validated: prospectDataRaw?.email_validated
      },
      botd: {
        result: getExtraInfo(VALID_EXTRA_INFO_PATHS.BOTD_RESULT),
        type: getExtraInfo(VALID_EXTRA_INFO_PATHS.BOTD_TYPE)
      },
      google: {
        recaptcha: toRaw(recaptchaData.value)
      }
    },
    user: {
      logged_in: isLoggedIn.value
    }
  });
};

// Clean prospect properties before to send it to Lukosiris
const cleanProspectForTracking = (prospect: Prospect) => {
  delete prospect?.intent;
  delete prospect?.billing_frequency;
  return prospect;
};

export const getUserData = () => {
  const { prospectData } = useProspect();
  const { getUserData } = useUser();
  const { getExtraInfo } = useExtraInfo();

  const amplitudeInstance = Object.keys(window.amplitude._instances)[1];

  const payload = {
    email: prospectData?.user?.subscriber?.email,
    firstname: prospectData?.user?.subscriber?.first_name,
    lastname: prospectData?.user?.subscriber?.last_name,
    // gender: we don't have it for now
    address: prospectData?.home?.main_asset?.address_main,
    zipcode: prospectData?.home?.main_asset?.postcode,
    device_id: window.amplitude._instances[amplitudeInstance].options.deviceId,
    city: prospectData?.home?.main_asset?.city,
    country_code: prospectData?.home?.main_asset?.country_code,
    phone: prospectData?.user?.subscriber?.phone,
    birth_year: getUserData(UserPathEnum.birth_date)?.split('-')[0] || null,
    birth_date: getUserData(UserPathEnum.birth_date),
    id: getUserData(UserPathEnum.userId),
    has_provided_phone: !!prospectData.user?.subscriber?.phone,
    email_domain: prospectData?.user?.subscriber?.email?.split('@').slice(-1)?.[0],
    utm: getExtraInfo(VALID_EXTRA_INFO_PATHS.UTM_PARAMS),
    uuid: getUserData(UserPathEnum.uuid)
  };

  return payload;
};
