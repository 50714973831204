import { Prospect } from '@/api/prospect/types';

export interface CrossSellRestrictions {
  id: string;
  conditions: { (prospect: Prospect): boolean }[];
}

export enum CrossSellTemplateId {
  // FR
  FR_HOME__FR_MORTGAGE = 'FR_HOME__FR_MORTGAGE',
  FR_LANDLORD__FR_MORTGAGE = 'FR_LANDLORD__FR_MORTGAGE',
  FR_ESCOOTER__FR_HOME = 'FR_ESCOOTER__FR_HOME',
  FR_ESCOOTER__FR_MORTGAGE = 'FR_ESCOOTER__FR_MORTGAGE',
  FR_SECONDARY__FR_MORTGAGE = 'FR_SECONDARY__FR_MORTGAGE',
  FR_LANDLORD__FR_SECONDARY = 'FR_LANDLORD__FR_SECONDARY',
  FR_LANDLORD__FR_HOME = 'FR_LANDLORD__FR_HOME',
  FR_SECONDARY__FR_HOME = 'FR_SECONDARY__FR_HOME'
}

export enum CrossSellSliceTemplate {
  CAROUSEL = 'crosssellcarousel',
  DEFAULT = 'locale' // this is the id of the template in prismic, not sure if it's modifiable without breaking cross sells
}
