import QuoteService from '@/api/quote';

export const pricingCallGuard = async (to, from, next) => {
  const isFromPricing = from.hash.includes('Pricing') || from.hash.includes('Payment');
  const isToNonPricing = !to.hash.includes('Pricing') && !to.hash.includes('Payment');

  if (isFromPricing && isToNonPricing) {
    QuoteService.abortQuote();
  }
  return next();
};
