<script lang="ts" setup>
import { load } from '@fingerprintjs/botd';
import { captureException } from '@sentry/minimal';
import { useHead } from '@vueuse/head';
import { computed, defineAsyncComponent, onMounted, onUnmounted } from 'vue';

import Loader from '@/components/Loader';
import InfoBox from '@/components/popups/InfoBox';
import LoginPopup from '@/components/popups/LoginPopup';
import PopupError from '@/components/popups/PopupError';
import RecaptchaOverlay from '@/components/RecaptchaOverlay';
import { useRecaptcha } from '@/composables/app/useRecaptcha';
import { useSteps } from '@/composables/app/useSteps';
import { useExtraInfo } from '@/composables/states/useExtraInfo';
import { VALID_EXTRA_INFO_PATHS } from '@/composables/states/useExtraInfo/useExtraInfo.types';
import { useQuote } from '@/composables/states/useQuote';
import { useSession } from '@/composables/states/useSession';
import { useState } from '@/composables/states/useState';
import { useEnv } from '@/composables/utils/useEnv';
import { useError } from '@/composables/utils/useError';
import { useIntercom } from '@/composables/utils/useIntercom';
import { useMobileApp } from '@/composables/utils/useMobileApp';
import { DEFAULT_LOCALE } from '@/i18n/locales';

import { useUnleash } from './composables/states/useUnleash';

const Prefill = defineAsyncComponent(() => import('@/components/Prefill'));

const { stateData } = useState();

const { isMobileApp } = useMobileApp();
const { initSession } = useSession();
const { isDev, isE2E } = useEnv();
const { isIntercomLoaded } = useIntercom();
const { errorData } = useError();
const { setExtraInfo } = useExtraInfo();
const { doQuote } = useQuote();
const { isPricingStep } = useSteps();
const { initUnleashListeners } = useUnleash();

useHead({
  htmlAttrs: [{ lang: DEFAULT_LOCALE }]
});

stateData.value.infoData = undefined;
const infoData = computed(() => stateData.value.infoData);

const loadingData = computed(() => stateData.value.loadingData);
const loadingLocale = computed(() => stateData.value.loadingLocale);

const isLoginPopupOpen = computed(() => !!stateData.value.loginCallback);
const showPrefill = computed(() => isDev);
const { displayRecaptcha } = useRecaptcha();

onMounted(() => {
  initSession();
  window.dataLayer?.push({ event: 'optimize.mounted' });
  if (!isE2E.value) {
    initUnleashListeners();
    const botdPromise = load();
    botdPromise
      .then((botd) => botd.detect())
      .then((result) => {
        setExtraInfo(VALID_EXTRA_INFO_PATHS.BOTD_RESULT, result.bot);
        setExtraInfo(VALID_EXTRA_INFO_PATHS.BOTD_TYPE, result?.botKind);
      })
      .catch((error) => captureException(error));
  }
  window.addEventListener('load', () => {
    isIntercomLoaded.value = true;
  });
});

onUnmounted(() => {
  window.removeEventListener('load', () => {
    isIntercomLoaded.value = true;
  });
});
</script>

<template>
  <div data-luko-tracking="App-Onboarding" :class="[{ app: true, 'mobile-App': isMobileApp }]">
    <transition name="fade-down" mode="out-in">
      <InfoBox v-if="infoData" />
    </transition>
    <router-view />
    <LoginPopup v-if="isLoginPopupOpen" />
    <PopupError v-if="!!errorData" />
    <transition name="fade" mode="out-in">
      <Loader v-if="!displayRecaptcha && (loadingData || loadingLocale)">
        <template #title>
          {{ loadingData?.title || null }}
        </template>
        <template #description>
          {{ loadingData?.description || null }}
        </template>
      </Loader>
    </transition>
    <Prefill v-if="showPrefill" />
    <RecaptchaOverlay v-if="isPricingStep && displayRecaptcha" @submit="doQuote" />
  </div>
</template>

<style lang="scss">
* {
  box-sizing: border-box;

  font-family: 'CircularXX', 'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Arial,
    sans-serif;

  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility !important;

  &::placeholder {
    color: $gray-400;
  }
}

input:not[type='number'] {
  -webkit-appearance: none !important;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;

  font-family: 'CircularXX', 'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Arial,
    sans-serif;

  background: white;

  font-feature-settings: 'liga' off;
  & > img {
    position: absolute;
  }
}

@include below(small) {
  .only-computer {
    display: none !important;
  }
}
@include above(small) {
  .only-mobile {
    display: none !important;
  }
}

@include below(small) {
  iframe.intercom-launcher-frame,
  .intercom-lightweight-app {
    display: none !important;
  }
  #intercom-container .intercom-messenger-frame {
    height: 100%;
  }
}

#AWIN_CDT {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -999;
}
.w-full {
  width: 100%;
}
</style>
