import { i18n } from '../../i18n/index';

type FormatCentsNumberReturnType<T extends boolean> = T extends true ? string : number | null;

const formatCentsNumber = <T extends boolean>(
  cents: number | null,
  currencyFormat: T = false as T
): FormatCentsNumberReturnType<T> => {
  if (!cents) {
    return null as FormatCentsNumberReturnType<T>;
  }
  const number = cents / 100;
  return currencyFormat
    ? (i18n.global.n(number, 'currency') as FormatCentsNumberReturnType<T>)
    : (number as FormatCentsNumberReturnType<T>);
};

const formatNumberToCents = (number: number): number => {
  return number * 100;
};

export const useNumber = () => ({
  formatCentsNumber,
  formatNumberToCents
});
