import { captureException } from '@sentry/minimal';
import { load } from 'recaptcha-v3';
import { computed, ref } from 'vue';

import RecaptchaService from '@/api/recaptcha';
import { useProspect } from '@/composables/states/useProspect';
import { useState } from '@/composables/states/useState';
import { useEnv } from '@/composables/utils/useEnv';

import { IRecaptchaData, RecaptchaActions } from './useRecaptcha.types';

const { PROSPECT_DATA_PATH, getProspectData } = useProspect();
const recaptchaData = ref<undefined | IRecaptchaData>(undefined);
const { stateData } = useState();
const { isProd } = useEnv();
const displayRecaptcha = computed(() => {
  return (
    import.meta.env.VITE_RECAPTCHA_V2_SITE_KEY &&
    isProd &&
    !stateData.value.checkHumanConfirmed &&
    recaptcha.value &&
    !!recaptchaData.value?.score &&
    recaptchaData.value?.score < 0.8
  );
});

const recaptcha = ref();

const getToken = async (action: string) => {
  try {
    if (!recaptcha.value) {
      recaptcha.value = await load(import.meta.env.VITE_RECAPTCHA_SITE_KEY, {
        autoHideBadge: true
      });
    }
    const token = await recaptcha.value.execute(action);

    return token;
  } catch (error) {
    captureException(new Error('Recaptcha Error'));

    return getProspectData<string>(PROSPECT_DATA_PATH.recaptcha.token);
  }
};

const checkStep = async (stepName: string) => {
  try {
    const token = await getToken(RecaptchaActions.CHANGING_STEP);
    const data = await RecaptchaService.recaptcha(token, stepName);
    recaptchaData.value = data;
  } catch (error) {
    captureException(new Error('Recaptcha Error'));
  }
};

export const useRecaptcha = () => {
  return {
    getToken,
    checkStep,
    recaptchaData,
    recaptcha,
    displayRecaptcha
  };
};
