export enum HomeFloorLevelEnum {
  TOP = 'top',
  MIDDLE = 'middle',
  GROUND = 'ground'
}

export enum HomeConstructionPeriodEnum {
  UNKNOWN = 'Unknown',
  BEFORE_1960 = 'before 1960',
  YEARS_1960_1979 = '1960-1979',
  YEARS_1980_1999 = '1980-1999',
  YEARS_2000_2015 = '2000-2015',
  AFTER_2015 = 'after 2015',
  MISSING = 'Missing'
}

export enum HomeTypeEnum {
  HOUSE = 'house',
  FLAT = 'flat',
  OTHER = 'otherType'
}

export enum HomeOccupantTypeEnum {
  OWNER = 'owner',
  TENANT = 'tenant'
}

export enum HomeUsageTypeEnum {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LANDLORD = 'landlord'
}

export enum HomeOwnerTypeEnum {
  LANDLORD = 'landlord',
  LANDLORD_FOR_TENANT = 'landlord_for_tenant'
}

export enum HomeValuableTypeEnum {
  JEWELS = 'jewels',
  ART = 'art',
  MUSIC = 'music',
  DEVICE = 'device',
  OTHER = 'other'
}

export enum HomeInstallationEnum {
  SOLAR_PANELS = 'solarPanels',
  VERANDA = 'veranda',
  SWIMMING_POOL = 'swimmingPool',
  GARAGE = 'garage'
}

export enum HomeApplianceEnum {
  FIRE_ALARM = 'fire_alarm',
  THEFT_ALARM = 'theft_alarm',
  CHIMNEY = 'chimney',
  SOLAR_PANELS = 'solar_panels',
  VERANDA = 'veranda',
  SWIMMING_POOL = 'swimmingpool'
}

export enum HomeFurnishedStatusEnum {
  FURNISHED = 'furnished',
  NOT_FURNISHED = 'not_furnished',
  MISSING = 'missing'
}
