import { computed } from 'vue';

import { EnvEnum } from './useEnv.types';

const isDev = import.meta.env.VITE_IS_DEV === 'true';
const isProd = import.meta.env.VITE_IS_DEV === 'false';
const isHostingEnv = import.meta.env.VITE_AMPLIFY === 'true';
const currentEnv = isDev ? EnvEnum.DEV : EnvEnum.PROD;

const isE2E = computed(() => window.sessionStorage.getItem('is_e2e') === 'true');
const devEnvForced = computed(() => window.localStorage.getItem('forceDev'));

export const useEnv = () => ({
  isDev,
  isProd,
  currentEnv,
  isHostingEnv,
  devEnvForced,
  isE2E
});
