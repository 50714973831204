import { captureException } from '@sentry/minimal';

import ContractService from '@/api/contract';
import { ContractResponse } from '@/api/contract/contract.types';
import { BundleOptionsEnum } from '@/composables/app/useBundles/useBundles.types';
import { useCoupon } from '@/composables/app/useCoupon';
import { useNavigation } from '@/composables/app/useNavigation';
import { useUser } from '@/composables/app/useUser';
import { UserPathEnum } from '@/composables/app/useUser/useUser.types';
import { useProspect } from '@/composables/states/useProspect';
import { useState } from '@/composables/states/useState';
import { useError } from '@/composables/utils/useError';
import { TerminationReason } from '@/constants/housing';
import { HomeOccupantTypeEnum } from '@/types/Home';
import { ProductEnum } from '@/types/Product';
import { UserRoleEnum } from '@/types/User';

const {
  setProspectData,
  getProspectData,
  resetProspect,
  PROSPECT_DATA_PATH,
  removePersistedProspect
} = useProspect();
const { MOVING_WITH_LUKO } = TerminationReason;
const { fetchCoupon } = useCoupon();
const { setUserData } = useUser();
const { stateData } = useState();
const { isUnauthorizedError, handleUnauthorizedError } = useError();
const { isOneTimeBypass } = useNavigation();

const updateTermination = (): void => {
  setProspectData(PROSPECT_DATA_PATH.termination_reason, MOVING_WITH_LUKO);
  setProspectData(PROSPECT_DATA_PATH.need_termination, false);
};

const updateUser = (contract: ContractResponse): void => {
  const user = contract.users.find(
    (user) => user.role === UserRoleEnum.SUBSCRIBER || user.role === UserRoleEnum.MAIN_BENEFICIARY
  );
  if (user) {
    setUserData(UserPathEnum.email, user.email);
    setUserData(UserPathEnum.first_name, user.first_name);
    setUserData(UserPathEnum.last_name, user.last_name);
    setUserData(UserPathEnum.birth_date, user.birth_date);
    setUserData(UserPathEnum.phone, user.phone);
    setUserData(UserPathEnum.birth_country_code, user.birth_country_code);
  }
};

const updateContractOptions = (contract: ContractResponse): void =>
  contract.options.forEach((option) => {
    if (option.subscribed) {
      if (!getProspectData(PROSPECT_DATA_PATH.optional_bundles))
        setProspectData(PROSPECT_DATA_PATH.optional_bundles, []);

      setProspectData(PROSPECT_DATA_PATH.optional_bundles, [
        ...getProspectData(PROSPECT_DATA_PATH.optional_bundles),
        option.tracking_code
      ]);
    }
  });

const updateChildren = (contract: ContractResponse): void => {
  const children = contract.users
    .filter((user) => user.role === UserRoleEnum.CHILD)
    .map((child) => ({
      birth_date: child.birth_date,
      first_name: child.first_name,
      last_name: child.last_name
    }));

  if (!getProspectData(PROSPECT_DATA_PATH.user_object)) {
    setProspectData(PROSPECT_DATA_PATH.user_object, {});
  }

  if (children) {
    setProspectData(PROSPECT_DATA_PATH.user_object, {
      ...getProspectData<object>(PROSPECT_DATA_PATH.user_object),
      child: [...children]
    });
  }
};

const updateValuables = (contract: ContractResponse): void => {
  setProspectData(PROSPECT_DATA_PATH.content_value, contract.home?.content_value);

  const valuables: { type: string; value: number }[] = [];

  contract.valuables.forEach(({ type, value }) => {
    valuables.push({
      type,
      value
    });
  });
  if (valuables.length > 0) {
    setProspectData(PROSPECT_DATA_PATH.optional_bundles, [
      ...getProspectData(PROSPECT_DATA_PATH.optional_bundles),
      BundleOptionsEnum.FR_VALUABLES
    ]);
  }
  setProspectData(PROSPECT_DATA_PATH.valuable_list, valuables);
};

const updateOccupantOwnerType = (contract: ContractResponse): void => {
  if (contract.home?.occupant_type === HomeOccupantTypeEnum.TENANT) {
    setProspectData(PROSPECT_DATA_PATH.occupant_type, HomeOccupantTypeEnum.TENANT);
    setProspectData(PROSPECT_DATA_PATH.is_main, contract.home?.is_main);
  } else if (contract.home?.occupant_type === HomeOccupantTypeEnum.OWNER) {
    setProspectData(PROSPECT_DATA_PATH.occupant_type, HomeOccupantTypeEnum.OWNER);
    setProspectData(PROSPECT_DATA_PATH.owner_type, contract.home?.occupant_type);
    setProspectData(PROSPECT_DATA_PATH.is_main, contract.home?.is_main);
  }
};

export const movingQueryGuard = async (to, from, next) => {
  const movingContractId = to.query.moving;

  if (movingContractId)
    try {
      const contract = await ContractService.getContract(movingContractId);

      // Update state data
      isOneTimeBypass.value = true;
      stateData.value.continueFlowCallback = null;

      // Update ProspectData
      removePersistedProspect();
      resetProspect();
      updateTermination();
      updateUser(contract);
      setProspectData(PROSPECT_DATA_PATH.deductible, contract.deductible);
      setProspectData(PROSPECT_DATA_PATH.moving_contract_id, parseInt(movingContractId));
      const product = ['FR_HOME_FLAT', 'FR_HOME_HOUSE', 'FR_SECONDARY'].includes(
        contract.product_code
      )
        ? ProductEnum.FR_HOME
        : contract.product_code;
      setProspectData(PROSPECT_DATA_PATH.product, product);

      // Update Contract Info for FR_HOME
      if (product === ProductEnum.FR_HOME) {
        updateContractOptions(contract);
        updateChildren(contract);
        updateValuables(contract);
        updateOccupantOwnerType(contract);
      }
      if (to?.query?.pc) {
        fetchCoupon(decodeURI(to?.query?.pc));
      }
    } catch (err) {
      if (isUnauthorizedError(err)) {
        return handleUnauthorizedError(async () => movingQueryGuard(to, from, next), true);
      }
      // capture unhandled error
      captureException(err);
    }

  return next();
};
