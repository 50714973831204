import { captureException } from '@sentry/minimal';
import { computed, Ref, ref, watch } from 'vue';

import PaymentService from '@/api/payment';
import { usePartner } from '@/composables/app/usePartner';
import { useUser } from '@/composables/app/useUser';
import { useProspect } from '@/composables/states/useProspect';
import { useQuote } from '@/composables/states/useQuote';
import { i18n } from '@/i18n';
import { PaymentFrequencyEnum, PaymentTypeEnum } from '@/types/Payment';

const { isLoggedIn } = useUser();
const { prospectData } = useProspect();
const { isAggregator } = usePartner();
const { currentQuote } = useQuote();

const isPaymentMethodFetched = ref(false);
const isFetchingPaymentMethods = ref(false);
const existingMethods = ref<{ value: string; wording: string }[]>([]);
const currentPaymentMethodId: Ref<string | null> = ref(null);
const hasExisitingPaymentMethods = computed(() => existingMethods.value.length > 0);
const isYearlyPayment = computed(
  () => prospectData.billing_frequency === PaymentFrequencyEnum.YEARLY
);

const paymentFrequency = computed(() => prospectData.billing_frequency);

const fixOptions = computed(() => [
  {
    value: `${PaymentTypeEnum.CARD}-new`,
    wording: hasExisitingPaymentMethods.value
      ? i18n.global.t('Payment.Method.newCard')
      : i18n.global.t('Payment.Method.card')
  },
  {
    value: `${PaymentTypeEnum.DEBIT}-new`,
    wording: hasExisitingPaymentMethods.value
      ? i18n.global.t('Payment.Method.newDebit')
      : i18n.global.t('Payment.Method.debit')
  }
]);

const fetchPaymentMethods = async () => {
  if (isPaymentMethodFetched.value) {
    return;
  }
  existingMethods.value = [];
  if (!isLoggedIn.value) {
    currentPaymentMethodId.value = `${PaymentTypeEnum.CARD}-new`;
    return;
  }
  isPaymentMethodFetched.value = true;
  isFetchingPaymentMethods.value = true;
  try {
    const methods = await PaymentService.getPaymentMethod();
    if (methods) {
      existingMethods.value = methods.map((item) => {
        if (item.is_default) {
          currentPaymentMethodId.value = `${item.type}-${item.id}`;
        }
        const wording =
          item.type === 'card'
            ? `${i18n.global.t('Payment.Method.options.card')} ${item.card_brand} - ${item.last4}`
            : `${i18n.global.t('Payment.Method.options.debit')} - ${item.masked_iban.slice(0, 5)} ${
                item.last4
              }`;
        return {
          value: `${item.type}-${item.id}`,
          wording
        };
      });
      if (!currentPaymentMethodId.value && methods[0]) {
        currentPaymentMethodId.value = `${methods[0].type}-${methods[0].id}`;
      }
    }
  } catch (err) {
    window.Logger.$log(err);
    captureException(err);
  }

  if (!currentPaymentMethodId.value) {
    currentPaymentMethodId.value = `${PaymentTypeEnum.CARD}-new`;
  }
  isFetchingPaymentMethods.value = false;
};

const paymentMethods = computed(() =>
  isFetchingPaymentMethods.value ? [] : existingMethods.value.concat(fixOptions.value)
);

const isNewCard = computed(
  () =>
    currentPaymentMethodDetails.value?.type === PaymentTypeEnum.CARD &&
    currentPaymentMethodDetails.value?.id === 'new'
);
const isNewSepa = computed(
  () =>
    currentPaymentMethodDetails.value?.type === PaymentTypeEnum.DEBIT &&
    currentPaymentMethodDetails.value?.id === 'new'
);

const isExistingMethod = computed(() => currentPaymentMethodDetails.value?.id !== 'new');

const currentPaymentMethodDetails = computed(() => {
  const splitted = currentPaymentMethodId.value?.split('-');
  if (splitted && splitted.length > 1) {
    return { id: splitted[1], type: splitted[0] };
  }
  return {};
});

const displayYearlyPricing = computed(() => isYearlyPayment.value && isAggregator.value);
const displayMonthlyDiscounted = computed(
  () => !isAggregator.value && !!currentQuote.discountLatitude && isYearlyPayment.value
);

watch(paymentFrequency, (newValue, oldValue) => {
  if (oldValue !== newValue && oldValue) {
    window.LukoTracking.trackEvent({
      id: 'Billing Frequency Updated'
    });
  }
});

export const usePaymentMethod = () => ({
  fetchPaymentMethods,
  paymentMethods,
  currentPaymentMethodId,
  currentPaymentMethodDetails,
  isFetchingPaymentMethods,
  hasExisitingPaymentMethods,
  isNewCard,
  isNewSepa,
  isExistingMethod,
  isYearlyPayment,
  paymentFrequency,
  displayYearlyPricing,
  displayMonthlyDiscounted,
  isPaymentMethodFetched
});
