import { captureException } from '@sentry/minimal';
import get from 'lodash-es/get';
import merge from 'lodash-es/merge';
import set from 'lodash-es/set';

import ProspectService from '@/api/prospect';
import { useCoupon } from '@/composables/app/useCoupon';
import { useCrossSell } from '@/composables/app/useCrossSell';
import { useNavigation } from '@/composables/app/useNavigation';
import { RestartReason } from '@/composables/app/useNavigation/useNavigation.type';
import { useProduct } from '@/composables/app/useProduct';
import { useSteps } from '@/composables/app/useSteps';
import { useUser } from '@/composables/app/useUser';
import { UserPathEnum } from '@/composables/app/useUser/useUser.types';
import { useExtraInfo } from '@/composables/states/useExtraInfo';
import { useProspect } from '@/composables/states/useProspect';
import { useState } from '@/composables/states/useState';
import { useCookies } from '@/composables/utils/useCookies';
import { useLoader } from '@/composables/utils/useLoader';
import { useStorage } from '@/composables/utils/useStorage';
import { TerminationReason } from '@/constants/housing';
import { ClaimHistoryEnum } from '@/types/Claim';
import { CountryCodeEnum } from '@/types/Country';
import { ProductEnum, ProductToParamEnum } from '@/types/Product';

const { SWITCHING } = TerminationReason;
const exclusionFromParse = [
  '.main_asset.postcode',
  '.main_asset.claim_history',
  'user.subscriber.phone'
];
const { fetchCoupon } = useCoupon();

export const prospectQueryGuard = async (to, from, next) => {
  const { path, query, hash } = to;
  const {
    prospectData,
    getProspectData,
    setProspectData,
    removePersistedProspect,
    getPersistedProspect,
    PROSPECT_DATA_PATH
  } = useProspect();
  const { isNonPaymentProduct } = useProduct();
  const { resetState, stateData } = useState();
  const { checkIsPostProspect, checkIsPricingStep } = useSteps();
  const { restartOnboard, isBypass } = useNavigation();
  const { getUserData, setUserData, isLoggedIn, jwtToken } = useUser();
  const { getFromLocalStorage, setInLocalStorage, removeFromLocalStorage } = useStorage();
  const { mergeCookie, CookieNamesEnum } = useCookies();
  const { cherryPickForCrossSell } = useCrossSell();
  const { launchLoader } = useLoader();
  const { restoreExtraInfoFromProspect } = useExtraInfo();

  if (Object.keys(prospectData).length) return next();
  const addressKeys = [
    '.address_latitude',
    '.address_longitude',
    '.address_main',
    '.city',
    '.country',
    '.country_code',
    '.postcode'
  ];
  let prospectObject = {};

  if (query.prospect) {
    try {
      prospectObject = JSON.parse(decodeURI(query.prospect));
    } catch {
      // TODO: show an error modal "unfortunately we couldn't prefill your information"
      window.Logger.$log('Invalid JSON in prospect query parameter');
    }
  } else {
    Object.keys(query).forEach((key) => {
      if (key.startsWith('$.')) {
        let value = query[key];
        key = key.replace('$.', '');

        if (['true', 'false', 'null'].includes(value)) {
          value = JSON.parse(value);
        } else if (!exclusionFromParse.find((k) => key.endsWith(k))) {
          value = Number(value) || value;
        }

        set(prospectObject, key, value);

        // Set address info into user.subscriber
        const addressKey = addressKeys.find((k) => key.endsWith(k));
        if (addressKey) {
          set(prospectObject, `user.subscriber${addressKey}`, value);
        }
      }
    });
  }

  let prospectFromApi = {};
  const queryProspectKey = query.pk || prospectObject.key;
  try {
    const isPostProspectStep = checkIsPostProspect(hash?.replace('#', ''));
    const isPricingStep = checkIsPricingStep(hash?.replace('#', ''));
    const persistedProspect = getPersistedProspect();
    const localProspectKey = getFromLocalStorage('prospect_key');
    // Query Key
    if (queryProspectKey && queryProspectKey.length > 10) {
      removePersistedProspect();
      resetState();
      launchLoader(true);
      setInLocalStorage('prospect_key', queryProspectKey);
      prospectFromApi = await ProspectService.getProspectByKey(queryProspectKey);
    } else if (persistedProspect) {
      isBypass.value = false;
      prospectFromApi = persistedProspect;
      stateData.value.continueFlowCallback = true;
    }
    // Local Key for pricing steps
    else if (isPostProspectStep) {
      if (localProspectKey) {
        prospectFromApi = await ProspectService.getLatestProspectByKey(localProspectKey);
      } else {
        // if no local key on pricing steps we reset
        return !isPricingStep ? next() : restartOnboard(RestartReason.MISSING_PK_FOR_PRICING);
      }
      stateData.value.continueFlowCallback = null;
    } else if (query.cross_sell && localProspectKey) {
      resetState();
      const prospectAPI = await ProspectService.getLatestProspectByKey(localProspectKey);
      prospectFromApi = cherryPickForCrossSell(prospectObject, prospectAPI);
      removeFromLocalStorage(['prospect_key']);
      if (!prospectFromApi) {
        return restartOnboard(RestartReason.MISSING_PROSPECT_CROSS_SELL);
      }
    }
    // Other step we reset the pk
    else if (!path.includes('sign') && !hash.includes('Signature')) {
      removeFromLocalStorage(['prospect_key']);
    }
  } catch (error) {
    if (error.response?.status === 404 || error.status === 404) {
      removeFromLocalStorage(['prospect_key']);
      mergeCookie(CookieNamesEnum.LK_CMS, { pk: undefined });
      return next();
    }
    captureException(error);
  }

  // We overwrite backend data to be consistent with the URL query string
  delete prospectObject.key;
  merge(prospectFromApi, prospectObject);
  prospectObject = prospectFromApi;

  // prospectData adaptation
  if (queryProspectKey) {
    const country = get(prospectObject, 'home.main_asset.country');
    if (country in CountryCodeEnum) {
      set(prospectObject, 'home.main_asset.country_code', CountryCodeEnum[country]);
    }
    if (
      prospectObject.need_termination &&
      prospectObject.termination_reason === SWITCHING &&
      !prospectObject.termination_subscription_date
    ) {
      stateData.value.skipExistingInsuranceDateForAggregs = true;
    }
    if (
      !prospectObject.claim_history ||
      prospectObject.claim_history === ClaimHistoryEnum.MISSING
    ) {
      stateData.value.skipClaimHistoryForAggregs = true;
    }
  }

  // Override userData ONLY non-logged user
  if (isLoggedIn.value) {
    if (
      prospectObject.user?.subscriber?.email &&
      getUserData(UserPathEnum.email) !== prospectObject.user?.subscriber?.email
    ) {
      jwtToken.value = null;
      setUserData(UserPathEnum.email, prospectObject.user?.subscriber.email);
    }
  } else {
    setUserData(UserPathEnum.email, prospectObject.user?.subscriber.email);
    setUserData(UserPathEnum.first_name, prospectObject.user?.subscriber.first_name);
    setUserData(UserPathEnum.last_name, prospectObject.user?.subscriber.last_name);
    setUserData(UserPathEnum.phone, prospectObject.user?.subscriber.phone);
    setUserData(UserPathEnum.birth_date, prospectObject.user?.subscriber.birth_date);
    setUserData(UserPathEnum.gender, prospectObject.user?.subscriber.gender);
    setUserData(
      UserPathEnum.birth_country_code,
      prospectObject.user?.subscriber.birth_country_code
    );
  }

  // Merge ProspectObject into prospectData
  Object.assign(prospectData, prospectObject);

  restoreExtraInfoFromProspect(prospectData);

  // translate Old product to LIAG
  if (
    ['FR_HOME_FLAT', 'FR_HOME_HOUSE', 'FR_SECONDARY'].includes(
      getProspectData(PROSPECT_DATA_PATH.product)
    )
  ) {
    setProspectData(PROSPECT_DATA_PATH.product, ProductEnum.FR_HOME);
  }
  if (
    ['FR_LANDLORD_HOUSE', 'FR_LANDLORD_FLAT'].includes(getProspectData(PROSPECT_DATA_PATH.product))
  ) {
    setProspectData(PROSPECT_DATA_PATH.product, ProductEnum.FR_LANDLORD);
  }

  if ((prospectData.key && queryProspectKey) || [query.pricing, query.go_fast].includes('true')) {
    if (query.payment && !isNonPaymentProduct.value) {
      stateData.value.skipPricing = true;
    }
    stateData.value.shouldGoFast = true;
  }

  // pc stands for promo code
  if (query.pc) {
    fetchCoupon(decodeURI(query.pc));
  }

  const product = ProductToParamEnum[getProspectData(PROSPECT_DATA_PATH.product)];

  if (queryProspectKey) to.hash = '';
  if (['pets', 'escooter'].includes(product) && !path.includes(product)) {
    return next({ query, hash: to.hash, path: `/onboard/${product}` });
  }

  return next();
};
