export enum HTTPErrorType {
  BadRequest = 400,
  Unauthorized = 401,
  NotFound = 404,
  RateLimit = 429
}

export enum ErrorResponseType {
  PricingMismatchError = 'PricingMismatchError',
  ComplyAdvantageError = 'ComplyAdvantageError',
  ProspectLocked = 'ProspectLocked',
  ProspectSigned = 'ProspectSigned',
  InvalidZipcodeError = 'InvalidZipcodeError',
  SepaNotAuthorized = 'SepaNotAuthorized',
  SubscriptionInThePastError = 'SubscriptionInThePastError',
  AlreadySignedContractError = 'AlreadySignedContractError',
  AlreadySignedSignatureRequestError = 'AlreadySignedSignatureRequestError',
  AlreadySignedDocumentSignatureRequestError = 'AlreadySignedDocumentSignatureRequestError',
  NoMovingWithLukoInMoreThanAMonth = 'NoMovingWithLukoInMoreThanAMonth',
  NoMovingWithLukoInThePast = 'NoMovingWithLukoInThePast',
  InvalidContentValueError = 'ContentValueMinMaxError',
  CreatedAccount = 'ResponseError', // account creation error when email already exist
  UnauthorizedIPOperator = 'UnauthorizedIPOperator' // dev env only, need to use VPN
}

export enum ProspectErrorType {
  ExcludedTerritoryError = 'ExcludedTerritoryError',
  MissingInputError = 'MissingInputError'
}

export enum PaymentErrorType {
  ActiveMandateAlreadyExisting = 'ActiveMandateAlreadyExisting',
  IntentNotReadyToBeCaptured = 'IntentNotReadyToBeCapturedError'
}

export enum ErrorPopupType {
  LukoDown = 'LukoDown',
  Unauthorized = 'Unauthorized',
  Retry = 'Retry',
  Unhandled = 'Unhandled',
  ProspectLocked = 'ProspectLocked',
  ProspectSigned = 'ProspectSigned',
  ProspectMissingContract = 'ProspectMissingContract',
  ValueError = 'ValueError',
  ComplyAdvantage = 'ComplyAdvantage',
  PricingMismatchError = 'PricingMismatchError',
  InvalidZipcodeError = 'InvalidZipcodeError',
  SepaNotAuthorized = 'SepaNotAuthorized',
  SubscriptionInThePastError = 'SubscriptionInThePastError',
  AlreadySigned = 'AlreadySigned',
  InvalidContentValueError = 'ContentValueMinMaxError',
  CreatedAccount = 'CreatedAccount',
  VpnError = 'VpnError',
  MovingError = 'MovingError',
  StripeError = 'StripeError',
  PdfRenderError = 'PdfRenderError',
  ContentValueLowerThanValuablesError = 'ContentValueLowerThanValuablesError',
  _Old = '_Old' // Old way error handling. Need to replace
}

export interface IErrorData {
  type: ErrorPopupType;
  error?: unknown;
  source?: string;
  code?: string;
  icon?: string;
  title?: string;
  description?: string;
  text?: string;
  onCancel?: (...args: never[]) => unknown;
  onCta?: (...args: never[]) => unknown;
  cta?: string;
  cancel?: string;
  hasIntercom?: boolean;
}

export interface IErrorInfo {
  message?: string | Error;
  cause?: unknown;
  request_url?: string;
  status?: number;
  request?: unknown;
  response?: unknown;
  sentry?: string | undefined;
}

export interface IErrorData {
  type: ErrorPopupType;
  error?: Error;
  code?: string;
  icon?: string;
  title?: string;
  description?: string;
  text?: string;
  onCancel?: (...args: never[]) => unknown;
  onCta?: (...args: never[]) => unknown;
  cta?: string;
  cancel?: string;
  hasIntercom?: boolean;
}
