import axios from 'axios';
import { ref, toRaw } from 'vue';

import PartnerAPI from '@/api/PartnerAPI';
import { Prospect } from '@/api/prospect/types';
import { i18n } from '@/i18n';

import { QuoteResponse } from './Quote.types';

const controllerQuoteCall = ref<AbortController>();

export default {
  controllerQuoteCall,
  async createQuote(prospectData: Prospect): Promise<QuoteResponse | null> {
    controllerQuoteCall.value = new AbortController();

    const signal = controllerQuoteCall.value.signal;
    try {
      const { data } = await PartnerAPI.post<QuoteResponse>(
        `/prospect/price?lang=${i18n.global.locale}`,
        toRaw(prospectData),
        {
          signal
        }
      );
      controllerQuoteCall.value = undefined;

      return data;
    } catch (e) {
      if (axios.isCancel(e)) return null;
      controllerQuoteCall.value = undefined;

      return Promise.reject(e);
    }
  },

  abortQuote() {
    if (controllerQuoteCall.value) {
      controllerQuoteCall.value.abort();
    }
  }
};
