import { captureException } from '@sentry/minimal';

import ContractService from '@/api/contract';
import type { ContractCreatedResponse } from '@/api/contract/contract.types';
import { SignatureRequestModeEnum } from '@/api/contract/contract.types';
import PaymentService from '@/api/payment';
import { useContract } from '@/composables/states/useContract/useContract';
import { useProspect } from '@/composables/states/useProspect';
import { useState } from '@/composables/states/useState/useState';
import { useSignature } from '@/modules/signature/composables/useSignature';

import { useError } from './useError';
import { ErrorPopupType } from './useError/useError.types';

const { initBackgroundSignature, signatureData, getDocHash, onSignContract, dispatchContractData } =
  useSignature();
const { prospectData, setProspectData, PROSPECT_DATA_PATH } = useProspect();
const { stateData } = useState();
const { saveContractCreation } = useContract();
const {
  isProspectSignedError,
  isProspectLockedError,
  isUnauthorizedError,
  isComplyAdvantageError,
  handleProspectLocked,
  handleUnauthorizedError,
  showErrorPopup
} = useError();

const quickIntent = async ({
  onSuccess,
  signatureMode,
  intent
}: {
  onSuccess?: (contract: ContractCreatedResponse) => void;
  signatureMode?: SignatureRequestModeEnum;
  intent?: boolean;
} = {}) => {
  try {
    const lockedProspect = await PaymentService.createPaymentIntent(prospectData.key, {
      intent: intent ?? false
    });

    setProspectData(PROSPECT_DATA_PATH.key, lockedProspect.prospectKey);
    stateData.value.paymentDone = true;

    const prospectContract = await ContractService.createContract({
      signatureMode: signatureMode ?? SignatureRequestModeEnum.LUKOSIGN_EMBEDDED,
      prospectKey: lockedProspect.prospectKey
    });
    saveContractCreation(prospectContract);
    dispatchContractData(prospectContract);
    onSuccess?.(prospectContract);
  } catch (err) {
    if (isUnauthorizedError(err)) {
      return handleUnauthorizedError(() => quickIntent({ onSuccess, signatureMode }));
    }
    if (isComplyAdvantageError(err)) {
      return showErrorPopup({ type: ErrorPopupType.ComplyAdvantage, source: 'Luko', err });
    }
    if (isProspectSignedError(err) || isProspectLockedError(err)) {
      return handleProspectLocked(err);
    }
    captureException(err);
  }
};

const quickSign = async (onSuccess?) => {
  // Temporary solution until Signy is ready
  await initBackgroundSignature();
  const response = await fetch(signatureData.url, { method: 'GET' });
  const arrayBuffer = await response.arrayBuffer();
  signatureData.hashedContract = await getDocHash(arrayBuffer);
  await onSignContract();
  onSuccess?.();
};

export const useQuickActions = () => ({
  quickIntent,
  quickSign
});
