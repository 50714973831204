const getMonthlyPrice = (cents: number): { total: number; cents: number; yearTotal: number } => ({
  total: Math.trunc(cents / 12) / 100,
  cents: Math.trunc(cents / 12),
  yearTotal: cents / 100
});

const getYearlyPrice = (cents: number): { total: number; monthly: number; cents: number } => ({
  total: cents / 100,
  monthly: cents / 12 / 100,
  cents
});

const getMonthlyFromYearly = (cents: number): number => Math.trunc(cents / 12) / 100;
const getYearlyPriceFromCents = (cents: number): number => cents / 100;

export const usePriceMethods = () => {
  return {
    getMonthlyPrice,
    getYearlyPrice,
    getMonthlyFromYearly,
    getYearlyPriceFromCents
  };
};
