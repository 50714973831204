import { ref } from 'vue';

import { useCrypto } from '@/composables/utils/useCrypto';
import { useStorage } from '@/composables/utils/useStorage';

const { setInSessionStorage, getFromSessionStorage } = useStorage();
const { uuidv4 } = useCrypto();

const sessionId = ref();

const initSession = () => {
  if (getFromSessionStorage('session_id')) {
    sessionId.value = getFromSessionStorage('session_id');
  } else {
    const id = uuidv4();
    sessionId.value = id;
    setInSessionStorage('session_id', id);
  }
};

export const useSession = () => ({
  sessionId,
  initSession
});
