<script lang="ts" setup>
import { Check, LukoIcon } from '@demain-es/lukompo';
import { computed, ref, watch } from 'vue';

const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  isLast: {
    type: Boolean,
    default: false
  },
  shouldCheck: {
    type: Boolean,
    default: false
  }
});

const progress = ref(0);

const intervalTimer = props.isLast ? 100 : Math.floor(Math.random() * 30) + 20;
const interval = setInterval(() => {
  if (props.isLast && progress.value > 90) return clearInterval(interval);
  if (progress.value < 100) progress.value += 0.5;
  else clearInterval(interval);
}, intervalTimer);

const isComplete = computed(() => progress.value === 100);
const progressStyle = computed(() => progress.value + '%');

watch(
  () => props.shouldCheck,
  () => {
    if (props.shouldCheck) {
      progress.value = 100;
      clearInterval(interval);
    }
  }
);
</script>

<template>
  <div :class="$style.container">
    <p :class="$style.text">{{ text }}</p>
    <div :class="$style.right">
      <div :class="$style.progress" />
      <div :class="$style.iconContainer">
        <transition name="fade-left">
          <LukoIcon v-if="isComplete" :size="12" :svg="Check" />
        </transition>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.container {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.text {
  @include typo-caption;
  color: $gray-1000;
}

.right {
  display: flex;
  gap: 8px;
  align-items: center;
}

.progress {
  position: relative;

  width: 100%;
  width: 100px;
  height: 4px;
  border-radius: 4px;

  background-color: $gray-100;

  &::before {
    position: absolute;
    top: 0;
    left: 0;

    width: v-bind('progressStyle');
    height: 4px;
    border-radius: 4px;

    background-color: $bluko-500;

    transition: 0.3s ease;

    content: '';
  }
}

.iconContainer {
  width: 12px;
  height: 12px;
}
</style>
