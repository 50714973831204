export enum VALID_EXTRA_INFO_PATHS {
  CHURN_DURATION = 'churn_duration',
  SOURCE = 'source',
  SOURCE_RANK = 'source_rank',
  TERMINATION_LESS_A_YEAR = 'termination_less_than_a_year',
  LIVING_SITUATION = 'living_situation',
  UTM_PARAMS = 'utm_params',
  FORMER_INSURANCE_YEARLY_PRICE = 'former_insurance_yearly_price',
  BOTD_RESULT = 'botd_result',
  BOTD_TYPE = 'botd_type',
  EXIT_INVENTORY_DATE = 'exit_inventory_date'
}
