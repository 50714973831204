import { useSteps } from '@/composables/app/useSteps';
import { useProspect } from '@/composables/states/useProspect';
import { useState } from '@/composables/states/useState';
import { useMobileApp } from '@/composables/utils/useMobileApp';
import { useStorage } from '@/composables/utils/useStorage';

export const mobileGuard = (to, from, next) => {
  const { isMobileApp } = useMobileApp();
  const { checkIsPostProspect } = useSteps();
  const { resetProspect } = useProspect();
  const { resetState } = useState();
  const { clearLocalStorage } = useStorage();

  const { webview } = to.query;

  if (webview) {
    isMobileApp.value = true;
  }

  if (isMobileApp.value && from.path == '/' && !checkIsPostProspect(to.hash.replace('#', ''))) {
    clearLocalStorage();
    resetProspect();
    resetState();
  }
  return next();
};
