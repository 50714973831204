import { UserPathEnum } from '@/composables/app/useUser/useUser.types';

import { ValidLocalStoragePaths, ValidSessionStoragePaths } from './useStorage.type';

function getFromStorage<T>(key: string, storage: Storage): T | string | null {
  const result = storage.getItem(key);
  if (result) {
    try {
      return JSON.parse(result) as T;
    } catch (e) {
      return result;
    }
  }
  return null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function setInStorage(key: string, value: any, storage: Storage): void {
  const formattedValue = typeof value === 'object' ? JSON.stringify(value) : value || '';
  storage.setItem(key, formattedValue);
}

function removeFromStorage(keys: string[], storage: Storage): void {
  keys.forEach((key) => storage.removeItem(key));
}

function clearStorage(storage: Storage): void {
  storage.clear();
}

const getFromLocalStorage = <T>(key: ValidLocalStoragePaths | UserPathEnum): T | string | null => {
  return getFromStorage(key, window.localStorage);
};

const getFromSessionStorage = <T>(key: ValidSessionStoragePaths): T | string | null => {
  return getFromStorage(key, window.sessionStorage);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setInLocalStorage = (key: ValidLocalStoragePaths | UserPathEnum, value: any): void => {
  setInStorage(key, value, window.localStorage);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setInSessionStorage = (key: ValidSessionStoragePaths, value: any): void => {
  setInStorage(key, value, window.sessionStorage);
};

const removeFromLocalStorage = (keys: Array<ValidLocalStoragePaths | UserPathEnum>): void => {
  removeFromStorage(keys, window.localStorage);
};

const removeFromSessionStorage = (keys: ValidSessionStoragePaths[]): void => {
  removeFromStorage(keys, window.sessionStorage);
};

const clearLocalStorage = (preserve: Array<ValidLocalStoragePaths | UserPathEnum> = []): void => {
  const stored: Array<{ key: ValidLocalStoragePaths | UserPathEnum; value: unknown }> = [];
  for (const item of preserve) {
    if (getFromLocalStorage(item)) {
      stored.push({ key: item, value: getFromLocalStorage(item) });
    }
  }
  clearStorage(window.localStorage);
  for (const s of stored) {
    setInLocalStorage(s.key, s.value);
  }
};

const clearSessionStorage = (): void => {
  clearStorage(window.sessionStorage);
};

export const useStorage = () => {
  return {
    getFromLocalStorage,
    getFromSessionStorage,
    setInLocalStorage,
    setInSessionStorage,
    removeFromLocalStorage,
    removeFromSessionStorage,
    clearLocalStorage,
    clearSessionStorage
  };
};
