export interface IRecaptchaData {
  'error-codes': string[];
  step_name: string;
  success: boolean;
  score: number;
  challenge_ts: string;
  action: string;
}

export enum RecaptchaActions {
  LANDING_ON_PRICING = 'LANDING_ON_PRICING',
  CHANGING_STEP = 'CHANGING_STEP'
}
