// Composable handling application states with validation to keep track of which states we are using

import { useSessionStorage } from '@vueuse/core';

import { ValidSessionStoragePaths } from '@/composables/utils/useStorage/useStorage.type';

import {
  STATE_DATA,
  STATE_POST_ONBOARDING_CONTROL,
  STATE_STEP,
  STATE_STEP_CONTROL
} from './useState.types';

const stateData = useSessionStorage<STATE_DATA>(ValidSessionStoragePaths.STATE_DATA, {});

const removePersistState = () => {
  stateData.value = {};
};

const resetState = () => {
  for (const key in stateData.value) {
    // don't reset  specific session values
    if ([STATE_STEP_CONTROL.AGGREGATOR_ONE_PAGER, 'loadingData'].includes(key)) continue;
    delete stateData.value[key];
  }
};

const resetStepState = () => {
  Object.values(STATE_STEP).forEach((key) => {
    delete stateData.value[key];
  });
  Object.values(STATE_POST_ONBOARDING_CONTROL).forEach((key) => {
    delete stateData.value[key];
  });
};

export const useState = () => {
  return {
    stateData,
    resetState,
    resetStepState,
    removePersistState
  };
};
