import { CookieNamesEnum, CookieStrategyEnum } from './useCookies.types';

const DOMAIN = '.luko.eu';

function parseCookie(cookies: string) {
  return cookies.split(';').map((cookie) => {
    const [name, value] = cookie.split('=');

    return {
      name: name.trim(),
      value: value?.trim()
    };
  });
}
function getExpires(strategy: CookieStrategyEnum): string {
  if (strategy === CookieStrategyEnum.REMOVE) {
    return 'expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }
  if (strategy === CookieStrategyEnum.SESSION) {
    return '';
  }

  const expiryDate = new Date();
  expiryDate.setTime(expiryDate.getTime() + 365 * 24 * 60 * 60 * 1000);
  return `expires=${expiryDate.toUTCString()}`;
}

function updateCookie(key: string, value: string | undefined, strategy: CookieStrategyEnum): void {
  const cookie = key + '=' + value;
  const expires = getExpires(strategy);

  document.cookie = [cookie, expires, 'path=/', `domain=${DOMAIN}`].filter((e) => e).join(';');
}

const getCookie = (cookieName: string): string | object => {
  const value =
    parseCookie(document.cookie).find((cookie) => cookie.name === cookieName)?.value || '';
  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
};

// `set` update entire value of cookie
const setCookie = (key: string, value: string | object): void => {
  updateCookie(key, JSON.stringify(value), CookieStrategyEnum.ONE_YEAR);
};

// `merge` update only some part of JSON stringified cookie
const mergeCookie = (key: string, value: object): void => {
  const finalValue = getCookie(key);
  if (typeof finalValue !== 'object') {
    return setCookie(key, value);
  }
  Object.assign(finalValue, value);
  setCookie(key, finalValue);
};

const removeCookie = (keys: string[]): void => {
  keys.forEach((key) => updateCookie(key, undefined, CookieStrategyEnum.REMOVE));
};

export const useCookies = () => {
  return {
    getCookie,
    setCookie,
    mergeCookie,
    removeCookie,
    CookieNamesEnum
  };
};
