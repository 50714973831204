import { useCoupon } from '@/composables/app/useCoupon';
import { useProduct } from '@/composables/app/useProduct';
import { useProspect } from '@/composables/states/useProspect';
import { ProductPackages } from '@/types/Product';

const { prospectData, getProspectData, setProspectData, PROSPECT_DATA_PATH } = useProspect();
const {
  isHomeInsurance,
  isEscooter,
  isMortgage,
  isHouse,
  isGli,
  isFrHomeFlat,
  isFrHomeHouse,
  isLandlord
} = useProduct();
const { couponData } = useCoupon();

const cleanEscooterData = () => {
  ['mortgage', 'pet', 'home', 'outbuilding', 'valuable', 'loan'].forEach(
    (key) => delete prospectData[key]
  );
  setProspectData(PROSPECT_DATA_PATH.deductible, 0);
};

const cleanMortgageData = () => {
  ['home', 'pet', 'nvei', 'outbuilding', 'valuable', 'start_date'].forEach(
    (key) => delete prospectData[key]
  );
};

const cleanHomeData = () => {
  ['mortgage', 'pet', 'nvei', 'loan'].forEach((key) => delete prospectData[key]);

  if (isHouse.value) delete prospectData.home?.main_asset.floor_level;
  if (isFrHomeFlat.value || isFrHomeHouse.value) delete prospectData.outbuilding;
  if (isLandlord.value) delete prospectData.valuable;
  if (!isLandlord.value) {
    delete prospectData.user.subscriber.company;
    setProspectData(PROSPECT_DATA_PATH.subscriber.is_sci, false);
  }
};

const cleanGLI = () => {
  delete prospectData.home?.main_asset.type;
};

const cleanAppliances = () => {
  delete prospectData.home?.main_asset.has_chimney;
  delete prospectData.home?.main_asset.has_fire_alarm;
  delete prospectData.home?.main_asset.has_theft_alarm;
  delete prospectData.home?.main_asset.has_solar_panels;
  delete prospectData.home?.main_asset.has_swimmingpool;
  delete prospectData.home?.main_asset.has_veranda;
};

const cleanProspect = () => {
  const product = getProspectData(PROSPECT_DATA_PATH.product) || '';

  if (!getProspectData(PROSPECT_DATA_PATH.subscriber.birth_date)) {
    setProspectData(PROSPECT_DATA_PATH.subscriber.birth_date, undefined);
  }

  if (isEscooter.value) cleanEscooterData();

  if (isMortgage.value) cleanMortgageData();

  if (isHomeInsurance.value) cleanHomeData();

  if (isGli.value) cleanGLI();

  if (!isHomeInsurance.value) cleanAppliances();

  if (
    ![ProductPackages[`${product}_POM`], ProductPackages[`${product}_MINLEG`]].includes(
      getProspectData(PROSPECT_DATA_PATH.package)
    )
  )
    delete prospectData.package;
  if (!couponData.code) delete prospectData.coupon;
};

export const useProspectClean = () => ({
  cleanProspect
});
