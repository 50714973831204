import { cloneDeep } from 'lodash-es';
import { computed, reactive, ref, watch } from 'vue';

import { Prospect } from '@/api/prospect/types';
import { useEnv } from '@/composables/utils/useEnv';
import { ProductEnum } from '@/types/Product';

import { useProspect } from '../useProspect';
import { InitialStateInterface, UnleashFlagKeys } from './useUnleash.types';

const { isDev, devEnvForced, isE2E } = useEnv();
const { prospectData } = useProspect();

const initialState = () => {
  return Object.fromEntries(
    Object.values(UnleashFlagKeys).map((value) => [value, { enabled: false }])
  );
};

const context = ref<{ [key: string]: string }>({});
const localFlagsForced = reactive<InitialStateInterface>({ flags: initialState() });
const unleashFlags = reactive<InitialStateInterface>({
  flags: initialState()
});
const flags = computed(() => unleashFlags.flags);

// Check if a flag is enable
const isFlagEnabled = (key: UnleashFlagKeys) => {
  window.unleash?.isEnabled(key);
  return !!unleashFlags.flags[key].enabled;
};

// Return the variant name for a given flag
const getVariant = (key: UnleashFlagKeys) => {
  if (unleashFlags.flags[key]) {
    window.unleash?.getVariant(key);
    return unleashFlags.flags[key].variant;
  } else {
    window.Logger.$logError('Error: Variant does not exist');
    return null;
  }
};

// Force a flag to a value
const forceFlag = (key: UnleashFlagKeys, value) => {
  if (unleashFlags.flags[key]) {
    unleashFlags.flags[key].enabled = value;
    localFlagsForced.flags[key].enabled = value;
  } else {
    window.Logger.$logError('Error: Feature Flag does not exist');
  }
};

// Force a variant to a value
const forceVariant = (key: UnleashFlagKeys, value: string) => {
  if (unleashFlags.flags[key]) {
    unleashFlags.flags[key].variant = value;
    localFlagsForced.flags[key].variant = value;
  } else {
    window.Logger.$logError('Error: Feature Flag does not exist');
  }
};

// Init Unleash Listeners
const initUnleashListeners = () => {
  updateUnleashContext(prospectData, {});
  window.unleash.on('ready', () => {
    const toggles = window.unleash.getAllToggles();
    unleashFlags.flags = { ...unleashFlags.flags, ...formatUnleashToggles(toggles) };
  });
  window.unleash.on('update', () => {
    const toggles = window.unleash.getAllToggles();
    const initFeature = isDev || devEnvForced.value ? localFlagsForced.flags : initialState();
    unleashFlags.flags = { ...initFeature, ...formatUnleashToggles(toggles) };
  });
};

// Format toggles to match local format
const formatUnleashToggles = (toggles) =>
  Object.fromEntries(
    toggles.map((t) => [t.name, { enabled: t.enabled, variant: t.variant?.name }])
  );

// Reset all flags to initial state;
const resetFlags = () => {
  localFlagsForced.flags = initialState();
  unleashFlags.flags = initialState();
  context.value = {};
};

// Update the context of unleash
const updateUnleashContext = (newProspect: Prospect, oldProspect: Prospect) => {
  let updateContext = false;

  // Product
  if (
    !!newProspect.product &&
    newProspect.product !== oldProspect.product &&
    newProspect.product !== ProductEnum.DEFAULT
  ) {
    context.value = { ...context.value, product: newProspect.product };
    updateContext = true;
  }

  // HomeType
  if (
    !!newProspect.home?.main_asset?.type &&
    newProspect.home?.main_asset?.type !== oldProspect.home?.main_asset?.type
  ) {
    context.value = { ...context.value, homeType: newProspect.home?.main_asset?.type };
    updateContext = true;
  }

  if (updateContext) window.unleash.updateContext?.(context.value);
};

watch(
  () => cloneDeep(prospectData),
  (newProspect, oldProspect) => {
    if (!isE2E.value) updateUnleashContext(newProspect, oldProspect);
  }
);
const isReplaceBundlePositionActivated = computed(
  () => getVariant(UnleashFlagKeys.REPLACE_BUNDLE_POSITION) === 'variant'
);

export const useUnleash = () => {
  return {
    initUnleashListeners,
    isFlagEnabled,
    getVariant,
    forceFlag,
    forceVariant,
    resetFlags,
    flags,
    isReplaceBundlePositionActivated
  };
};
