import { InfoBoxTypesEnum } from '@/components/popups/InfoBox/InfoBox.types';
import { LoadingData } from '@/composables/utils/useLoader/useLoader.types';

// enum needed for checking by iteration
export enum STATE_STEP {
  SIGNATURE_DONE = 'signatureDone',
  PAYMENT_DONE = 'paymentDone',
  PRICING_DONE = 'pricingDone',
  RECAP_DONE = 'recapDone',
  TERMINATION_DONE = 'terminationDone',
  MORTGAGE_SIGN_DONE = 'mortgageSignDone',
  ACCOUNT_CREATION_DONE = 'accountCreationDone',
  ADDITIONAL_INFOS_DONE = 'additionalInfosDone'
}
export enum STATE_STEP_CONTROL {
  SKIP_EXISTING_INSURANCE = 'skipExistingInsuranceDateForAggregs',
  SKIP_CLAIM_HISTORY = 'skipClaimHistoryForAggregs',
  SKIP_PRICING = 'skipPricing',
  BYPASS = 'bypass',
  ONE_TIME_BYPASS = 'oneTimeBypass',
  SHOULD_GO_FAST = 'shouldGoFast',
  STANDALONE_ACCESS = 'standaloneAccess',
  AGGREGATOR_ONE_PAGER = 'aggregatorOnePager'
}
export enum STATE_POST_ONBOARDING_CONTROL {
  NEEDS_ACCOUNT_CREATION = 'needsAccountCreation',
  NEEDS_TERMINATION = 'needsTermination',
  NEEDS_MEDICAL_CHECKUP = 'needsMedicalCheckup',
  NEEDS_ILLNESS_FORM = 'needsIllnessForm'
}

type o_state_global = {
  ipCountryCode?: string;
  loadingData?: LoadingData | null;
};
type o_state_sign_url = {
  hasSignatureUrl?: boolean;
  signatureUrlData?: { id: string; pk: string; date: number }; // need to precise
};
type o_state_popup = {
  infoData?: { type: InfoBoxTypesEnum; text: string };
  keepMePosted?: boolean;
  loginCallback?: (() => void) | null;
  continueFlowCallback?: (() => void) | null;
  blockSignatureCallback?: (() => void) | null;
  activatePom?: boolean;
  collectEmail?: boolean;
  checkHumanConfirmed?: boolean;
  feedbackAnswer?: 'good' | 'bad';
};
type o_state_other = {
  slowConnectionLoader?: boolean;
  loadingLocale?: boolean;
  generalTermsUrl?: boolean;
  scrollTo?: string;
  shouldShowIntercom?: boolean;
  hideNavbar?: boolean;
};
type o_state_step = {
  [key in STATE_STEP]?: boolean;
};
type o_state_step_control = {
  [key in STATE_STEP_CONTROL]?: boolean;
};
type o_state_post_onboarding_control = {
  [key in STATE_POST_ONBOARDING_CONTROL]?: boolean;
};

export type STATE_DATA = o_state_step &
  o_state_global &
  o_state_step_control &
  o_state_post_onboarding_control &
  o_state_sign_url &
  o_state_popup &
  o_state_other;
