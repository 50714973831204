import { Prospect } from '@/api/prospect/types';
import { CountryCodeEnum } from '@/types/Country';
import { Base64Data } from '@/types/File';
import {
  HomeConstructionPeriodEnum,
  HomeFloorLevelEnum,
  HomeOccupantTypeEnum,
  HomeTypeEnum,
  HomeValuableTypeEnum
} from '@/types/Home';
import { OutbuildingSizeEnum } from '@/types/Outbuilding';
import { PaymentFrequencyEnum } from '@/types/Payment';
import { TerminationReasonEnum } from '@/types/Termination';

interface IDocument {
  description: string;
  icon: string;
  reference: string | null;
  title: string;
  tracking_code: string;
  type: string;
  url?: string;
}

type IBeneficiary = {
  account_created: boolean;
  address_latitude: number;
  address_longitude: number;
  birth_date: string;
  birth_country_code?: string;
  company: string;
  country_code: CountryCodeEnum;
  documents: IDocument[];
  email: string;
  first_name: string;
  gender: string;
  guarantors: unknown[];
  has_authorized_mailing: boolean;
  id: number;
  inventory_date: string | null;
  is_sci: boolean;
  is_smoker: boolean;
  is_subscriber: boolean;
  last_name: string;
  phone: string;
  profession_category: string;
  revenue: unknown;
  role: string;
  uuid: string;
};

type ICoverage = {
  b2b_description: string;
  b2b_title: string;
  code: string;
  icon: string;
  claim_types: IClaimHistory[];
  covered_perils: ICoverageItemContent[];
  deductible: ICoverageItemContent;
  duties: unknown[];
  exclusions: ICoverageItemContent[];
  id: number;
  large_description: string;
  legacy_name: string | null;
  legal_description: string;
  legal_title: string;
  name: string;
  price: ICoveragePrice | null;
  small_description: string;
  special_deductibles: ICoverageItemContent[];
  special_limits: ICoverageItemContent[];
  title: string;
  tracking_code: string;
};

type ICoverageItemContent = {
  b2b_description: string | null;
  b2b_title: string;
  large_description: string | null;
  legal_description: string | null;
  legal_title: string;
  small_description: string | null;
  title: string;
};

type IClaimHistory = {
  created_at: string;
  id: number;
  name: string;
  updated_at: string | null;
};

type ICoveragePrice = { prime_gross: number; prime_net: number; taxes: number };

type IOption = {
  available: boolean;
  b2b_description: string;
  b2b_title: string;
  code: string;
  coverages: ICoverage[];
  icon: string;
  id: number;
  large_description: string;
  last_quoted_price: null;
  legacy_amends: string[];
  legal_description: string | null;
  legal_title: string | null;
  name: string;
  package_title: string;
  small_description: string;
  subscribed: boolean;
  title: string;
  tracking_code: string;
};

export type ContractResponse = {
  id: number;
  uuid: string;
  luko_id: string;
  price: number;
  product_name: string;
  product_code: string;
  product_icon: string;
  start_date: string;
  deductible: number;
  state: ContractStateEnum;
  coupon: string | null;
  is_active: boolean;
  is_signed: boolean;
  need_termination: boolean;
  creation_datetime: string;
  expiration_datetime: string | null;
  access_level: string;
  billing_frequency: PaymentFrequencyEnum;
  commercial_latitude: 0.17;
  commission_percentage: number | null;
  created_at: string;
  deductible_string: string;
  discount_latitude_for_monthly_billing_permyriad: number;
  discount_latitude_for_yearly_billing_permyriad: number;
  email_validated: boolean;
  existing_card_payment_method_id: number;
  first_payment_pending: boolean;
  genuine_code: string;
  initial_partner_id: number;
  intent: boolean;
  is_finalised: boolean;
  is_ready_for_signature: boolean;
  key: string;
  next_renewal_date: string;
  optional_bundles: string[];
  package: string;
  package_code: string;
  package_title: string;
  partner_id: number;
  payment_date: string;
  product: string;
  product_b2b_title: string;
  product_caption: string;
  product_id: number;
  product_title: string;
  product_version: number;
  prospect_key: string;
  prospect_origin: string;
  prospect_state_key: string;
  sales_id: string | null;
  saved_at: string;
  status: string;
  termination_reason: string | null;
  version_number: 1;
  options: IOption[];
  home: {
    id: number;
    uuid: string;
    address: {
      street_and_number: string;
      complement: string;
      city: string;
      country: string;
      postcode: string;
    };
    type: HomeTypeEnum;
    occupant_type: HomeOccupantTypeEnum;
    role: string;
    is_main: boolean;
    claim_history: number;
    floor_level: HomeFloorLevelEnum;
    nb_floors: number;
    nb_rooms: number;
    surface: number;
    content_value: number;
    year_built: HomeConstructionPeriodEnum;
    has_chimney: boolean;
    has_fire_alarm: boolean;
    has_solar_panels: boolean;
    has_swimmingpool: boolean;
    has_theft_alarm: boolean;
    has_veranda: boolean;
    outbuilding_size: OutbuildingSizeEnum;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parcels_json: any;
  } | null;
  mortgage: null;
  nvei: null;
  pet: null;
  users: IBeneficiary[];
  beneficiaries: IBeneficiary[];
  valuables: {
    id: number;
    role: string;
    type: HomeValuableTypeEnum;
    uuid: string;
    value: number;
  }[];
  coverages: ICoverage[];
  documents: IDocument[];
  extensions: unknown;
};

export interface SignatureRequest {
  data: {
    sign_url?: string;
    pdf_url?: string;
  };
  id: string;
  mode: SignatureRequestModeEnum;
}

export interface ContractCreatedResponse {
  contract: { id: number; uuid: string };
  paid_date: string;
  signature_request: SignatureRequest;
  user: {
    email: string;
    first_name: string;
    id: number;
    intercom_tokens: {
      android: string;
      ios: string;
      web: string;
    };
    jwt_token: string;
    last_name: string;
  };
}

export interface PaymentIntentResponse {
  intent: { client_secret: string; id: string };
  prospect: Prospect;
}

export interface MandateInterface {
  payment_service_provider: string;
  customer_bank_account_token: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  address?: string;
  city?: string;
  postal_code?: string;
  country_code?: string;
  psp_id?: string;
}

export interface SignContractRequest {
  request_id: string;
  file_hash: string;
  consent: boolean;
  tracking_info: {
    session_id?: string;
    amplitude_id?: string;
    device_id?: string;
    user_agent?: string;
    product?: string;
    user_id?: number;
  };
}

export enum ContractStateEnum {
  UNSIGNED = 'UNSIGNED',
  NOT_YET_ACTIVE = 'NOT_YET_ACTIVE',
  ACTIVE = 'ACTIVE',
  SOON_TO_BE_TERMINATED = 'SOON_TO_BE_TERMINATED',
  TERMINATED = 'TERMINATED'
}

export enum SignatureRequestModeEnum {
  LUKOSIGN_EMBEDDED = 'luko_embedded',
  LUKO_EMAIL = 'luko_email',
  SKIP = 'skip'
}

export interface TerminationInfoPayload {
  previous_contract_id?: number;
  exit_inventory_date?: string;
  anniversary_date?: string | null;
  insurer_name?: string;
  contract_number?: string;
  underwriter_name?: string;
  contract_address?: string;
  is_switching?: boolean;
  subscription_date?: string | null;
  moving_date?: string | null;
  societaire_number?: string;
  former_insurance_yearly_price?: number;
  contracts_base64?: Base64Data[];
  user_ids_base64?: Base64Data[];
}

export interface TerminationInfoResponse {
  id: number;
  user_uuid: number;
  contract_uuid: number;

  previous_contract_country: string;
  motive?: TerminationReasonEnum;

  state: string;
  state_updated_at?: Date;
  termination_date?: Date;

  home_insurer_contract: {
    contract_number?: string;
    approx_subscription?: Date;
    subscription_date?: Date;
    moving_date?: Date;
    societaire_number?: string;
    subscriber_name: string;
    home_address: string;
    termination_type?: string;
    entity: {
      name: string;
    };
  };
}
