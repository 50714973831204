import 'vue-router';

import { createRouter, createWebHistory } from 'vue-router';

declare module 'vue-router' {
  interface RouteLocationNormalized {
    params: {
      locale: string;
      product: string;
    };
  }
}

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/join/:email',
      name: 'join',
      redirect(to) {
        const pc = to.params.email;
        const newRoute = {
          ...to,
          name: 'onboarding',
          query: { pc }
        };
        return newRoute;
      }
    },
    {
      path: '/:locale?',
      name: 'locale',
      props: true,
      component: () => import('@/i18n/LocaleRouter.vue'),
      children: [
        {
          path: '',
          redirect: { name: 'onboarding' }
        },
        {
          path: 'onboard/signature',
          name: 'standaloneSignature',
          props: (route) => ({ query: route.query }),
          component: () => import('@/modules/signature/StandaloneSignature.vue'),
          beforeEnter: async (to, from, next) => {
            const guard = (await import('./guards/standaloneSignature.guard')).default;
            return guard(to, from, next);
          }
        },
        {
          path: 'onboarding/signature',
          redirect: { name: 'standaloneSignature' }
        },
        {
          path: 'onboard/termination',
          name: 'termination',
          redirect: { name: 'onboarding', hash: '#Termination' }
        },
        {
          path: 'onboarding/termination',
          name: 'oldTermination',
          redirect: { name: 'onboarding', hash: '#Termination' }
        },
        {
          path: 'onboard/sign',
          name: 'sign',
          redirect: { name: 'onboarding', hash: '#Signature' }
        },
        {
          path: 'onboarding/sign',
          name: 'oldSign',
          redirect: { name: 'onboarding', hash: '#Signature' }
        },
        {
          // To manage old nvei url
          path: 'onboard/nvei',
          redirect: { path: '/onboard/escooter' }
        },
        {
          path: 'onboard/:product?',
          name: 'onboarding',
          props: (route) => ({ data: route.params.product }),
          component: () => import('@/modules/StepsManager.vue')
        },
        {
          path: 'pricing/:pathMatch(.*)*',
          redirect: { path: '/onboard' }
        },
        {
          path: 'onboard/pricing',
          redirect: { name: 'onboarding' }
        },
        {
          path: 'onboard/:pathMatch(.*)*',
          redirect: { name: 'onboarding' }
        },
        {
          path: 'onboarding/:pathMatch(.*)*',
          redirect: { name: 'onboarding' }
        }
      ]
    }
  ]
});
