import { computed, ref, WritableComputedRef } from 'vue';

import { useStorage } from '@/composables/utils/useStorage';
import { ValidLocalStoragePaths } from '@/composables/utils/useStorage/useStorage.type';

const { getFromLocalStorage, setInLocalStorage } = useStorage();

const partnerNameRef = ref<string>(getFromLocalStorage(ValidLocalStoragePaths.PARTNER_NAME) || '');
const isAggregator = ref(false);
const isPartner = ref(false);

const partnerName: WritableComputedRef<string> = computed({
  get(): string {
    return partnerNameRef.value?.toString()?.toLowerCase();
  },
  set(name: string): void {
    const formattedName = name?.toLowerCase();
    partnerNameRef.value = formattedName;
    setInLocalStorage(ValidLocalStoragePaths.PARTNER_NAME, formattedName);
  }
});

const partnerLogo = computed(() =>
  partnerName.value
    ? `https://s3.eu-central-1.amazonaws.com/cdn.luko.cover/cobranding/${partnerName.value?.toLowerCase()}.png`
    : null
);

export const usePartner = () => ({
  partnerName,
  partnerLogo,
  isAggregator,
  isPartner
});
