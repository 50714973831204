import { isBoolean, isNil } from 'lodash-es';
import { computed } from 'vue';

import { Prospect } from '@/api/prospect/types';
import { useProduct } from '@/composables/app/useProduct';
import { useProspect } from '@/composables/states/useProspect';
import { HomeConstructionPeriodEnum } from '@/types/Home';
import { TerminationReasonEnum } from '@/types/Termination';

const { isFrHomeFlat, isFrHomeHouse, isHomeInsurance, isFrProduct } = useProduct();
const { prospectData } = useProspect();

const checkFrHomeFlat = (prospect: Prospect) => {
  return (
    prospect.home?.main_asset.floor_level &&
    prospect.home?.main_asset.year_built === HomeConstructionPeriodEnum.MISSING &&
    isNil(prospect.home?.main_asset.has_solar_panels) &&
    isNil(prospect.home?.main_asset.has_swimmingpool) &&
    prospect.home?.main_asset.nb_rooms &&
    prospect.home?.main_asset.surface
  );
};

const checkFrHomeHouse = (prospect: Prospect) => {
  return (
    isNil(prospect.home?.main_asset.floor_level) &&
    prospect.home?.main_asset.year_built &&
    prospect.home?.main_asset.year_built !== HomeConstructionPeriodEnum.MISSING &&
    isBoolean(prospect.home?.main_asset.has_solar_panels) &&
    isBoolean(prospect.home?.main_asset.has_swimmingpool) &&
    prospect.home?.main_asset.nb_rooms &&
    prospect.home?.main_asset.surface
  );
};

const checkPreviousContract = (prospect: Prospect) => {
  if (!isBoolean(prospect.need_termination)) return false;
  if (prospect.need_termination === false) return true;
  if (!prospect.termination_reason) return false;
  if (TerminationReasonEnum.MOVING === prospect.termination_reason) {
    return isNil(prospect.termination_subscription_date);
  }
  if (TerminationReasonEnum.SWITCHING !== prospect.termination_reason) return true;

  // Switching
  if (isNil(prospect.other_info?.termination_less_than_a_year)) return false;
  if (prospect.other_info?.termination_less_than_a_year) {
    return !!prospect.termination_subscription_date;
  }
  return isNil(prospect.termination_subscription_date);
};

const isRecapHomeInfoValid = computed(() => {
  if (isFrHomeFlat.value) {
    return checkFrHomeFlat(prospectData);
  }
  if (isFrHomeHouse.value) {
    return checkFrHomeHouse(prospectData);
  }
  return false;
});

const isRecapValid = computed(() => {
  if (isHomeInsurance.value && isFrProduct.value) {
    return !!isRecapHomeInfoValid.value && !!checkPreviousContract(prospectData);
  }

  return false;
});

export const useValidateRecap = () => {
  return {
    isRecapValid,
    isRecapHomeInfoValid
  };
};
