import { captureException, init } from '@sentry/browser';
import { Integration } from '@sentry/types';
import { BrowserTracing, Replay } from '@sentry/vue';
import axios from 'axios';
import { App } from 'vue';

import { useNavigation } from '@/composables/app/useNavigation';
import { RestartReason } from '@/composables/app/useNavigation/useNavigation.type';
import { useEnv } from '@/composables/utils/useEnv';
import { useError } from '@/composables/utils/useError';
import { ErrorPopupType } from '@/composables/utils/useError/useError.types';

const { isDev, isHostingEnv } = useEnv();
const { restartOnboard } = useNavigation();
const {
  getErrorInfo,
  showErrorPopup,
  hasAlreadyErrorPopup,
  isNetworkError,
  isServerError,
  isUnauthorizedError,
  isBadRequestError,
  isPricingMismatchError,
  isSepaNotAuthorized,
  isAlreadySignedError
} = useError();

export const install = (app: App): void => {
  const integrations: Integration[] = [
    new BrowserTracing({
      tracingOrigins: ['atlas.getluko.com', 'cover.luko.eu', 'api.partner.luko.eu']
    })
  ];

  if (isHostingEnv)
    integrations.push(
      new Replay({
        maskAllText: false
      })
    );

  init({
    app,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    environment: isDev ? 'development' : 'production',
    tracesSampleRate: 0.25,
    autoSessionTracking: true,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations,
    ignoreErrors: [
      'top.GLOBALS',
      'AbortError: The user aborted a request',
      'AbortError: Fetch is aborted'
    ],
    denyUrls: [
      /extensions\//i,
      /^chrome:\/\//i,
      /^safari-extension:\/\//i,
      /^chrome-extension:\/\//i
    ],
    beforeSend(event, hint) {
      const originalException: unknown = hint?.originalException;
      window.Logger.$logError(originalException);

      const detail = getErrorInfo(originalException);
      detail.sentry = `https://sentry.luko.eu/organizations/luko/?query=${event.event_id}`;

      window.LukoTracking?.trackEvent({
        id: axios.isAxiosError(originalException) ? 'API Error' : 'Error',
        properties: {
          error: detail
        }
      });

      // Unhandled captured exceptions.
      if (axios.isAxiosError(originalException) && !hasAlreadyErrorPopup.value) {
        if (isNetworkError(originalException)) {
          showErrorPopup({ type: ErrorPopupType.Retry, error: originalException });
        } else if (isServerError(originalException)) {
          showErrorPopup({ type: ErrorPopupType.LukoDown, error: originalException });
        } else if (isUnauthorizedError(originalException)) {
          showErrorPopup({ type: ErrorPopupType.Unauthorized, error: originalException });
        } else if (isPricingMismatchError(originalException)) {
          showErrorPopup({ type: ErrorPopupType.PricingMismatchError, error: originalException });
        } else if (isSepaNotAuthorized(originalException)) {
          showErrorPopup({ type: ErrorPopupType.SepaNotAuthorized, error: originalException });
        } else if (isAlreadySignedError(originalException)) {
          showErrorPopup({ type: ErrorPopupType.AlreadySigned, error: originalException });
        } else if (isBadRequestError(originalException)) {
          showErrorPopup({ type: ErrorPopupType.ValueError, error: originalException });
        } else {
          showErrorPopup({
            type: ErrorPopupType.Unhandled,
            error: originalException,
            onCta: () => restartOnboard(RestartReason.ERROR_NOT_HANDLED),
            hasIntercom: true
          });
        }
      }

      if (import.meta.env.VITE_AMPLIFY !== 'true') return null;

      // Send log to sentry
      return {
        ...event,
        message: detail.message,
        contexts: {
          ...event.contexts,
          detail
        }
      };
    }
  });

  app.config.errorHandler = (error) => {
    captureException(error);
  };
};
