import { useState } from '@/composables/states/useState';

const { stateData } = useState();

export const scrollToGuard = async (to, _, next) => {
  if (to.query.scroll_to) {
    stateData.value.scrollTo = to.query.scroll_to;
  }
  next();
};
