export interface IContractData {
  id: number;
  uuid: string;
  product_code: string;
  need_termination: boolean;
  is_signed: boolean;
  price: number;
  users: IContractUser[];
  beneficiaries: IContractUser[];
}

interface IContractUser {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  is_subscriber: boolean;
  account_created: boolean;
  is_sci: boolean;
  gender: 'male' | 'female';
  has_authorized_mailing: boolean;
  is_smoker: boolean;
}

export enum VALID_CONTRACT_PATHS {
  ID = 'id',
  GENERAL_TERM_URL = 'general_terms_url',
  UUID = 'uuid'
}
