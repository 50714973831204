import { captureException } from '@sentry/minimal';
import { addDays, isAfter } from 'date-fns';
import { cloneDeep, delay } from 'lodash-es';
import { computed, reactive, ref, watch } from 'vue';

import { SignatureRequestModeEnum } from '@/api/contract/contract.types';
import QuoteService from '@/api/quote';
import { Bundle, QuoteResponse } from '@/api/quote/Quote.types';
import { useCoupon } from '@/composables/app/useCoupon';
import { useNavigation } from '@/composables/app/useNavigation';
import { usePartner } from '@/composables/app/usePartner';
import { PartnerNameEnum } from '@/composables/app/usePartner/usePartner.types';
import { useProduct } from '@/composables/app/useProduct';
import { useRecaptcha } from '@/composables/app/useRecaptcha';
import { RecaptchaActions } from '@/composables/app/useRecaptcha/useRecaptcha.types';
import { useSteps } from '@/composables/app/useSteps';
import { useUser } from '@/composables/app/useUser';
import { useContract } from '@/composables/states/useContract/useContract';
import { VALID_CONTRACT_PATHS } from '@/composables/states/useContract/useContract.types';
import { useProspect } from '@/composables/states/useProspect';
import { useState } from '@/composables/states/useState';
import { useCookies } from '@/composables/utils/useCookies';
import { useEnv } from '@/composables/utils/useEnv';
import { useError } from '@/composables/utils/useError';
import { useLoader } from '@/composables/utils/useLoader';
import { usePriceMethods } from '@/composables/utils/usePriceMethods';
import { useQuickActions } from '@/composables/utils/useQuickActions';
import { i18n } from '@/i18n/index';
import { usePaymentState } from '@/modules/payment/composables/usePaymentState';
import { useValidateRecap } from '@/modules/recap/composables/useValidateRecap';
import { PaymentFrequencyEnum } from '@/types/Payment';
import { ProductDefaultPackageEnum, ProductEnum, ProductPackages } from '@/types/Product';

import { CurrentQuote } from './useQuote.types';

const currentQuote = reactive({} as CurrentQuote);
const isDoingQuote = computed(() => !!QuoteService.controllerQuoteCall.value);

const quoteCount = ref(0);
const isFirstQuote = ref(true);
const lockPricingCall = ref(false);

const { prospectData, setProspectData, getProspectData, PROSPECT_DATA_PATH, selectedOptions } =
  useProspect();
const { isGli, isMortgage, isFrHome } = useProduct();
const { stateData } = useState();
const { isPricingStep, isRecapStep, isPaymentStep } = useSteps();
const { goNext } = useNavigation();
const { jwtToken } = useUser();
const { isE2E } = useEnv();
const { mergeCookie, CookieNamesEnum } = useCookies();
const { isAggregator, partnerName } = usePartner();
const { getMonthlyPrice, getYearlyPrice } = usePriceMethods();
const { launchLoader, removeLoader } = useLoader();
const { quickIntent } = useQuickActions();
const { recaptchaData, getToken, displayRecaptcha } = useRecaptcha();
const { setContractData } = useContract();
const { isRecapValid } = useValidateRecap();
const { resetPaymentState } = usePaymentState();

const {
  isUnauthorizedError,
  isProspectLockedError,
  isProspectSignedError,
  handleUnauthorizedError,
  handleProspectLocked
} = useError();
const { applyCoupon, resetCoupon } = useCoupon();

watch(currentQuote, (newQuote) => {
  mergeCookie(CookieNamesEnum.LK_CMS, { pk: prospectData.key });
  setContractData(
    VALID_CONTRACT_PATHS.GENERAL_TERM_URL,
    newQuote?.currentPackage?.general_terms_pdf_url
  );
});

watch(
  () => currentQuote.currentPrice?.monthly?.yearTotal,
  (newPrice, oldPrice) => {
    if (oldPrice) {
      if (newPrice < oldPrice) {
        window.LukoTracking.trackEvent({ id: 'Quote Downgraded' });
      } else if (newPrice > oldPrice) {
        window.LukoTracking.trackEvent({ id: 'Quote Upgraded' });
      }
    }
  }
);

watch(
  () => prospectData.billing_frequency,
  async (newFrequency, oldFrequency) => {
    if (
      isPaymentStep.value &&
      quoteCount.value &&
      !isFirstQuote.value &&
      !lockPricingCall.value &&
      newFrequency !== oldFrequency
    ) {
      await doQuote();
    }
  }
);

const getDifferentPropertyNames = (obj1: object, obj2: object, path = ''): string[] => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  const uniqueKeys = new Set([...keys1, ...keys2]);
  const differentKeys: string[] = [];

  for (const key of uniqueKeys) {
    const newPath = path ? `${path}.${key}` : key;

    if (
      typeof obj1[key] === 'object' &&
      typeof obj2[key] === 'object' &&
      obj1[key] !== null &&
      obj2[key] !== null
    ) {
      differentKeys.push(...getDifferentPropertyNames(obj1[key], obj2[key], newPath));
    } else if (obj1[key] !== obj2[key]) {
      differentKeys.push(newPath);
    }
  }

  return differentKeys;
};

const IgnorePricingCallKeys = [
  PROSPECT_DATA_PATH.start_date,
  PROSPECT_DATA_PATH.need_termination,
  PROSPECT_DATA_PATH.termination_reason,
  PROSPECT_DATA_PATH.termination_subscription_date,
  PROSPECT_DATA_PATH.other_info.termination_less_than_a_year,
  PROSPECT_DATA_PATH.legal_pdf_sent_date,
  PROSPECT_DATA_PATH.address_complement_second_line
];

watch(
  () => cloneDeep(prospectData),
  async (newProspect, oldProspect) => {
    if (
      (isPricingStep.value || (isRecapStep.value && isRecapValid.value)) &&
      quoteCount.value &&
      !isFirstQuote.value &&
      !lockPricingCall.value &&
      (!stateData.value.pricingDone || !stateData.value.paymentDone)
    ) {
      const changedPropertyNames = getDifferentPropertyNames(oldProspect || {}, newProspect).filter(
        (path) => IgnorePricingCallKeys.every((ignoreKey) => !path.includes(ignoreKey))
      );

      if (changedPropertyNames.length === 1 && changedPropertyNames[0] === 'key') {
        // updated by saveProspect
        return;
      }
      /*
      /// DISABLE THIS CODE FOR NOW AS WE WANT TO REQUEST PRICING FOR ANY CHANGE
      
      if (!changedPropertyNames.length) {
        return await saveProspect();
      }
      if (changedPropertyNames.length === 1 && changedPropertyNames[0] === 'key') {
        // updated by saveProspect
        return;
      }
      ///
      */

      // Requote on change only if first one was done manually
      // and avoid to requote while displaying the Payment module
      resetPaymentState();
      await doQuote();
    }
  }
);

const isStartDateAfterSixMonths = computed(() => {
  const prospectStartDate = getProspectData<string>(PROSPECT_DATA_PATH.start_date);
  return prospectStartDate && isAfter(new Date(prospectStartDate), addDays(new Date(), 180));
});

const canPomPopupBeDisplayed = ref(true);
const isActivatePomFeatureEnabled = computed(
  () => canPomPopupBeDisplayed.value && !currentQuote.isPom && isAggregator.value
);

const approveQuote = async () => {
  if (isStartDateAfterSixMonths.value) return (stateData.value.keepMePosted = true);

  if (isActivatePomFeatureEnabled.value && !isDoingQuote.value)
    return (stateData.value.activatePom = true);

  if (!isDoingQuote.value) stateData.value.pricingDone = true;

  if (isMortgage.value) {
    stateData.value.pricingDone = true;
    stateData.value.paymentDone = true;
    launchLoader(undefined, { title: i18n.global.t('Pricing.waitContract') });
    await quickIntent();
    await goNext();
    return;
  }
  if (isGli.value) {
    stateData.value.pricingDone = true;
    stateData.value.paymentDone = true;
    stateData.value.signatureDone = true;
    launchLoader(true);
    await quickIntent({ signatureMode: SignatureRequestModeEnum.SKIP });
    window.LukoTracking.trackEvent({ id: 'Contract Subscribed' });
    await goNext();
    delay(removeLoader, 500);
    return;
  }

  await goNext();
};

// Partner A/B testing ----
const isPartnerABTesting = computed(
  () => isFirstQuote.value && isFrHome.value && !isE2E.value && !prospectData.package
);

const triggerPartnerABTesting = async () => {
  await setProspectData(PROSPECT_DATA_PATH.package, undefined);
  await setProspectData(PROSPECT_DATA_PATH.deductible, undefined);
  await setProspectData(PROSPECT_DATA_PATH.optional_bundles, undefined);
};

const setUpPartnerABTestingData = async (quoteResponse: QuoteResponse) => {
  await setProspectData(PROSPECT_DATA_PATH.deductible, quoteResponse.deductible);

  currentQuote.funnel_segmentation_group = quoteResponse.funnel_segmentation_group;
  currentQuote.funnel_segmentation_set = quoteResponse.funnel_segmentation_set;
  currentQuote.funnel_segmentation_version = quoteResponse.funnel_segmentation_version;

  const bundles: string[] = selectedOptions.value || [];
  currentQuote.currentPackage.optional_bundles.forEach((bundle) => {
    if (bundle.subscribed && !selectedOptions.value.includes(bundle.code))
      bundles.push(bundle.code);
  });
  selectedOptions.value = bundles;
};

const doQuoteNow = async () => {
  if (displayRecaptcha.value) return;

  try {
    if (import.meta.env.VITE_AMPLIFY === 'true') {
      lockPricingCall.value = true;
      const token = await getToken(RecaptchaActions.LANDING_ON_PRICING);

      await setProspectData(PROSPECT_DATA_PATH.recaptcha.token, token);
    }

    quoteCount.value++;

    if (isPartnerABTesting.value) await triggerPartnerABTesting();

    if (!prospectData.package && !isPartnerABTesting.value) {
      await setProspectData(
        PROSPECT_DATA_PATH.package,
        ProductDefaultPackageEnum[prospectData.product]
      );
    }

    lockPricingCall.value = false;

    const quoteResponse = await QuoteService.createQuote(prospectData);

    await setProspectData(PROSPECT_DATA_PATH.key, quoteResponse?.key);

    if (!jwtToken.value) stateData.value.needsAccountCreation = true;

    if (!quoteResponse) return;

    if (quoteResponse.recaptcha) {
      recaptchaData.value = quoteResponse.recaptcha;
    }

    currentQuote.product = {
      title: quoteResponse.title || '---',
      description: quoteResponse.small_description || '---',
      icon: quoteResponse.icon
    };

    let currentPackage = quoteResponse.packages.find((pack) => pack.subscribed);
    if (
      partnerName.value === PartnerNameEnum.DECATHLON &&
      getProspectData(PROSPECT_DATA_PATH.product) === ProductEnum.FR_ESCOOTER &&
      isFirstQuote.value
    ) {
      await setProspectData(PROSPECT_DATA_PATH.package, ProductPackages.FR_ESCOOTER_MINLEG);
      currentPackage = quoteResponse.packages.find(
        (pack) => pack.code === ProductPackages.FR_ESCOOTER_MINLEG
      );
    }

    if (currentPackage === undefined) {
      currentPackage = quoteResponse.packages.find(
        (pack) => pack.code === ProductDefaultPackageEnum[quoteResponse.code]
      );
      if (currentPackage === undefined) throw new Error("Can't find default package");
    }

    currentQuote.currentPackage = currentPackage;
    if (isPartnerABTesting.value) await setUpPartnerABTestingData(quoteResponse);
    if (!prospectData.package) {
      await setProspectData(PROSPECT_DATA_PATH.package, currentPackage.code);
    }

    // it is a bad idea to use trunc here, but we just follow the backend that uses int(x)
    // let's wait until they send us the monthly price
    currentQuote.currentPrice = {
      monthly: getMonthlyPrice(currentQuote.currentPackage.price_monthly_billing),
      yearly: getYearlyPrice(currentQuote.currentPackage.price_yearly_billing)
    };

    const { 0: firstPackage, 1: secondPackage } = quoteResponse.packages;

    if (firstPackage && secondPackage) {
      currentQuote.packages = {
        minleg: firstPackage.price < secondPackage.price ? firstPackage : secondPackage,
        pom: firstPackage.price < secondPackage.price ? secondPackage : firstPackage
      };
    } else {
      currentQuote.packages = {
        minleg: firstPackage,
        pom: null
      };
    }

    if (quoteResponse.commercial) currentQuote.commercial = quoteResponse.commercial;

    if (quoteResponse.discount_latitude_percentage) {
      currentQuote.discountLatitude = quoteResponse.discount_latitude_percentage;
    }

    if (quoteResponse.billing_frequency && !getProspectData(PROSPECT_DATA_PATH.billing_frequency)) {
      await setProspectData(PROSPECT_DATA_PATH.billing_frequency, quoteResponse.billing_frequency);
    }

    if (quoteResponse.coupon)
      if (quoteResponse.coupon.success)
        applyCoupon({
          ...quoteResponse.coupon,
          premiumMonthly: currentQuote.currentPrice.monthly.cents,
          premiumYearly: currentQuote.currentPrice.yearly.cents
        });
      else resetCoupon();

    currentQuote.isPom = currentQuote.currentPackage.code === currentQuote.packages.pom?.code;

    isFirstQuote.value = false;

    removeLoader();
    if (quoteCount.value === 1) window.LukoTracking.trackEvent({ id: 'Quote Seen' });
    else if (isPricingStep.value) window.LukoTracking.trackEvent({ id: 'Quote Updated' });
  } catch (err) {
    isFirstQuote.value = false;
    removeLoader();

    if (isUnauthorizedError(err)) {
      return handleUnauthorizedError(doQuoteNow);
    }

    if (isProspectLockedError(err) || isProspectSignedError(err)) {
      return handleProspectLocked(err);
    }

    // capture all unhandled error
    captureException(err);
  }
};

let debounceTimeout: ReturnType<typeof setTimeout>;

const doQuote = async () => {
  if (isDoingQuote.value) QuoteService.abortQuote();

  clearTimeout(debounceTimeout);
  debounceTimeout = setTimeout(() => doQuoteNow(), 500);
};

// Minleg <=> Pom switch management
const adaptOptionalBundles = (newBundleList: Bundle[]) => {
  const bundleCodes = getProspectData<string[]>(PROSPECT_DATA_PATH.optional_bundles);
  const newBundleCodes = bundleCodes?.filter((code) =>
    newBundleList?.find((nb) => nb.code === code)
  );
  setProspectData(PROSPECT_DATA_PATH.optional_bundles, newBundleCodes || []);
};

const enablePom = () => {
  if (currentQuote.packages?.pom) {
    setProspectData(PROSPECT_DATA_PATH.package, currentQuote.packages.pom.code);
    adaptOptionalBundles(currentQuote.packages.pom.optional_bundles);
    window.LukoTracking.trackEvent({ id: 'POM Enabled' });
  }
};

const disablePom = () => {
  canPomPopupBeDisplayed.value = false;
  setProspectData(PROSPECT_DATA_PATH.package, currentQuote.packages?.minleg.code);
  adaptOptionalBundles(currentQuote.packages.minleg.optional_bundles);
  window.LukoTracking.trackEvent({ id: 'POM Disabled' });
};

const ipidUrl = computed(() => currentQuote.currentPackage?.ipid_pdf_url);
const generalTermsUrl = computed(() => currentQuote.currentPackage?.general_terms_pdf_url);

const pomPrice = computed(() => {
  if (!currentQuote.packages?.pom) return 0;
  const price = currentQuote.packages?.pom;

  return getProspectData(PROSPECT_DATA_PATH.billing_frequency) === PaymentFrequencyEnum.YEARLY
    ? price?.price_yearly_billing
    : price?.price_monthly_billing;
});

const minLegPrice = computed(() => {
  const price = currentQuote.packages?.minleg;
  return getProspectData(PROSPECT_DATA_PATH.billing_frequency) === PaymentFrequencyEnum.YEARLY
    ? price?.price_yearly_billing
    : price?.price_monthly_billing;
});

export const useQuote = () => {
  return {
    pomPrice,
    minLegPrice,
    currentQuote,
    isFirstQuote,
    isDoingQuote,
    doQuote,
    enablePom,
    disablePom,
    approveQuote,
    quoteCount,
    isStartDateAfterSixMonths,
    ipidUrl,
    generalTermsUrl
  };
};
