import PartnerAPI from '@/api/PartnerAPI';

import { IAuthResponse, IAuthUser } from './auth.types';

export default {
  async login(email: string, password: string): Promise<IAuthResponse> {
    try {
      const { data } = await PartnerAPI.post<IAuthResponse>(`/auth/jwt`, { email, password });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async getUser(): Promise<IAuthUser> {
    try {
      const { data } = await PartnerAPI.get<{ data: IAuthUser[] }>(`/partner/consumers/self`);
      return data.data[0];
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async createUser(payload): Promise<IAuthResponse> {
    try {
      const { data } = await PartnerAPI.post<IAuthResponse>(`/cover/user/account`, payload, {
        headers: {
          Authorization: `Bearer ${import.meta.env.VITE_API_PARTNER_KEY}`
        }
      });

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};
