import { ProspectAddress } from '@/api/prospect/types';

export enum TerminationReasonEnum {
  SWITCHING = 'SWITCHING',
  MOVING = 'MOVING',
  MOVING_WITH_LUKO = 'MOVING_WITH_LUKO'
}
export interface TerminationData {
  contractNumber: string;
  files: object[];
  addressObj: ProspectAddress;
  currentInsurer: string;
  motive: TerminationReasonEnum;
  movingDate: Date;
  societaryNumber: string;
  subscriberName: string;
  subscriptionDate: Date;
  fileList: object[];
  identityFileList: object[];
  formerInsuranceYearlyPrice: number;
  exitDate: Date;
}
/*motive?: TerminationReasonEnum;
  subscriptionDate: Date | null;
  currentInsurer: string;
  exitDate: Date;
  anniversaryDate: Date | null;
  contractNumber: string;
  subscriberName: string;
  
  isSwitching: boolean;
  isMoving: boolean;
  movingDate: Date;
  societaryNumber?: string;
 
  fileList: object[];
  identityFileList: object[];
}
*/
