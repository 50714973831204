import isUndefined from 'lodash-es/isUndefined';
import { NavigationGuard } from 'vue-router';

type evaluteGuardsType = (guards: NavigationGuard[], ...guard: Parameters<NavigationGuard>) => void;

const evaluateGuards: evaluteGuardsType = (guards, to, from, next) => {
  const guardsLeft = guards.slice(0); // clone the array so we do not accidentally modify it
  const nextGuard = guardsLeft.shift();

  if (isUndefined(nextGuard)) {
    next();
    return;
  }

  nextGuard(to, from, (nextArg) => {
    if (isUndefined(nextArg)) {
      evaluateGuards(guardsLeft, to, from, next);
      return;
    }

    next(nextArg);
  });
};

const asyncEvaluateGuards: evaluteGuardsType = async (guards, to, from, next) => {
  const guardsLeft = guards.slice(0); // clone the array so we do not accidentally modify it
  const nextGuard = guardsLeft.shift();

  if (isUndefined(nextGuard)) {
    next();
    return;
  }

  await nextGuard(to, from, async (nextArg) => {
    if (isUndefined(nextArg)) {
      await asyncEvaluateGuards(guardsLeft, to, from, next);
      return;
    }

    next(nextArg);
  });
};

const multiguard = (guards: NavigationGuard[]): NavigationGuard => {
  return (to, from, next) => evaluateGuards(guards, to, from, next);
};

const asyncMultiguard = (guards: NavigationGuard[]): NavigationGuard => {
  return (to, from, next) => asyncEvaluateGuards(guards, to, from, next);
};

export { asyncMultiguard, multiguard };
