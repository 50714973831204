export const TerminationReason = {
  NONE: 'NONE',
  MOVING: 'MOVING',
  MOVING_WITH_LUKO: 'MOVING_WITH_LUKO',
  SWITCHING: 'SWITCHING'
};

export const HouseType = {
  INDEPENDANT: 'independent',
  TWIN: 'twin',
  FIXED: 'fixed'
};

export const PROPERTY_TYPE_FLOORS = ['ground', 'middle', 'top'];

export const START_DATE_FORMAT = 'yyyy-MM-dd';
export const TERMINATION_DATE_FORMAT = 'yyyy-MM-dd';

export const Birthdate = {
  DATE_FORMAT: 'yyyy-MM-dd',
  AGE_MIN: 18,
  YEAR_MAX: 1900
};

export const UNSUPPORTED_FRENCH_DEPARTMENTS = ['20', '97', '98'];
export const UNSUPPORTED_SPANISH_DEPARTMENTS = ['07', '35', '38', '51', '52'];

export const MAX_TOTAL_OUTBUILDING_SURFACE = 30;

export const MAX_VALUABLES_TOTAL_FR = 30000;

export const OnboardingTimeouts = {
  FR_ONE_MONTH_PLUS_OPS: 34,
  FR_TWO_MONTHS_PLUS_OPS: 64,
  MAX_BUFFER_DAYS: 180
};
