import { useMediaQuery } from '@vueuse/core';

const isMediumScreen = useMediaQuery('(max-width: 959px)');
const isSmallScreen = useMediaQuery('(max-width: 719px)');
const isXSmallScreen = useMediaQuery('(max-width: 539px)');

export const useScreenSize = () => {
  return {
    isMediumScreen,
    isSmallScreen,
    isXSmallScreen
  };
};
