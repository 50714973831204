import { ProspectAddress } from '@/api/prospect/types';

import { ValidationRegex } from './useValidation.types';

const isValidEmail = (email: string) => !!email && ValidationRegex.EMAIL.test(email);

const isValidPhone = (phone: string) =>
  !!phone && phone.length >= 10 && ValidationRegex.PHONE_NUMBER.test(phone);

const isValidPostCode = (postCode: string) =>
  !!postCode && ValidationRegex.POST_CODE.test(postCode);

const isValidPassword = (password: string) => !!password && ValidationRegex.PASSWORD.test(password);

const isValidDate = (date: string) => !!date && ValidationRegex.DATE.test(date);

const isValidAddress = (address: ProspectAddress) => {
  if (!address) {
    return false;
  }
  return Object.entries(address).every(([key, value]) => {
    if (key === 'postcode') {
      return isValidPostCode(value);
    }
    if (key === 'address_longitude' || key === 'address_latitude') {
      return true;
    }
    if (key === 'additionalAddressInfo') {
      return true;
    }
    return !!value;
  });
};

export const useValidation = () => ({
  isValidEmail,
  isValidPhone,
  isValidPostCode,
  isValidPassword,
  isValidAddress,
  isValidDate
});
