import { computed } from 'vue';

import { useProspect } from '@/composables/states/useProspect';
import { CountryCodeEnum } from '@/types/Country';
import { HomeOccupantTypeEnum, HomeTypeEnum } from '@/types/Home';
import { ProductEnum } from '@/types/Product';

const { getProspectData, PROSPECT_DATA_PATH } = useProspect();

const product = computed(() => getProspectData<ProductEnum>(PROSPECT_DATA_PATH.product));

const productLocal = computed(() => {
  return product.value && product.value.includes('_')
    ? product.value.split('_')[0]
    : getProspectData<CountryCodeEnum>(PROSPECT_DATA_PATH.subscriber.country_code);
});

const isFrProduct = computed(() => productLocal.value === CountryCodeEnum.France);
const isEsProduct = computed(() => productLocal.value === 'ES');
const isDeProduct = computed(() => productLocal.value === CountryCodeEnum.Germany);

const isWakamProduct = computed(() => {
  return [ProductEnum.FR_ESCOOTER].includes(getProspectData(PROSPECT_DATA_PATH.product));
});

const checkIsHomeInsurance = (product: ProductEnum) => {
  return (
    !!product?.includes('HOME') ||
    !!product?.includes('HOUSE') ||
    !!product?.includes('FLAT') ||
    !!product?.includes('SECONDARY') ||
    !!product?.includes('LANDLORD')
  );
};

const isMortgage = computed(
  () => getProspectData(PROSPECT_DATA_PATH.product) === ProductEnum.FR_MORTGAGE
);

const isGli = computed(() => getProspectData(PROSPECT_DATA_PATH.product) === ProductEnum.FR_RENT);

const isNvei = computed(
  () => getProspectData(PROSPECT_DATA_PATH.product) === ProductEnum.FR_ESCOOTER
);

const isEscooter = computed(() => {
  return [ProductEnum.FR_ESCOOTER].includes(getProspectData(PROSPECT_DATA_PATH.product));
});

const isHomeInsurance = computed(() =>
  checkIsHomeInsurance(getProspectData(PROSPECT_DATA_PATH.product))
);

const isSecondary = computed(
  () =>
    getProspectData(PROSPECT_DATA_PATH.is_main) === false &&
    !getProspectData(PROSPECT_DATA_PATH.owner_type)
);

const isLandlord = computed(() =>
  [ProductEnum.FR_LANDLORD].includes(getProspectData(PROSPECT_DATA_PATH.product))
);

const isLandlordFlat = computed(
  () =>
    [ProductEnum.FR_LANDLORD].includes(getProspectData(PROSPECT_DATA_PATH.product)) && isFlat.value
);

const isLandlordHouse = computed(
  () =>
    [ProductEnum.FR_LANDLORD].includes(getProspectData(PROSPECT_DATA_PATH.product)) && isHouse.value
);

const isOwner = computed(
  () => getProspectData(PROSPECT_DATA_PATH.occupant_type) === HomeOccupantTypeEnum.OWNER
);

const isFlatOwner = computed(
  () => isOwner.value && getProspectData(PROSPECT_DATA_PATH.main_asset_type) === HomeTypeEnum.FLAT
);

const isFrHome = computed(
  () => getProspectData(PROSPECT_DATA_PATH.product) === ProductEnum.FR_HOME
);

const isFrHomeFlat = computed(
  () =>
    getProspectData(PROSPECT_DATA_PATH.product) === ProductEnum.FR_HOME &&
    getProspectData(PROSPECT_DATA_PATH.main_asset_type) === HomeTypeEnum.FLAT
);

const isFrHomeHouse = computed(
  () =>
    getProspectData(PROSPECT_DATA_PATH.product) === ProductEnum.FR_HOME &&
    getProspectData(PROSPECT_DATA_PATH.main_asset_type) === HomeTypeEnum.HOUSE
);

const isFlat = computed(
  () => getProspectData(PROSPECT_DATA_PATH.main_asset_type) === HomeTypeEnum.FLAT
);

const isHouse = computed(
  () => getProspectData(PROSPECT_DATA_PATH.main_asset_type) === HomeTypeEnum.HOUSE
);

const isTenant = computed(
  () =>
    getProspectData<HomeOccupantTypeEnum>(PROSPECT_DATA_PATH.occupant_type) ===
    HomeOccupantTypeEnum.TENANT
);

const isNonPaymentProduct = computed(() =>
  [ProductEnum.FR_MORTGAGE, ProductEnum.FR_RENT].includes(
    getProspectData<ProductEnum>(PROSPECT_DATA_PATH.product)
  )
);

export const useProduct = () => ({
  productLocal,
  isFrProduct,
  isDeProduct,
  isEsProduct,
  isWakamProduct,
  isEscooter,
  isMortgage,
  isGli,
  isNvei,
  isHomeInsurance,
  checkIsHomeInsurance,
  isSecondary,
  isLandlord,
  isFrHome,
  isFrHomeHouse,
  isFrHomeFlat,
  product,
  isFlat,
  isHouse,
  isLandlordFlat,
  isLandlordHouse,
  isOwner,
  isFlatOwner,
  isTenant,
  isNonPaymentProduct
});
