import { captureException } from '@sentry/minimal';
import { AxiosError, AxiosResponse } from 'axios';

import { useError } from '@/composables/utils/useError/useError';
import { ErrorPopupType, ErrorResponseType } from '@/composables/utils/useError/useError.types';

const { showErrorPopup } = useError();

const exceptions = {
  'Network Error': () => {
    return new AxiosError('Network Error', 'ERR_NETWORK', {
      url: 'https://api-dev.partner.luko.eu/v1/prospect/price?lang=fr'
    });
  },
  ArianeAPIFailedError: () => {
    return new AxiosError(
      'An unexpected error ocurred.',
      undefined,
      undefined,
      {
        responseURL: 'https://api-dev.partner.luko.eu/v1/prospect/price?lang=fr'
      },
      {
        status: 500,
        data: {
          message: 'An unexpected error ocurred.',
          type: 'ArianeAPIFailedError'
        }
      } as AxiosResponse
    );
  },
  UnauthorizedError: () => {
    return new AxiosError(
      'An unexpected error ocurred.',
      undefined,
      undefined,
      {
        responseURL: 'https://api-dev.partner.luko.eu/v1/prospect/price?lang=fr'
      },
      {
        status: 401,
        data: {
          message: 'An unexpected error ocurred.'
        }
      } as AxiosResponse
    );
  },
  ProspectLocked: () => {
    showErrorPopup({ type: ErrorPopupType.ProspectLocked });
  },
  ProspectSigned: () => {
    showErrorPopup({ type: ErrorPopupType.ProspectSigned });
  },
  ProspectMissingContract: () => {
    showErrorPopup({ type: ErrorPopupType.ProspectMissingContract });
  },
  ValueError: () => {
    return new AxiosError(
      'An unexpected error ocurred.',
      undefined,
      undefined,
      {
        responseURL: 'https://api-dev.partner.luko.eu/v1/prospect/price?lang=fr'
      },
      {
        status: 400,
        data: {
          message: 'Entrée invalide: $.home.main_asset.type (valeurs possibles: flat)',
          type: 'ArianeAPIFailedError'
        }
      } as AxiosResponse
    );
  },
  ComplyAdvantage: () => {
    showErrorPopup({ type: ErrorPopupType.ComplyAdvantage });
  },
  PricingMismatchError: () => {
    showErrorPopup({ type: ErrorPopupType.PricingMismatchError });
  },
  InvalidZipcodeError: () => {
    showErrorPopup({ type: ErrorPopupType.InvalidZipcodeError });
  },
  SepaNotAuthorized: () => {
    showErrorPopup({ type: ErrorPopupType.SepaNotAuthorized });
  },
  SubscriptionInThePastError: () => {
    showErrorPopup({ type: ErrorPopupType.SubscriptionInThePastError });
  },
  AlreadySigned: () => {
    return new AxiosError(
      'The document signature request has already been signed.',
      undefined,
      undefined,
      { responseURL: 'https://api.partner.luko.eu/v1/contracts/430396/document_signature_request' },
      {
        status: 400,
        data: { type: ErrorResponseType.AlreadySignedContractError }
      } as AxiosResponse
    );
  },
  CreatedAccount: () => {
    showErrorPopup({ type: ErrorPopupType.CreatedAccount });
  },
  MovingError: () => {
    showErrorPopup({ type: ErrorPopupType.MovingError });
  },
  StripeError: () => {
    showErrorPopup({ type: ErrorPopupType.StripeError, description: 'Credit Card rejected' });
  },
  OldError: () => {
    showErrorPopup({
      type: ErrorPopupType._Old,
      description: 'Old Error',
      cancel: 'cancel',
      onCancel: () => window.Logger.$log('Old Error')
    });
  }
};

export const useErrorTrigger = () => {
  return {
    triggerError(type: string) {
      if (!type) {
        window.Logger.$log(
          `Error type is missing. Please clarify which error you want to trigger. ex) window.events.$emit({ type: 'ErrorType' })`
        );
        window.Logger.$log(`Available Errors > ${Object.keys(exceptions).join(', ')}`);
        return;
      }

      const e = exceptions[type];
      if (!e) {
        window.Logger.$log(`Error "${type}" is not defined.`);
        return;
      }
      const error = e();
      if (error) {
        captureException(error);
      }
    }
  };
};
