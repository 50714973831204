import { useProspect } from '@/composables/states/useProspect';
import { useStorage } from '@/composables/utils/useStorage';

const { resetProspect } = useProspect();
const { getFromLocalStorage, setInLocalStorage } = useStorage();

export const removePersistDataGuard = async (_, __, next) => {
  if (getFromLocalStorage('IS_UNAVAILABLE') === true) {
    resetProspect();
    setInLocalStorage('IS_UNAVAILABLE', false);
  }
  next();
};
