import { captureException } from '@sentry/minimal';

import ProspectService from '@/api/prospect';
import { useUser } from '@/composables/app/useUser';
import { UserPathEnum } from '@/composables/app/useUser/useUser.types';
import { useContract } from '@/composables/states/useContract';
import { VALID_CONTRACT_PATHS } from '@/composables/states/useContract/useContract.types';
import { useProspect } from '@/composables/states/useProspect';
import { useState } from '@/composables/states/useState';
import { useStorage } from '@/composables/utils/useStorage';

export const standaloneAccessGuard = async (to, _, next) => {
  const { setUserData } = useUser();
  const { stateData } = useState();
  const { setInLocalStorage } = useStorage();
  const { removePersistedProspect, prospectData } = useProspect();
  const { setContractData } = useContract();

  // Termination Access
  if (to.query.contractId) {
    removePersistedProspect();
    stateData.value.standaloneAccess = true;
    setUserData(UserPathEnum.contractId, to.query.contractId);
    setContractData(VALID_CONTRACT_PATHS.ID, to.query.contractId);
  }

  // Signature Access
  if (to.query.data) {
    try {
      const data = JSON.parse(atob(to.query.data));
      if (data.id && data.pk) {
        removePersistedProspect();
        setInLocalStorage('prospect_key', data.pk);
        setUserData(UserPathEnum.contractId, data.id);
        stateData.HAS_SIGNATURE_URL = true;
        stateData.SIGNATURE_URL_DATA = data;
        stateData.value.pricingDone = true;
        stateData.value.paymentDone = true;

        setContractData(VALID_CONTRACT_PATHS.ID, data.id);
        Object.assign(prospectData, await ProspectService.getProspectByKey(data.pk));
      }
    } catch (err) {
      window.Logger.$log('Error In data parsing for signature url');
      captureException(err);
    }
  }
  return next();
};
