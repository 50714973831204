import { computed, ref, watch } from 'vue';

import { i18n } from '@/i18n';

export const useEmptyFields = (
  fields: {
    [x in string]: unknown;
  },
  trackForm = true
) => {
  const { t } = i18n.global;

  const showEmptyFields = ref(false);

  const emptyInputs = computed<string[]>(() =>
    Object.entries(fields)
      .map(([k, v]) => (!v ? k : ''))
      .filter((v) => v)
  );

  const shouldBeFilled = (inputName: string) => {
    if (showEmptyFields.value && emptyInputs.value.includes(inputName))
      return t('Global.Errors.fieldRequired');
    return undefined;
  };

  const hasEmptyFields = computed(() => emptyInputs.value.length > 0);

  // Track Form started
  const trackedFormStarted = ref(false);
  watch(emptyInputs, (countEmpty) => {
    if (countEmpty !== fields.length && !trackedFormStarted.value && trackForm) {
      trackedFormStarted.value = true;
      window.LukoTracking.trackEvent({
        id: 'Form Started'
      });
    }
  });

  return {
    shouldBeFilled,
    showEmptyFields,
    hasEmptyFields,
    emptyInputs
  };
};
