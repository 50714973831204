import { useNavigation } from '@/composables/app/useNavigation';
import { useProspect } from '@/composables/states/useProspect';
import { HomeOccupantTypeEnum, HomeOwnerTypeEnum } from '@/types/Home';
import { ProductEnum } from '@/types/Product';

const allowed_parameters_options = {
  // PROFIL
  firstname: {
    products: ['mrh', 'nvei'],
    prospect_path: {
      mrh: 'user.subscriber.first_name',
      nvei: 'user.subscriber.first_name'
    }
  },
  lastname: {
    products: ['mrh', 'nvei'],
    prospect_path: {
      mrh: 'user.subscriber.last_name',
      nvei: 'user.subscriber.last_name'
    }
  },
  email: {
    products: ['mrh', 'nvei'],
    prospect_path: {
      mrh: 'user.subscriber.email',
      nvei: 'user.subscriber.email'
    }
  },

  // MRH & NVEI
  address: {
    products: ['mrh', 'nvei'],
    prospect_path: {
      mrh: 'home.main_asset.address_main',
      nvei: 'nvei.main_asset.address_main'
    }
  },
  postcode: {
    products: ['mrh', 'nvei'],
    prospect_path: {
      mrh: 'home.main_asset.postcode',
      nvei: 'nvei.main_asset.postcode'
    }
  },
  city: {
    products: ['mrh', 'nvei'],
    prospect_path: {
      mrh: 'home.main_asset.city',
      nvei: 'nvei.main_asset.city'
    }
  },
  country: {
    products: ['mrh', 'nvei'],
    prospect_path: {
      mrh: 'home.main_asset.country',
      nvei: 'nvei.main_asset.country'
    }
  },
  countrycode: {
    products: ['mrh', 'nvei'],
    prospect_path: {
      mrh: 'home.main_asset.country_code',
      nvei: 'nvei.main_asset.country_code'
    }
  },
  // MRH
  residence_type: {
    products: ['mrh'],
    prospect_path: {
      mrh: 'home.main_asset.type'
    }
  },
  size: {
    products: ['mrh'],
    isNumber: true,
    prospect_path: {
      mrh: 'home.main_asset.surface'
    }
  },
  room: {
    products: ['mrh'],
    isNumber: true,
    prospect_path: {
      mrh: 'home.main_asset.nb_rooms'
    }
  },
  floor: {
    products: ['mrh'],
    prospect_path: {
      mrh: 'home.main_asset.floor_level'
    }
  },
  main_home: {
    products: ['mrh'],
    prospect_path: {
      mrh: 'home.main_asset.is_main'
    }
  },
  had_policy: {
    products: ['mrh'],
    prospect_path: {
      mrh: 'need_termination'
    }
  },
  floor_number: {
    products: ['mrh'],
    isNumber: true,
    prospect_path: {
      mrh: 'home.main_asset.floors_nb'
    }
  },
  claim_history: {
    products: ['mrh'],
    prospect_path: {
      mrh: 'home.main_asset.claim_history'
    }
  },
  year_built: {
    products: ['mrh'],
    prospect_path: {
      mrh: 'home.main_asset.year_built'
    }
  },
  open_fireplace: {
    products: ['mrh'],
    prospect_path: {
      mrh: 'home.main_asset.has_chimney'
    }
  },
  closed_fireplace: {
    products: ['mrh'],
    prospect_path: {
      mrh: 'home.main_asset.has_chimney'
    }
  },
  chimney: {
    products: ['mrh'],
    prospect_path: {
      mrh: 'home.main_asset.has_chimney'
    }
  },
  fire_alarm: {
    products: ['mrh'],
    prospect_path: {
      mrh: 'home.main_asset.has_fire_alarm'
    }
  },
  theft_alarm: {
    products: ['mrh'],
    prospect_path: {
      mrh: 'home.main_asset.has_theft_alarm'
    }
  },
  solar_panels: {
    products: ['mrh'],
    prospect_path: {
      mrh: 'home.main_asset.has_solar_panels'
    }
  },
  veranda: {
    products: ['mrh'],
    prospect_path: {
      mrh: 'home.main_asset.has_veranda'
    }
  },
  outbuilding_size: {
    products: ['mrh'],
    prospect_path: {
      mrh: 'home.main_asset.outbuilding_size'
    }
  },
  swimmingpool: {
    products: ['mrh'],
    prospect_path: {
      mrh: 'home.main_asset.has_swimmingpool'
    }
  },
  // NVEI
  type: {
    products: ['nvei'],
    prospect_path: {
      nvei: 'nvei.main_asset.type'
    }
  },
  brand: {
    products: ['nvei'],
    prospect_path: {
      nvei: 'nvei.main_asset.brand'
    }
  },
  model: {
    products: ['nvei'],
    prospect_path: {
      nvei: 'nvei.main_asset.model'
    }
  },
  price: {
    products: ['nvei'],
    prospect_path: {
      nvei: 'nvei.main_asset.price'
    }
  },
  faster25: {
    products: ['nvei'],
    prospect_path: {
      nvei: 'nvei.main_asset.faster25'
    }
  },
  // SPECIAL CASES
  had_policy_start_date: {
    products: ['mrh']
  },
  capital_amount: {
    products: ['mrh']
  },
  occupanttype: {
    products: ['mrh']
  },
  pc: {
    products: ['mrh', 'nvei']
  }
};

const parse_occupanttype = (prospect_v2) => {
  const occupanttype = prospect_v2.__special_param__occupanttype;
  delete prospect_v2.__special_param__occupanttype;
  if (occupanttype) {
    if (occupanttype === HomeOccupantTypeEnum.TENANT) {
      prospect_v2['home.main_asset.isOwner'] = false;
      prospect_v2['home.main_asset.occupant_type'] = HomeOccupantTypeEnum.TENANT;
      return;
    }

    prospect_v2['home.main_asset.isOwner'] = true;
    prospect_v2['home.main_asset.occupant_type'] = HomeOccupantTypeEnum.OWNER; // default
    prospect_v2['home.main_asset.owner_type'] = null;

    if (occupanttype === HomeOwnerTypeEnum.LANDLORD) {
      prospect_v2['home.main_asset.is_main'] = false;
      prospect_v2['home.main_asset.occupant_type'] = HomeOccupantTypeEnum.TENANT;
      prospect_v2['home.main_asset.owner_type'] = HomeOwnerTypeEnum.LANDLORD;
    }
  }
};

// TODO: Policy Start date
const parse_had_policy_start_date = (prospect_v2) => {
  /*if (had_policy_start_date) {
      if (prospect_v2.prospect_house_month_prev_contract === null) {
        prospect_v2.prospect_house_oldInsuranceAboveTwelveMonths = true
      } else {
        prospect_v2.prospect_house_oldInsuranceAboveTwelveMonths = false
  
        const m = parse(had_policy_start_date, 'yyyy-MM-dd', new Date())
  
        prospect_v2.prospect_house_oldInsuranceStartingMonth = m.getMonth() + 1
      }
    }*/
  delete prospect_v2.__special_param__had_policy_start_date;
};

// TODO: SET_PERSONAL_GOOD_VALUE is forced to default actually, so impossible to load as parameter
const parse_capital_amount = (prospect_v2) => {
  delete prospect_v2.__special_param__capital_amount;
};

// TODO: SET_WAITING_PROMOCODE is forced to default actually, so impossible to load as parameter
const parse_pc = (prospect_v2) => {
  delete prospect_v2.__special_param__pc;
};

const parseQuery = (query) => {
  const product = query.product;

  if (!product) return null;

  const { setProspectData, removePersistedProspect } = useProspect();
  removePersistedProspect();

  // PROSPECT V2
  const prospect_v2 = Object.entries(query)
    .filter(([key]) => {
      const parameter_option = allowed_parameters_options[key];
      if (!parameter_option) {
        // Query param is not in allowed ones
        return false;
      }
      if (!parameter_option.products.includes(product)) {
        // Query param is allowed but not for the selected product
        return false;
      }
      return true;
    })
    .map(([key, value]) => {
      const parameter_option = allowed_parameters_options[key];
      if (value === 'false') {
        value = false;
      } else if (value === 'true') {
        value = true;
      } else if (value === '') {
        value = null;
      } else if (parameter_option.isNumber) {
        value = Number(value);
      }

      if (!parameter_option.prospect_path) {
        // No `prospect_path` : this query param is a special case, key is supplemented with __special_param__
        return [`__special_param__${key}`, value];
      }
      const prospect_path = parameter_option.prospect_path[product] || key;
      return [prospect_path, value];
    })
    .reduce((obj, [key, value]) => ((obj[key] = value), obj), {});

  // OTHER SPECIAL CASES
  if (Object.prototype.hasOwnProperty.call(prospect_v2, '__special_param__had_policy_start_date')) {
    parse_had_policy_start_date(prospect_v2);
  }

  if (Object.prototype.hasOwnProperty.call(prospect_v2, '__special_param__occupanttype')) {
    parse_occupanttype(prospect_v2);
  }

  if (Object.prototype.hasOwnProperty.call(prospect_v2, '__special_param__pc')) {
    parse_pc(prospect_v2);
  }

  if (Object.prototype.hasOwnProperty.call(prospect_v2, '__special_param__capital_amount')) {
    parse_capital_amount(prospect_v2);
  }

  if (['FR_HOME_FLAT', 'FR_HOME_HOUSE', 'FR_SECONDARY', 'MRH', 'mrh'].includes(query.product)) {
    prospect_v2.product = ProductEnum.FR_HOME;
  } else if (['FR_LANDLORD_HOUSE', 'FR_LANDLORD_FLAT'].includes(query.product)) {
    prospect_v2.product = ProductEnum.FR_LANDLORD;
  } else if (Object.keys(ProductEnum).includes(query.product)) {
    prospect_v2.product = query.product;
  } else {
    prospect_v2.product = ProductEnum.DEFAULT;
  }

  // STORE INFORMATION EXTRACTED IN PROSPECT_V2
  for (const [path, value] of Object.entries(prospect_v2)) {
    setProspectData(path, value);
  }

  window.LukoTracking?.trackEvent('Prospect loaded', {
    product: product.toUpperCase(),
    type: 'uri_params'
  });
};

const productQueryGuard = async (to, _, next) => {
  const { goFast, isFirstLanded } = useNavigation();
  parseQuery(to.query);

  const isProductParams = !!to.query['product'];
  const isGoFast = !!to.query['gofast'];

  if (isGoFast) await goFast();
  if (isProductParams) {
    isFirstLanded.value = false;
    return next({ path: to.path, replace: true });
  }

  return next();
};

export default productQueryGuard;
