export interface CouponData {
  code: string | null;
  email: string | null;
  amount: number | null;
  redeem_type: string | null;
  success_msg: CouponMsgInterface | null;
  banner_msg: CouponMsgInterface | null;
  premium_monthly: number | null;
  premium_yearly: number | null;
  premium_monthly_disc: number | null;
  premium_yearly_disc: number | null;
  applied: boolean;
}

export interface CouponResponse {
  code: string;
  redeem_amount: number;
  redeem_type: CouponRedeemTypeEnum;
  success_msg: CouponMsgInterface;
  banner_msg: CouponMsgInterface;
}

export enum CouponRedeemTypeEnum {
  AMOUNT = 'AMOUNT_OFF',
  PERCENTAGE = 'PERCENT_OFF',
  MONTH = 'MONTH_OFF'
}

export interface CouponMsgInterface {
  fr?: string;
  en?: string;
  de?: string;
}
