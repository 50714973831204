import { captureException } from '@sentry/minimal';
import { reactive, ref, watch } from 'vue';

import ProspectService from '@/api/prospect';
import { useProduct } from '@/composables/app/useProduct';
import { useProspect } from '@/composables/states/useProspect';
import { useState } from '@/composables/states/useState';
import { useEmptyFields } from '@/composables/utils/useEmptyFields';

const { stateData } = useState();
const isGeneralConditionsChecked = ref(!!stateData.value.signatureDone);
const confirmBannerShown = ref(false);
const { getProspectData, PROSPECT_DATA_PATH, setProspectData } = useProspect();
const { isFrProduct } = useProduct();
const resetPaymentState = () => {
  isGeneralConditionsChecked.value = false;
};

const { shouldBeFilled, hasEmptyFields, showEmptyFields } = useEmptyFields(
  reactive({
    isGeneralConditionsChecked
  }),
  false
);
const sendLegalOfferEmail = async () => {
  if (isFrProduct.value) {
    const data = await ProspectService.sendLegalPdf(getProspectData(PROSPECT_DATA_PATH.key));
    setProspectData(PROSPECT_DATA_PATH.legal_pdf_sent_date, data.sent_date);
  }
};

const sendEmail = async () => {
  try {
    await sendLegalOfferEmail();
    confirmBannerShown.value = true;
  } catch (error) {
    captureException(error);
  }
};

watch(isGeneralConditionsChecked, async (isChecked) => {
  if (isChecked && !getProspectData(PROSPECT_DATA_PATH.legal_pdf_sent_date)) {
    await sendEmail();
    window.LukoTracking.trackEvent({
      id: 'Document Checkbox Checked'
    });
  }
});

export const usePaymentState = () => {
  return {
    isGeneralConditionsChecked,
    shouldBeFilled,
    hasEmptyFields,
    showEmptyFields,
    resetPaymentState,
    confirmBannerShown,
    sendEmail
  };
};
