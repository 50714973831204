import { createI18n } from 'vue-i18n';

import { useState } from '@/composables/states/useState';
import { useStorage } from '@/composables/utils/useStorage';

import { datetimeFormats, numberFormats } from './formats';
import { AVAILABLE_LOCALES, DEFAULT_LOCALE } from './locales';

const { getFromLocalStorage } = useStorage();
const { stateData } = useState();

export const i18n = createI18n({
  availableLocales: AVAILABLE_LOCALES,
  fallbackLocale: DEFAULT_LOCALE,
  datetimeFormats,
  numberFormats,
  locale: getFromLocalStorage('lang') || DEFAULT_LOCALE,
  // dirty trick for the companion redirect
  messages: {
    en: { Pricing: { waitFirstPrice: 'Calculating your price...' } },
    fr: { Pricing: { waitFirstPrice: 'Nous calculons votre prix...' } },
    de: { Pricing: { waitFirstPrice: 'Bitte warten...' } }
  },
  warnHtmlInMessage: 'off'
});

const loadedLanguages: Array<string> = [];

function setI18nLanguage(lang: string) {
  i18n.global.locale = lang;
  document.querySelector('html')?.setAttribute('lang', lang);
  return lang;
}

export async function loadLanguageAsync(lang: string) {
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  stateData.value.loadingLocale = true;
  const messages = await import(`./locales/${lang}.json`);
  i18n.global.setLocaleMessage(lang, messages.default);
  loadedLanguages.push(lang);
  stateData.value.loadingLocale = false;
  return setI18nLanguage(lang);
}
