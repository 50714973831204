import { Braze } from '@demain-es/lukosiris';

import { useEnv } from '@/composables/utils/useEnv';

import { BrazeEventsEnum } from './braze.types';
import { processEvent } from './tracking';

const { isDev } = useEnv();
const eventsList = Object.values(BrazeEventsEnum);

if (import.meta.env.VITE_AMPLIFY === 'true' && !isDev) {
  new Braze({
    key: import.meta.env.VITE_APP_BRAZE_KEY,
    enableLogging: isDev,
    processEvent,
    eventsList,
    baseUrl: 'brz-api.luko.eu'
  }).init();
} else {
  window.braze = {
    notify: () => null
  };
}
